import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import { bigTv as mediaQuery } from '../../../components/MediaQueries'

export default class Content extends Component {
    render(){
        return (
        <div className="home-section2 pt-0" style={(mediaQuery) ? {"minHeight":"800px"}: {"height":"100%"}}>
        	<style>{`
        		.home-maxwith1 {
        			display: unset;
        			flex: unset;
        			position: unset;
        		}
        	`}
        	</style>
			    <div className={(mediaQuery) ? "home-maxwith1 pt-0" : "home-maxwith1 shadow-none pt-0"}>
			        <div className="row m-0 d-flex justify-content-center">
			            <div className="home-container02 card col-4 mt-4 border-0 pe-0">
			                <img alt="" src="./playground_assets/nexa%20tel%20logo%20copy-200h.png" className="home-image mb-2 card-img-top"/>
			                <div className="card-body mb-2 lh-1-4 pe-0">
			                	<div className="card-text" style={{"lineHeight":"1.6"}}>
			                	Internet for your business <br/>&amp; every needs.
			                	</div>
			                </div>
			                <NavLink to="/nexatel" className="card-footer home-button button ps-4 ms-3 border-0">
			                    <svg viewBox="0 0 1024 1024" className="home-icon">
			                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                    </svg>
			                    <span className="learn">Learn More</span>
			                </NavLink>
			            </div>
			            <div className="home-container02 card col-4 mt-4 border-0 pe-0">
			                <img alt="" src="./playground_assets/nexa%20apps%20logo%20copy-200h.png" className="home-image mb-2 card-img-top"/>
			                <div className="card-body mb-2 lh-1-4 pe-0">
			                	<div className="card-text" style={{"lineHeight":"1.6"}}>
			                	Align with your goals! Website, Web App, Mobile App, and Development Agency.
			                	</div>
			                </div>
			                <NavLink to="/nexaapps" className="card-footer home-button button ps-3 border-0">
			                    <svg viewBox="0 0 1024 1024" className="home-icon">
			                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                    </svg>
			                    <span className="learn">Learn More</span>
			                </NavLink>
			            </div>
			            <div className="home-container02 card col-4 mt-4 border-0 pe-0">
			                <img alt="" src="./playground_assets/nexa%20data%20logo%20copy-200h.png" className="home-image01 mb-2 card-img-top"/>
			                <div className="card-body mb-2 lh-1-4 pe-0">
			                	<div className="card-text" style={{"lineHeight":"1.6"}}>
			                	Provider that can deliver DCaaS and data center services cankeep your future ready.
			                	</div>
			                </div>
			                <NavLink to="/nexadata" className="card-footer home-button button ps-3 border-0">
			                    <svg viewBox="0 0 1024 1024" className="home-icon">
			                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                    </svg>
			                    <span className="learn">Learn More</span>
			                </NavLink>
			            </div>
			            <div className="home-container02 card col-4 mt-4 border-0 pe-0">
			                <img alt="" src="./playground_assets/nexa%20digital%20logo%20copy-200h.png" className="home-image01 mb-2 card-img-top"/>
			                <div className="card-body mb-2 lh-1-4 pe-0" style={{"lineHeight":"1.6"}}>
			                    <div className="card-text">Social Media Management, Media Planning, Whatsapp, Email &amp; SMS Broadcast Engine, Brand Identity, Graphic Designs.</div>
			                </div>
			                <NavLink to="/nexadigital" className="card-footer home-button button ps-3 border-0">
			                    <svg viewBox="0 0 1024 1024" className="home-icon">
			                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                    </svg>
			                    <span className="learn">Learn More</span>
			                </NavLink>
			            </div>
			            <div className="home-container02 card col-4 mt-4 border-0 pe-0">
			                <img alt="" src="./playground_assets/nexa%20solution%20logo%20copy-200h.png" className="home-image01 mb-2 card-img-top"/>
			                <div className="card-body mb-2 lh-1-4 pe-0" style={{"lineHeight":"1.6"}}>
			                    <div className="card-text">Managed service with the latest developments & cost-effective solutions.</div>
			                </div>
			                <NavLink to="/solution" className="card-footer home-button button ps-3 border-0">
			                    <svg viewBox="0 0 1024 1024" className="home-icon">
			                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                    </svg>
			                    <span className="learn">Learn More</span>
			                </NavLink>
			            </div>
			            <div className="home-container02 card col-4 mt-4 border-0 pe-0">
			                <span className="card-body home-text05 pe-0 ps-0">
			                    <span className="card-text home-text06">Connections More</span>
			                    <br/>
			                    <span className="home-text07">Than Anything</span>
			                </span>
			            </div>
			        </div>
			        <div className="home-container05">
			            <div className="home-container06">
			                <div className="home-container-mobile">
			                    <div className="home-container07">
			                        <div className="home-nexatel1">
			                            <img alt="" src="./playground_assets/nexa%20tel%20logo%20copy-200h.png" className="home-image05 card-img-top"/>
			                            <button className="home-button05 button">
			                                <svg viewBox="0 0 1024 1024" className="home-icon10">
			                                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                                </svg>
			                                <span className="learn">Learn More</span>
			                            </button>
			                        </div>
			                        <div className="home-nexadigital1">
			                            <img alt="" src="./playground_assets/nexa%20digital%20logo%20copy-200h.png" className="home-image06 card-img-top"/>
			                            <button className="home-button06 button learn">
			                                <svg viewBox="0 0 1024 1024" className="home-icon12">
			                                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                                </svg>
			                                <span>Learn More</span>
			                            </button>
			                        </div>
			                    </div>
			                </div>
			                <div className="home-container08">
			                    <div className="home-nexaapps1">
			                        <img alt="" src="./playground_assets/nexa%20apps%20logo%20copy-200h.png" className="home-image07 card-img-top"/>
			                        <button className="home-button07 button">
			                            <svg viewBox="0 0 1024 1024" className="home-icon14">
			                                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                            </svg>
			                            <span className="learn">Learn More</span>
			                        </button>
			                    </div>
			                    <div className="home-nexasolution1">
			                        <img alt="" src="./playground_assets/nexa%20solution%20logo%20copy-200h.png" className="home-image08 card-img-top"/>
			                        <button className="home-button08 button">
			                            <svg viewBox="0 0 1024 1024" className="home-icon16">
			                                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                            </svg>
			                            <span className="learn">Learn More</span>
			                        </button>
			                    </div>
			                </div>
			            </div>
			            <div className="home-container09">
			                <div className="home-nexadata2">
			                    <img alt="" src="./playground_assets/nexa%20data%20logo%20copy-200h.png" className="home-image09 card-img-top"/>
			                    <button className="home-button09 button">
			                        <svg viewBox="0 0 1024 1024" className="home-icon18">
			                            <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                        </svg>
			                        <span className="learn">Learn More</span>
			                    </button>
			                </div>
			                <div className="home-nexadata3">
			                    <span className="home-text13">
			                        <span className="home-text14">Connections</span>
			                        <span className="home-text15">More</span>
			                        <br/>
			                        <span className="home-text16">Than</span>
			                        <span className="home-text17">Anything</span>
			                    </span>
			                </div>
			            </div>
			        </div>
			    </div>
        </div>
        )
    }
}