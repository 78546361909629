import Content 		from './Content';
import Footer 		from '../../components/Footer';
import NotFound 	from '../../../error_page/NotFound';
import '../../../assets/detailnews.css';

import  React from 'react'
import { getArticle, CreateToken } from '../../../controller/index'
import { useSelector } from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import { Helmet } from 'react-helmet';

function Loading(){
	return(
		<>
		<div className="container text-center">
			<h3>Loading...</h3>
		</div>
		<Footer />
		</>
	)
}
function Detail() {
	const [searchParams] 			= useSearchParams();
	const langs 					= useSelector(state => state.langs);
	const [result, setResult] 		= React.useState('null')
	const [article, setArticle] 	= React.useState(null)
	const [notFound, setNotFound]	= React.useState(<Loading />)
	const [statusLang] 				= React.useState(langs[0].idLang)
	const [newsSearchParam, setSearchParam] = useSearchParams()

	let langCondition 				= (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data) => {
			getArticle().then((data) => {
				let res_data = data
				if (res_data.metadata.status !== 200) {
					console.log('Error ', res_data.message)
				} else {
					res_data.response.news_articles.forEach((row, idx) => {
						if (row.kode === searchParams.get("id")) 
							setResult(res_data.response.news_articles[idx])
						else
							setNotFound(<NotFound />)
					})
					let dataNews = res_data.response.news_articles
				
					const indexOfObject = dataNews.findIndex(object => {
						return object.kode === searchParams.get("id")
					});
					
					dataNews.splice(indexOfObject, 1)
	
					setArticle(dataNews)
				}
			}).catch((err) => {
				console.log('detail err', err)
			})
		})
		window.scrollTo(0,0)

		

	}, [langCondition, searchParams]);
	let eTitle = "News"
	let desc =  "News Detail From Nexa"
	let mainBannerPage  = ""
	
	return (
		<div>
			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

		{ result === 'null' ? 
			(<>{notFound}</>) : 
			(<>
				<Content storeData={result} news={article} />
				
				</>
				)
		}
		</div>
		)
}

export default Detail;
