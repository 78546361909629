import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';

export default class AfterBanner extends Component {
    render(){
        return (
        	<>
	        <style jsx="true">
			{`
				.card.bg-purple {
					top: -6vh;
				}
				.bg-purple {
					background-color: var(--dl-color-nexa-nexadarkblue)
				}
				.companyabout-text09, .companyabout-text16 {
					text-align: left
				}
			`}
			</style>
			<section>
				<div className="card bg-purple" style={ ( (this.props.button) ? {"height": "auto"} : this.props.styleSection) }>
					<div className="card-body companyabout-text09 ps-4 pe-4 pb-2 text-left align-self-start">
						{this.props.title}
					</div>
					<div className="card-body companyabout-text16 mb-0 ps-4 pe-4 pt-2 justify-content-center" style={{textAlign:"left"}}>
					{this.props.desc}
					<br/>
					<div style={{textAlign:"center", marginTop:"20px"}}>
					{(this.props.button) ? <a href="https://e-katalog.lkpp.go.id/info/penyedia/87" className="companyabout-register button mt-2 mb-0 align-middle text-white" target="_blank" rel="noopener noreferrer">Try Now</a> : ''}
					</div>
					
					</div>
				</div>
			</section>
			</>
        )
    }
}