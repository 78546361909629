import Footer from '../../components/Footer';
import Content from './Content';
import Banner from '../../components/Banner';

import  React from 'react'
import {  NexatelPage,
          NexaappsPage,
          NexadataPage,
          NexadigitalPage,
          NexasolutionPage,
          getArticle,
          LineBreak, 
          setImgDownload,
          getLinkDownloadAndroid,
          getLinkDownloadIos,
          setImgDownloadMobil,
          CreateToken,
          homeHelper,
          detectDevice,
        } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

function Home() {
  const [tel, setTel] = React.useState(null)
  const [app, setApp] = React.useState(null)
  const [data, setData] = React.useState(null)
  const [digital, setDigital] = React.useState(null)
  const [solu, setSolu] = React.useState(null)
  const [article, setArticle] = React.useState(null)

  const langs         = useSelector(state => state.langs);
  const langNexaLevel = useSelector(state => state.homePage);
  const mainServices  = useSelector(state => state.mainServices);
  const [statusLang]  = React.useState(langs[0].idLang)
  let [img, setImg] = React.useState(null)
  let [downloadIOS, setLinkIOS] = React.useState(null)
  let [downloadAndroid, setLinkAndroid] = React.useState(null)
  let [supportHome, setHomeSupport] = React.useState(null)
  let [isCompatible, setCompatibleDevice] = React.useState(null)

  let langCondition = (statusLang !== langs[0].idLang)

  React.useEffect(() => {

    setCompatibleDevice(detectDevice());

    CreateToken().then((dataToken) => {
      NexatelPage().then((data) => {
        setTel(data.response[0].tentang_1.split('|')[1])
      }).catch((err) => {
        console.log('setTel', err)
      })
      NexaappsPage().then((data) => {
        setApp(data.response[0].tentang_1)
      }).catch((err) => {
        console.log('setApp', err)
      })
      NexadataPage().then((data) => {
        setData(data.response[0].tentang_1) 
      }).catch((err) => {
        console.log('setData', err)
      })
      NexadigitalPage().then((data) => {
        setDigital(data.response[0].tentang_1) 
      }).catch((err) => {
        console.log('setDigital', err)
      })
      NexasolutionPage().then((data) => {
        setSolu(data.response[0].tentang_1) 
      }).catch((err) => {
        console.log('setSolu', err)
      })
      
      getArticle().then((data) => {
  
        let res_data = data
        if (res_data.metadata.status !== 200) {
          console.log('404 getArticle ', res_data.message)
        } else {
          setArticle(res_data.response.news_articles)
        }
      }).catch((err) => {
        console.log('error getArticle', err)
      })
  
      setImg(setImgDownloadMobil())
  
      getLinkDownloadIos().then((data) => {
        setLinkIOS(data.response[0].link)
      }).catch(err => {
        console.log(err)
      })
  
      getLinkDownloadAndroid().then((data) => {
        setLinkAndroid(data.response[0].link)
      }).catch(err => {
        console.log(err)
      })
  
      homeHelper().then((data) => {
        setHomeSupport(data)
      }).catch(err => {
        console.log(err)
      })
    })
    

  }, [langCondition]);



  let arrDataBanner = [
    {
      'title': <h1 className="banner-text fw-bold row"><span>Next Level</span><br/><span className="solutioncorporate-text002">Experience</span></h1>,
      'desc': LineBreak(langNexaLevel[0].desc),
      // 'url_image': './playground_assets/nexa-homepage-background-1500w.webp'
      'url_image': './assets/images/banner/mobile/nexahome.png'
    },
    {
      // 'title': <h1 className="banner-text fw-bold"><span>Nexatel</span></h1>,
      // 'desc': tel !== null ? LineBreak(tel) : <Shimmer  width={"inherit"} height={"5rem"},
      'url_image': './assets/images/banner/mobile/nexatel.png'
    },
    {
      // 'title': <h1 className="banner-text fw-bold text-danger"><span>Nexa Apps</span></h1>,
      // 'desc': app !== null ? LineBreak(app) : <Shimmer  width={"inherit"} height={"5rem"},
      'url_image': './assets/images/banner/mobile/nexaapp.png'
    },
    {
      // 'title': <h1 className="banner-text fw-bold text-primary"><span>Nexa Data</span></h1>,
      // 'desc': data !== null ? LineBreak(data) : <Shimmer  width={"inherit"} height={"5rem"},
      'url_image': './assets/images/banner/mobile/nexadata.png'
    },
    {
      // 'title': <h1 className="banner-text fw-bold text-pink"><span>Nexa Digital</span></h1>,
      // 'desc': digital !== null ? LineBreak(digital) : <Shimmer  width={"inherit"} height={"5rem"},
      'url_image': './assets/images/banner/mobile/nexadigital.png'
    },
    {
      // 'title': <h1 className="banner-text fw-bold text-primary"><span>Nexa Solution</span></h1>,
      // 'desc': solu !== null ? LineBreak(solu) : <Shimmer  width={"inherit"} height={"5rem"},
      'url_image': './assets/images/banner/mobile/nexasolution.png'
    }
  ];
  let eTitleHeader = <h1 className="solutioncorporate-text-mobile row"><span>Next Level</span><br/><span className="solutioncorporate-text002">Experience</span></h1>;
  let eStyle = { "width" : "100%", "gridGap":"unset" };
  let desk = sessionStorage.getItem('lang') === "1" ? <span>We are not only a provider <br/> but also friend in all issues</span> : <span>Kami bukan hanya penyedia jasa <br/> tapi juga teman dalam segala permasalahan</span>

  return (
    <div>

      <Helmet>
          <title>Nexa - Nexa Level Experience</title>
          <meta property="og:title" content="Nexa - Nexa Level Experience" ></meta>
          <meta property="og:site_name" content="Nexa"></meta>
          <meta property="og:url" content="https://nexa.net.id/"></meta>
          <meta property="og:description" content="Penyedia Layanan Digital Terpercaya di Indonesia. Mengembangkan bisnis Anda, memberikan solusi yang sesuai dan menghadirkan layanan untuk bisnis Anda dengan teknologi generasi terbaru."></meta>
          <meta name="description" content="Penyedia Layanan Digital Terpercaya di Indonesia. Mengembangkan bisnis Anda, memberikan solusi yang sesuai dan menghadirkan layanan untuk bisnis Anda dengan teknologi generasi terbaru."></meta>
          <meta property="og:type" content="product"></meta>
          <meta property="og:image" content="https://minio.nexa.net.id/nexa/nexa-homepage-background-1500w.webp"></meta>
      </Helmet>
      
      <Banner slider={false} title={eTitleHeader} desc={desk} arrayData={arrDataBanner} style={eStyle} />
      <Content storeData={mainServices} 
                news={ article !== null ? article : null} 
                img_download={img} 
                link_ios={downloadIOS} 
                link_android={downloadAndroid}
                support = {supportHome !== null ? supportHome: null}
                isCompatible = {isCompatible}/>

    </div>
  );
}

export default Home;
