import React, {Component, useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api"
import { LineBreak, SubscribePost } from '../../../controller/index'
import { Shimmer } from 'react-shimmer';
import axios from 'axios';
import Swal from 'sweetalert2'

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15
}

export default class Content extends Component {

	render(){
		return (
			<section>
			<style jsx="true">
			{`
				.bg-map {
					background-color:#f3f3f3
				}
			`}
			</style>
			<section>
				<div className="contacts-section2 d-block">
		          <div className="contacts-container01">
		            <span className="contacts-text">Privacy</span>
		            <h2 className="contacts-text01 fw-bold" style={{fontSize:"30px"}}>{ this.props.storeData !== null ? this.props.storeData.title : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
		            <span className="contacts-text02">
		            { this.props.storeData !== null ? LineBreak(this.props.storeData.privacy_policy) : <Shimmer width={"20rem"} height={"4rem"} /> }
		            </span>
		          </div>

				  <div className="contacts-container01">
					<h2 className="contacts-text01 fw-bold" style={{fontSize:"30px"}}>Umum</h2>
					<span className="contacts-text02 w-100">
					<ul>
						{this.props.storeData !== null ? (
						this.props.storeData.umum.split(',').map((item, index) => (
							<li
							key={index}
							className="contacts-text02"
							style={{ textDecoration: 'none', listStyle: 'decimal', textAlign: 'left' }}
							>
							{item.trim()}
							</li>
						))
						) : (
						<Shimmer width={"20rem"} height={"4rem"} />
						)}
					</ul>
					</span>
				</div>

				<div className="contacts-container01">
					<h2 className="contacts-text01 fw-bold" style={{fontSize:"30px"}}>Mutu</h2>
					<span className="contacts-text02 w-100">
					<ul>
						{this.props.storeData !== null ? (
						this.props.storeData.mutu.split(',').map((item, index) => (
							<li
							key={index}
							className="contacts-text02"
							style={{ textDecoration: 'none', listStyle: 'decimal', textAlign: 'left' }}
							>
							{item.trim()}
							</li>
						))
						) : (
						<Shimmer width={"20rem"} height={"4rem"} />
						)}
					</ul>
					</span>
				</div>

				<div className="contacts-container01">
					<h2 className="contacts-text01 fw-bold" style={{fontSize:"30px"}}>Keamanan Informasi</h2>
					<span className="contacts-text02 w-100">
					<ul>
						{this.props.storeData !== null ? (
						this.props.storeData.keamanan_informasi.split(',').map((item, index) => (
							<li
							key={index}
							className="contacts-text02"
							style={{ textDecoration: 'none', listStyle: 'decimal', textAlign: 'left' }}
							>
							{item.trim()}
							</li>
						))
						) : (
						<Shimmer width={"20rem"} height={"4rem"} />
						)}
					</ul>
					</span>
				</div>

		          
		        </div>
	        </section>

			</section>
		)
	}
}