
import { NavLink } from 'react-router-dom';
import '../../assets/home.css';

import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Info, Work, PeopleAlt, PermContactCalendar, CorporateFare, School, LocalHospital, HomeWork, AccountBalance, SignalCellularAlt, DataThresholding, PlayLesson, AppShortcut, EmojiObjects, Newspaper } from '@mui/icons-material';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { onChangeLang } from '../../store/lang/Lang';


function HeaderView({props}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const arrMenu = [
	  {
	  	'title': 'Company',
	  	'url': '/company',
	  	'sub_menu': [
		  	{
		  		'title': 'About Us',
		  		'url': '/aboutus',
		  		'icon': <Info />
		  	},
		  	{
		  		'title': 'Career',
		  		'url': '/career',
		  		'icon': <Work />
		  	},
		  	{
		  		'title': 'Clients',
		  		'url': '/client',
		  		'icon': <PeopleAlt />
		  	},
		  	{
		  		'title': 'Contact',
		  		'url': '/contact',
		  		'icon': <PermContactCalendar />
		  	},
	  	]
	  },
	  {
	  	'title': 'Services',
	  	'url': '/services',
	  	'sub_menu': [
		  	{
		  		'title': 'Nexatel',
		  		'url': '/nexatel',
		  		'icon': <SignalCellularAlt />
		  	},
		  	{
		  		'title': 'Nexa Apps',
		  		'url': '/nexaapps',
		  		'icon': <AppShortcut />
		  	},
		  	{
		  		'title': 'Nexa Data',
		  		'url': '/nexadata',
		  		'icon': <DataThresholding />
		  	},
		  	{
		  		'title': 'Nexa Digital',
		  		'url': '/nexadigital',
		  		'icon': <PlayLesson />
		  	},
		  	{
		  		'title': 'Nexa Solutions',
		  		'url': '/solution',
		  		'icon': <EmojiObjects />
		  	},
	  	]
	  },
	  {
	  	'title': 'Solution',
	  	'url': '/solution',
	  	'sub_menu': [
		  	{
		  		'title': 'Corporate',
		  		'url': '/corporate',
		  		'icon': <CorporateFare />
		  	},
		  	{
		  		'title': 'Education',
		  		'url': '/education',
		  		'icon': <School />
		  	},
		  	{
		  		'title': 'Government',
		  		'url': '/goverment',
		  		'icon': <AccountBalance />
		  	},
		  	{
		  		'title': 'Hospitality',
		  		'url': '/hospitality',
		  		'icon': <LocalHospital />
		  	},
		  	{
		  		'title': 'Manufacture',
		  		'url': '/manufacture',
		  		'icon': <HomeWork />
		  	},
	  	]
	  },
	  {
	  	'title': 'Support',
	  	'url': '/support',
	  	'sub_menu': [
		  	{
		  		'title': 'Areas',
		  		'url': '/areas',
		  		'icon': <CorporateFare />
		  	},
		  	{
		  		'title': 'Subscription',
		  		'url': '/subscribe',
		  		'icon': <School />
		  	}
	  	]
	  },
	  {
	  	'title': 'News & Article',
	  	'url': '/news',
	  	'sub_menu': [
		  	{
		  		'title': 'News & Article',
		  		'url': '/news',
		  		'icon': <Newspaper />
		  	}
	  	]
	  }
  ]

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >	
    <div className="card">
	    <NavLink to="/">
	    	<img alt="" src="https://minio.nexa.net.id/nexa/nexa_logo_1500.png" className="img-card-top pe-5 ps-5 pb-2 pt-2 w-75 align-self-center" />
	    </NavLink>
    </div>
    <Divider />
    {arrMenu.map((text, index) => (
    <List 
    	key={index}
      	sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      	component="nav"
      	aria-labelledby="nested-list-subheader"
      	subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {text.title}
        </ListSubheader>
      }>
      {
      	text.sub_menu.map((row, idx) => (
      	<NavLink to={row.url} key={row.title}>
	      	<ListItem disablePadding>
	            <ListItemButton
	            	selected={selectedIndex === row.title}
          			onClick={(event) => handleListItemClick(event, row.title)}
          		>
	              <ListItemIcon>
	              	{row.icon}
	                
	              </ListItemIcon>
	              <ListItemText primary={row.title} />
	            </ListItemButton>
	        </ListItem>
        </NavLink>
      	))
      }
      </List>
      ))}
      
      <Divider />

    </Box>
  );

  const UriEnglish = () => {
  	return 'https://flagicons.lipis.dev/flags/4x3/gb.svg'
  }

  const UriIndonesia = () => {
  	return 'https://flagicons.lipis.dev/flags/4x3/id.svg'
  }

  const [bahasa, setBahasa] = React.useState( parseInt(sessionStorage.getItem('lang')) );

  const checkBahasa = () => {
  	return (bahasa === 1) ? 'English' : 'Indonesia'
  }

  const boolBahasa = () => {
  	return bahasa === 1
  }

  const dispatch = useDispatch();

  const onChangeBahasa = (id) => {
  	sessionStorage.setItem('lang', id)
  	setBahasa(parseInt(id))
  	dispatch(onChangeLang(id))
  }

  return (
    <React.Fragment>
    	<style jsx="true">
    	{`
    		.dropdown-toggle::after{
    			display:none;
    		}
    		.btn-check:focus+.btn, .btn:focus,
    		.btn-check:active+.btn:focus, .btn-check:checked+.btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus
    		{ box-shadow: unset }
    	`}
    	</style>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          
			<nav className="navbar navbar-light bg-light w-100 fixed-top" id="__mainHeader">
				<Container>
				<NavLink className="navbar-brand" to="/" onClick={(event) => handleListItemClick(event, 1)}>&nbsp;
					<img alt="" src="https://minio.nexa.net.id/nexa/nexa_logo_1500.png" width="auto" height="30" className="d-inline-block align-top"/>
				</NavLink>
				<Dropdown className="ms-auto">
					<Dropdown.Toggle
						className="text-grey-500 boder-0 d-flex"
						style={{"border":"none"}}
						variant="light" id="__mFlag">
						<img alt="" src={ (boolBahasa()) ? UriEnglish() : UriIndonesia() } className="align-self-center me-2" style={{"width":"20px"}} /> 
						{checkBahasa()}
					</Dropdown.Toggle>

					<Dropdown.Menu style={{width:"20px"}}>
						<button onClick={() => { (boolBahasa()) ? onChangeBahasa(2) : onChangeBahasa(1) }} className="text-grey-500 dropdown-item d-flex">
						<img alt="" src={ (boolBahasa()) ? UriIndonesia() : UriEnglish() } className="align-self-center me-2" style={{"width":"20px"}} /> 
						<div className="w-100">{ (boolBahasa()) ? 'Indonesia' : 'English'}</div>
						</button>
					</Dropdown.Menu>
				</Dropdown>
				<Button onClick={toggleDrawer(anchor, true)}>
					&nbsp;
					<svg viewBox="0 0 1024 1024" className="navigasi-icon">
		             	<path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
		            </svg>
				</Button>
			  </Container>
			</nav>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      
    </React.Fragment>
  );
}

export default class Header extends React.Component {
	render(){
		return(
			<HeaderView />
		)
	}
}
