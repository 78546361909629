import Banner from '../../components/Banner';
import AfterBanner from '../../components/AfterBanner';
import Content from './Content';
import '../../../assets/career.css';

import  React from 'react'
import { CareerPage, ListCareer, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice();

function Career() {
	const [result, setResult] = React.useState(null)
	const [objCareer, setListCareer] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data)=>{
			CareerPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
	
			ListCareer().then((data) => {
				// console.log(data.response)
				setListCareer(data.response)
			}).catch((err) => {
				setListCareer(err)
			})
		})
		
	}, [langCondition]);


	let eBanner 			= <h1 className="banner-text fw-bold"><span>Career</span></h1>
	let eTitle				= 'Career'
	let sBanner_desc  		= (result !== null) && result.tentang_1.replaceAll('<br/>', ' ')
	let sAfterBanner_title 	= (result !== null) && result.tentang_2.replaceAll('<br/>', '')
	let sAfterBanner_desc 	= (result !== null) && result.tentang_3.replaceAll('<br/>', '')
	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/career.webp" : "https://minio.nexa.net.id/nexa/career.jpeg"
	let eStyle = { "width" : "70%" }
	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			<Banner title={eBanner} url_image={mainBannerPage} style={eStyle} />
			{/* <Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={eStyle} /> */}
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			<Content storeData={result} storeDataCareer={objCareer}/>
			
		</div>
		);
}

export default Career;