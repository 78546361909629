// import logo from './logo.svg';
import './App.css';
import React from "react";
import { Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';

import Home from './view/company/homepage/Home';
import Aboutus from './view/company/aboutus/Company';
import Career from './view/company/career/Career';
import Client from './view/company/client/Client';
import Contact from './view/company/contact/Contact';
import PrivacyPolicy from './view/company/privacy_policy/PrivacyPolicy';

import Support from './view/support/support_page/Support';
import Areas from './view/support/areas/Areas';
import Subscribe from './view/support/subscription/Subscribe';

import News from './view/news/list/List';
import NewsDetail from './view/news/detail/Detail';

import Itsupport from './view/it_support/Its';
import Infrastructure from './view/infrastructure/Infrastructure';
import InternetServices from './view/internet/InternetServices';

import Services from './view/services/services/Services';
import Solution from './view/services/nexasolution/Solution';
import Nexadata from './view/services/nexadata/Nexadata';
import Nexadigital from './view/services/nexadigital/Nexadigital';
import Nexaapps from './view/services/nexaapps/Nexaapps';
import Nexatel from './view/services/nexatel/Nexatel';

import NotFound from './error_page/NotFound';

import Corporate from './view/solutions/corporate/Corporate';
import Education from './view/solutions/education/Education';
import Goverment from './view/solutions/goverment/Goverment';
import Hospitally from './view/solutions/hospitally/Hospitally';
import Manufacture from './view/solutions/manufacture/Manufacture';

import MobileHome from './mobile/company/homepage/Home';
import MobileAbout from './mobile/company/aboutus/About';
import MobileCareer from './mobile/company/career/Career';
import MobileClient from './mobile/company/client/Client';
import MobileContact from './mobile/company/contact/Contact';
import MobilePrivacyPolicy from './mobile/company/privacy_policy/PrivacyPolicy';

import MobileNexatel from './mobile/services/nexatel/Nexatel';
import MobileNexaapps from './mobile/services/nexaapps/Nexaapps';
import MobileNexadata from './mobile/services/nexadata/Nexadata';
import MobileNexadigital from './mobile/services/nexadigital/Nexadigital';
import MobileNexaSolution from './mobile/services/nexasolution/Nexasolution';

import MobileInet from './mobile/services/internet/Internet';
import MobileITSupport from './mobile/services/itsupport/Itsupport';
import MobileInfra from './mobile/services/infra/Infra';

import MobileCorporate from './mobile/solutions/corporate/Corporate';
import MobileEducation from './mobile/solutions/education/Education';
import MobileGoverment from './mobile/solutions/goverment/Goverment';
import MobileHospitally from './mobile/solutions/hospitally/Hospitally';
import MobileManufacture from './mobile/solutions/manufacture/Manufacture';

import MobileAreas from './mobile/support/areas/Areas';
import MobileSubscription from './mobile/support/subscription/Subscription';

import MobileArticle from './mobile/news/article/Article';
import MobileArticleDetail from './mobile/news/article_detail/Detail';

import { DefaultLanguage, detectDevice } from "./controller/index.js";
import ReactGA from "react-ga";
import { trackPathForAnalytics } from "./controller/google_analitik";
import { useLocation } from "react-router";

// import fbPixel from 'react-facebook-pixel'

// ======= Add Google Analytic ==================

const TRACKING_ID = "UA-239075827-1"; //TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  window.onresize = function (event) {
    if (window.matchMedia("(min-width: 768px)").matches) {
      document.location.reload(true);
    }
  };
  const { pathname, search } = useLocation();
  const analytics = React.useCallback(() => {
    trackPathForAnalytics(pathname, search, pathname.split("/")[1]);
    // fbPixel.init("175844151523778")
    // fbPixel.pageView();
    // fbPixel.fbq('tracks', pathname)
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        //1546029366185128 //ndak tau punya siapa ikut AI conference di ai.nexa.net.id
        //175844151523778 // mungkin punya nexa
        //3236223809985213 // yang digunakan sekarang
        //sama dengan yang index di public
        //di index menggunakan 1546029366185128
        ReactPixel.init("1546029366185128");
        ReactPixel.pageView();
        ReactPixel.fbq("tracks", pathname);
      });
  }, [pathname, search]);
  const [isCompatible, setIsCompatible] = React.useState(1);
  React.useEffect(() => {
    let valueCompatible = detectDevice();
    setIsCompatible(valueCompatible);
  }, []);
  React.useEffect(() => {
    analytics();
    DefaultLanguage(2);

    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    // Initialize Facebook Pixel
    window.fbq("init", "1546029366185128");
    window.fbq("track", "PageView");
  }, [analytics]);

  return (
    <main className="App">
      <BrowserView>
        {/* <div className='button-wa-fixed'>
				<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
					<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed">
	                  <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
	                </svg>
				</a>
			</div> */}
        <Routes>
          <Route
            path="/"
            index
            element={<Home isCompatible={isCompatible} />}
          />
          <Route
            path="company"
            element={<Aboutus isCompatible={isCompatible} />}
          />
          <Route
            path="career"
            element={<Career isCompatible={isCompatible} />}
          />
          <Route
            path="client"
            element={<Client isCompatible={isCompatible} />}
          />
          <Route path="contact" element={<Contact />} />
          <Route
            path="privacy_policy"
            element={<PrivacyPolicy />}
          />
          <Route path="support" element={<Support />} />
          <Route
            path="nexatel"
            element={<Nexatel isCompatible={isCompatible} />}
          />
          <Route path="news" element={<News isCompatible={isCompatible} />} />
          <Route path="news_detail" element={<NewsDetail />} />
          <Route path="areas" element={<Areas />} />
          <Route
            path="subscribe"
            element={<Subscribe isCompatible={isCompatible} />}
          />
          <Route path="services" element={<Services />} />
          <Route path="internetservices" element={<InternetServices />} />
          <Route path="it_support" element={<Itsupport />} />
          <Route path="infrastructure" element={<Infrastructure />} />
          <Route
            path="solution"
            element={<Solution isCompatible={isCompatible} />}
          />

          <Route
            path="nexadata"
            element={<Nexadata isCompatible={isCompatible} />}
          />
          <Route
            path="nexadigital"
            element={<Nexadigital isCompatible={isCompatible} />}
          />
          <Route
            path="nexaapps"
            element={<Nexaapps isCompatible={isCompatible} />}
          />
          <Route path="*" element={<NotFound />} />

          <Route
            path="corporate"
            element={<Corporate isCompatible={isCompatible} />}
          />
          <Route
            path="education"
            element={<Education isCompatible={isCompatible} />}
          />
          <Route
            path="goverment"
            element={<Goverment isCompatible={isCompatible} />}
          />
          <Route
            path="hospitality"
            element={<Hospitally isCompatible={isCompatible} />}
          />
          <Route
            path="manufacture"
            element={<Manufacture isCompatible={isCompatible} />}
          />
        </Routes>
      </BrowserView>

      <MobileView>
        {/* <div className='button-wa-fixed-mobile'>
					<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
						<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed-mobile">
						<path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
						</svg>
					</a>
				</div> */}
        <Routes>
          <Route path="/" index element={<MobileHome />} />
          <Route path="/aboutus" index element={<MobileAbout />} />
          <Route path="/career" index element={<MobileCareer />} />
          <Route path="/client" index element={<MobileClient />} />
          <Route path="/contact" index element={<MobileContact />} />
          <Route path="privacy_policy" element={<MobilePrivacyPolicy />} />

          <Route path="/nexatel" index element={<MobileNexatel />} />
          <Route path="/nexaapps" index element={<MobileNexaapps />} />
          <Route path="/nexadata" index element={<MobileNexadata />} />
          <Route path="/nexadigital" index element={<MobileNexadigital />} />
          <Route path="/solution" index element={<MobileNexaSolution />} />

          <Route path="/internetservices" index element={<MobileInet />} />
          <Route path="/it_support" index element={<MobileITSupport />} />
          <Route path="/infrastructure" index element={<MobileInfra />} />

          <Route path="/corporate" index element={<MobileCorporate />} />
          <Route path="/education" index element={<MobileEducation />} />
          <Route path="/goverment" index element={<MobileGoverment />} />
          <Route path="/hospitality" index element={<MobileHospitally />} />
          <Route path="/manufacture" index element={<MobileManufacture />} />

          <Route path="/areas" index element={<MobileAreas />} />
          <Route path="/subscribe" index element={<MobileSubscription />} />

          <Route path="/news" index element={<MobileArticle />} />
          <Route path="/news_detail" index element={<MobileArticleDetail />} />
        </Routes>
      </MobileView>
    </main>
  );
}

export default App;
