
import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import Content 		from './Content';
import Interested 	from '../../components/Interested';
import '../../assets/internetservice.css';

import  React from 'react'
import { InternetServicesPage, LineBreak, CreateToken, AboutUs } from '../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

function InternetServices() {

	//Google Analitik
	ReactGA.event({category:"user", action:"Internet Services"})
	

	const [result, setResult] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	const [interested, setInterested] = React.useState(null)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data) => {

			AboutUs().then((data) => {
				setInterested(data.response[0])
			})

			InternetServicesPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})

	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text fw-bold"><span>{ (result !== null) ? result.tentang_1.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}</span></h1>;
	let sBanner_desc  		= (result !== null) ? LineBreak(result.tentang_1.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} />;
	let sAfterBanner_title 	= <span className="fs-36">{(result !== null) ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />}</span>;
	let sAfterBanner_desc 	= <div className="fs-26">{(result !== null) ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} />}</div>;

	let mainBannerPage 		= './assets/images/banner/internet_service.jpeg';
	let eTitle				= (result !== null) ? result.tentang_1.split('|')[0] : ""
	let desc				= (result !== null) ? result.tentang_1.split('|')[1] : ""
	
	return (
		<div>

		<Helmet>
          <title>{`Nexa - ${eTitle}`}</title>
          <meta property="og:title" content="Nexa - Nexa Level Experience" ></meta>
          <meta property="og:site_name" content="Nexa"></meta>
          <meta property="og:url" content="https://nexa.net.id/"></meta>
          <meta property="og:description" content={desc}></meta>
		  <meta name="description" content={desc}></meta>
          <meta property="og:type" content="product"></meta>
          <meta property="og:image" content={mainBannerPage}></meta>
      	</Helmet>
		
			<Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			{result == null && <Content storeData={null} />}
			{result !== null && <Content storeData={{'b2': result.business_category_b2b_1, 'consumer': result.business_category_consumer_1}} />}
			{/* { (result == null) && <Interested storeData={null} /> }
			{ (result !== null) && <Interested storeData={result} /> } */}
			{ (interested == null) && <Interested storeData={null} /> }
			{ (interested !== null) && <Interested storeData={interested} /> }
		</div>
		);
}

export default InternetServices;