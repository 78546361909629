import React, {Component, useState, useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api"
import { LineBreak, SubscribePost } from '../../../controller/index'
import { Shimmer } from 'react-shimmer';
import axios from 'axios';
import Swal from 'sweetalert2'

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15
}
const onClickMaps = e => {
  console.log('onClickMaps', e.latLng.lat(), e.latLng.lng())
}

function Maps({zoomView=0, centerView=0}) {
  // console.log(zoomView, centerView,'MapsProp')
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY,
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <MapContainer zoomData={zoomView} centerData={centerView} key={Math.random()} />;
}

function MapContainer({zoomData, centerData}){

  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsMounted(true), []);
  const center = {lat: -7.021314, lng: 110.427398}

  const OnClickMarker = (e) => {
  	setIsOpen(true)
  	console.log('onClickMarker')
	}

	const onCloseClick = e => {
	setIsOpen(false)
	console.log('InfoWindow close', e)
}

  if (isMounted) {
    return(
      <GoogleMap
      zoom={ (zoomData === 0) ? 15 : zoomData }
      center={ (centerData === 0) ? center : centerData  }
      onClick={onClickMaps}
      mapContainerClassName="map-container"
    >
    {
    	<>
    	<Marker onClick={OnClickMarker} position={{lat: -7.0213080499589084, lng: 110.42711278794319}} />
    	<InfoWindow position={{lat: -7.019202954309754, lng: 110.42712977909851}} onCloseClick={onCloseClick}>
      		<div style={divStyle}>
        		<h6>PT Internet Mulia Untuk Negeri</h6>
      		</div>
    	</InfoWindow>
    	{isOpen ? (

	    	<InfoWindow position={{lat: -7.019202954309754, lng: 110.42712977909851}} onCloseClick={onCloseClick}>
	      		<div style={divStyle}>
	        		<h6>PT Internet Mulia Untuk Negeri</h6>
	      		</div>
	    	</InfoWindow>

    	) : null}
    	</>
    }
    </GoogleMap>
    )
  }
}


function FormControl() {

  return (
    <Form>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="contacts-text38">Name *</Form.Label>
        <Form.Control type="text" id='fullname'  className="contacts-textinput input" placeholder="Your Name" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="contacts-text38">Company Name *</Form.Label>
        <Form.Control type="text" id="comp" className="contacts-textinput input" placeholder="Your Company" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="contacts-text38">Email *</Form.Label>
        <Form.Control type="email" id="mail" className="contacts-textinput input" placeholder="Your Email Address" required />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="contacts-text38">Telp No. *</Form.Label>
        <Form.Control type="text" id="telp" className="contacts-textinput input" placeholder="Your Phone Number" required />
      </Form.Group>
	  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
		<Form.Label className="contacts-text38">Address *</Form.Label>
		<Form.Control type="text" id="address" className="contacts-textinput input" placeholder="Your Address" required />
	  </Form.Group>					
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message * *</Form.Label>
        <Form.Control as="textarea" id='msg' required rows={3} className="contacts-textinput input" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control type="submit" required value="Submit" className="contacts-register button w-100 p-2 fs-22"
		onClick={(e) => {e.preventDefault();}}/>
      </Form.Group>
    </Form>
  );
}


export default class Content extends Component {

	constructor(props){
		super(props)
		this.state = {
			// storeData: null
			isSubmiting: false,
			submitCount: 0
		}
	
	}

	postSubscription = async () => {
		let dump = {
			"nama" : document.getElementById('fullname').value || "",
			"company" : document.getElementById('comp').value || "",
			"email" : document.getElementById('mail').value || "",
			"address" : document.getElementById('address').value || "",
			"note" : document.getElementById('msg').value || "",
			"no_tlp" : document.getElementById('telp').value || "",
			// "insert_by" : "",
			// "update_by" : "admin"
		}

		if (this.state.submitCount > 0) {
			Swal.fire({
			  title: 'Error!',
			  text: 'You already submit this form!',
			  icon: 'error',
			  confirmButtonText: 'ok'
			})
			return false
		}
	
		//validasi
		for await (let key of Object.keys(dump)) {
		if (dump[key] === "") {
			Swal.fire({
			title: 'Error!',
			text: 'Please fill all form!',
			icon: 'error',
			confirmButtonText: 'ok'
			})
			return false
		}
		}

		this.setState({isSubmiting: true})

		//Send Email
		await axios.post("https://nexaclientsystem.nexagroup.id/service/email/send_mail_support", dump).then().catch((err) => {console.log(err)})
	
		SubscribePost(dump).then((data) => {
			if (data.metadata.status === 200) {
				
				this.setState({submitCount: this.state.submitCount+1})
				// import('react-facebook-pixel')
				// .then((x) => x.default)
				// .then((ReactPixel) => {
				// 	ReactPixel.init("1546029366185128")
				// 	ReactPixel.pageView()
				// 	ReactPixel.trackCustom('SubmitApplication', dump)
				// 	// ReactPixel.fbq('tracks', 'Purchase')
				// })

				// window.fbq('track', 'Purchase', dump)
				window.fbq('trackCustom', 'SubmitApplication', dump)

				Swal.fire({
					title: 'success!',
					text: data.metadata.message,
					icon: 'success',
					confirmButtonText: 'ok'
				})
			} else {

				// import('react-facebook-pixel')
				// .then((x) => x.default)
				// .then((ReactPixel) => {
				// 	//1546029366185128 //ndak tau punya siapa ikut AI conference di ai.nexa.net.id
				// 	//175844151523778 // mungkin punya nexa
				// 	//3236223809985213 // yang digunakan sekarang
				// 	//sama dengan yang index di public
				// 	//di index menggunakan 1546029366185128

				// 	ReactPixel.init("1546029366185128")
				// 	ReactPixel.pageView()
				// 	ReactPixel.trackCustom('SubmitApplicationFailed', dump)
		
				// })

				// window.fbq('track', 'PurchaseFailed', dump)
				// window.fbq('trackCustom', 'SubmitApplicationFailed', dump)

				Swal.fire({
					title: 'Error!',
					text: data.metadata.message,
					icon: 'error',
					confirmButtonText: 'ok'
				})
			}
			this.setState({isSubmitting: false})
		}).catch((err) => {
			console.log('post', err)
		})
		
		this.setState({isSubmiting: false})
		
	}

	render(){
		return (
			<section>
			<style jsx="true">
			{`
				.bg-map {
					background-color:#f3f3f3
				}
			`}
			</style>
			<section>
				<div className="contacts-section2 d-block">
		          <div className="contacts-container01">
		            <span className="contacts-text">Contact</span>
		            <h2 className="contacts-text01 fw-bold" style={{fontSize:"30px"}}>{ this.props.storeData !== null ? LineBreak(this.props.storeData.contact_us.split('|')[0]) : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
		            <span className="contacts-text02">
		            { this.props.storeData !== null ? LineBreak(this.props.storeData.contact_us.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} /> }
		            </span>
		          </div>

			        <div className="row">
			          	<div className="col-md-6 text-center">
			          		<Card className="border-0">
			          			<Card.Body>
			          				<Card.Title className="contacts-text11-mobile">
				          				<svg viewBox="0 0 1024 1024" className="contacts-icon-mobile">
						                  <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
						                </svg>&nbsp;
			          					Head Office
			          				</Card.Title>
			          				<Card.Text className="lh-base contacts-text12">
			          				{ this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[0]) : <Shimmer width={"20rem"} height={"4rem"} /> }
			          				</Card.Text>
			          			</Card.Body>
			          		</Card>
			          	</div>
			          	<div className="col-md-6 text-center">
			          		<Card className="border-0">
			          			<Card.Body>
			          				<Card.Title className="contacts-text11-mobile">
				          				<svg viewBox="0 0 1024 1024" className="contacts-icon-mobile">
						                  <path d="M512 42q160 0 272 113t112 271v428q0 52-38 90t-90 38h-256v-86h298v-42h-170v-342h170v-86q0-124-87-211t-211-87-211 87-87 211v86h170v342h-128q-52 0-90-38t-38-90v-300q0-158 112-271t272-113z"></path>
						                </svg>&nbsp;
			          					Call Us
			          				</Card.Title>
			          				<Card.Text className="lh-base contacts-text12">
			          				{ this.props.storeData !== null ? (<a href={'tel:'+this.props.storeData.head_office.split('|')[1].split('<br/>')[0]}>Call. {this.props.storeData.head_office.split('|')[1].split('<br/>')[0] } </a>) : <Shimmer width={"20rem"} height={"4rem"} /> }<br/>
			          				{/* { this.props.storeData !== null ? (<a href="/#">Fax. {this.props.storeData.head_office.split('|')[1].split('<br/>')[1] } </a>) : <Shimmer width={"20rem"} height={"4rem"} /> } */}
			          				<a href="http://wa.gmedia.id/hd" target="__blank">Phone. +62 811-2971-000</a><br/>
									<a href="http://wa.me/6282115555646" target="__blank">Phone. +62 8211-5555-646</a>
									</Card.Text>
			          			</Card.Body>
			          		</Card>
			          	</div>
			          	<div className="col-md-6 text-center">
			          		<Card className="border-0">
			          			<Card.Body>
			          				<Card.Title className="contacts-text11-mobile">
				          				<svg viewBox="0 0 1024 1024" className="contacts-icon-mobile">
						                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
						                </svg>&nbsp;
			          					Email Us
			          				</Card.Title>
			          				<Card.Text className="lh-base contacts-text12">
			          					<a href={ this.props.storeData !== null ? 'mailto:'+LineBreak(this.props.storeData.head_office.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} /> }><span>Email. { this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[2]) : <Shimmer width={"20rem"} height={"4rem"} /> }</span></a>
			          				</Card.Text>
			          			</Card.Body>
			          		</Card>
			          	</div>
			        </div>

		          
		        </div>
	        </section>

	        <section className="bg-map">
	        	<div className="w-100" style={{"height":"300px"}}>
	              <Maps centerView={{lat:-7.0252326, lng:110.4278272}} zoomView={15} />
	            </div>
	            <Card className="mt-4 border-0 bg-map">
	            	<Card.Body>
					<Form>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="contacts-text38">Name *</Form.Label>
						<Form.Control type="text" id='fullname'  className="contacts-textinput input" placeholder="Your Name" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="contacts-text38">Company Name *</Form.Label>
						<Form.Control type="text" id="comp" className="contacts-textinput input" placeholder="Your Company" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="contacts-text38">Email *</Form.Label>
						<Form.Control type="email" id="mail" className="contacts-textinput input" placeholder="Your Email Address" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="contacts-text38">Telp No. *</Form.Label>
						<Form.Control type="text" id="telp" className="contacts-textinput input" placeholder="Your Phone Number" required />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
						<Form.Label className="contacts-text38">Address *</Form.Label>
						<Form.Control type="text" id="address" className="contacts-textinput input" placeholder="Your Address" required />
					</Form.Group>					
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Label>Message * *</Form.Label>
						<Form.Control as="textarea" id='msg' required rows={3} className="contacts-textinput input" />
					</Form.Group>
					<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
						<Form.Control disabled={this.state.isSubmiting} type="submit" required value="Submit" className="contacts-register button w-100 p-2 fs-22"
						onClick={(e) => {e.preventDefault(); this.postSubscription()}}/>
					</Form.Group>
					</Form>
	            	</Card.Body>
	            </Card>
	        </section>
			</section>
		)
	}
}