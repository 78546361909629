import React, {Component} from 'react';
import { Shimmer } from 'react-shimmer';

export default class Content extends Component {
    render(){
        return (
        <section>

			<div className='button-wa-fixed'>
				<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
					<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed">
	                  <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
	                </svg>
				</a>
			</div>

			<style>
        	{`
        		.itsuport-container06 {
        			min-height: 350px;
        		}	
        	`}
        	</style>
	        <section className="itsuport-container03">
	          <div className="itsuport-container04">
	            <span className="itsuport-text17">Nexatel</span>
	            <h2 className="itsuport-text18 fw-bold">Our Support</h2>
	          </div>
	          <div className="itsuport-container05 h-auto mt-5" style={{"alignItems":"start"}}>
	            <div className="itsuport-container06">
	              <img loading="lazy" alt="Icon On Site Support" src="./playground_assets/on%20site%20support-300h.png" className="itsuport-image"/>
	              <span className="itsuport-text19">On Site Support</span>
	              <span className="itsuport-text20">
	              	{this.props.storeData !== null ? this.props.storeData.site : <Shimmer width={"20rem"} height={"4rem"} />}
	              </span>
	            </div>
	            <div className="itsuport-container06">
	              <img loading="lazy" alt="Icon On Call Support" src="./playground_assets/on%20call%20support-300h.png" className="itsuport-image1"/>
	              <span className="itsuport-text26">
	                <span className="itsuport-text27"></span>
	                <span className="itsuport-text28">On Call Support</span>
	              </span>
	              <span className="itsuport-text29">
	              {this.props.storeData !== null ? this.props.storeData.call : <Shimmer width={"20rem"} height={"4rem"} />}
	              </span>
	            </div>
	            <div className="itsuport-container06">
	              <img loading="lazy" alt="Icon Managed IT Support" src="./playground_assets/managed%20it%20support-300h.png" className="itsuport-image2"/>
	              <span className="itsuport-text34">Managed IT Support</span>
	              <span className="itsuport-text35">
	              {this.props.storeData !== null ? this.props.storeData.managed : <Shimmer width={"20rem"} height={"4rem"} />}
	              </span>
	            </div>
	          </div>
	        </section>

			<section className="itsuport-container09" style={{marginBottom: "2rem"}}> 
	          <div className="itsuport-container10">
	            <span className="itsuport-text41">Nexatel</span>
	            <h2 className="itsuport-text42 fw-bold">Service Features</h2>
	          </div>
	          <div className="itsuport-container11">
	            <div className="itsuport-container12">
	              <img loading="lazy" alt="Icon Experienced" src="./playground_assets/well%20experienced-200h.png" className="itsuport-image3"/>
	              <span className="itsuport-text43">
	                <span>Well</span>
	                <br/>
	                <span></span>
	                <span>Experienced</span>
	              </span>
	            </div>
	            <div className="itsuport-container13">
	              <img loading="lazy" alt="Icon Accredited" src="./playground_assets/fully%20accredited-200h.png" className="itsuport-image4"/>
	              <span className="itsuport-text48">
	                <span>Fully</span>
	                <br/>
	                <span></span>
	                <span>Accredited</span>
	              </span>
	            </div>
	            <div className="itsuport-container14">
	              <img loading="lazy" alt="Icon IT Support" src="./playground_assets/communicative%20it%20support-200h.png" className="itsuport-image5"/>
	              <span className="itsuport-text53">
	                <span>Communicative</span>
	                <br/>
	                <span>IT Support</span>
	              </span>
	            </div>
	          </div>
	        </section>
        </section>
        )
    }
}