import React from 'react'
import { Row, Col } from 'react-bootstrap';

export default class Content extends React.Component {
	services_features = () => {
		return [
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_high_quality_material.png',
				'title': 'High quality<br/>material'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_fully_accredited.png',
				'title': 'Certified engineer'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_trusted_partner.png',
				'title': 'Trusted partner'	
			},
			{
				'image':'https://minio.nexa.net.id/nexa/icon_commisioning_testing.png',
				'title':'Commisioning<br/>& testing'
			},
			{
				'image':'https://minio.nexa.net.id/nexa/icon_zero_cost.png',
				'title':'Zero cost<br/>investment'
			},
			{
				'image':'https://minio.nexa.net.id/nexa/icon_suitable_brand.png',
				'title':'Suitable brand<br/>& technology'
			},
			{
				'image':'https://minio.nexa.net.id/nexa/icon_asset_licensing_management.png',
				'title':'Asset & licensing<br/>management'
			},
			{
				'image':'https://minio.nexa.net.id/nexa/icon_procurement.png',
				'title':'Procurement of IT<br/>equipment'
			},
			{
				'image':'https://minio.nexa.net.id/nexa/icon_worry_free.png',
				'title':'Worry free'
			},
		]
	}

	formatText = (text) => {
		const newText = text.split('<br/>').map(str => <React.Fragment key={str}>{str}<br/></React.Fragment>)
	return newText;
	}
	render(){
		const aLinkHover = (this.props.storeData !== null) ? this.props.storeData.product_services.split('|') : []
		return(
			<section>

				<div className='button-wa-fixed-mobile'>
					<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
						<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed-mobile">
						<path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
						</svg>
					</a>
				</div> 
				<section className="network-infrastructure-container03">
		          <div className="network-infrastructure-container04">
		            <span className="network-infrastructure-text18">Nexatel</span>
		            <h2 className="network-infrastructure-text19 fw-bold fs-32 text-center">
		              Products &amp; Services
		            </h2>
		          </div>
		          <Row className=" container mx-auto">
		            <Col xs={12} md={6} className="mb-4">
		              
		              { aLinkHover.map((row, idx) => 
		              	(
			              <button key={idx} className="network-infrastructure-button text-black-50 button">
			                <svg viewBox="0 0 1024 1024" className="network-infrastructure-icon">
			                  <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
			                </svg>
			                {row}
			              </button>
		              	)
		              )}
		              
		            </Col>
		            <Col xs={12} md={6} className="mb-4">
		              <img loading='lazy' alt="Gambar Banner" src="https://images.unsplash.com/photo-1597138804456-e7dca7f59d54?crop=entropy&amp;cs=tinysrgb&amp;fm=jpg&amp;ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDMxfHxzZXJ2ZXJ8ZW58MHx8fHwxNjUyODQ1OTIz&amp;ixlib=rb-1.2.1&amp;q=80&amp;raw_url=true&amp;w=400" className="network-infrastructure-image w-100"/>
		            </Col>
		          </Row>
		        </section>

				<section className="nexadata-section6">
		          <div className="nexadata-container10">
		            <span className="internetservice-text033">Nexatel</span>
		            <h2 className="nexadata-text23 fw-bold fs-32">Service Features</h2>
		          </div>
		          <div className="container pe-4 ps-4">
		            <div className="nexadata-container12 row mx-auto" style={{"gridGap":"unset", "justifyContent":"unset"}}>
		              {
		              	this.services_features().map((row, idx) => (
			              <Col xs={6} md={4} key={idx} className="nexadata-features mt-4 text-center">
			                <img loading='lazy' alt={`Nexa - ${row.title}`} src={row.image} className="nexadata-image mx-auto"/>
			                <span className="nexadata-text24">
			                {this.formatText(row.title)}
			                </span>
			              </Col>
		              	))
		              }

		            </div>
		          </div>
		        </section>
			</section>
		)
	}
}