import React, {Component, useState, useEffect} from 'react';
import { LineBreak, SubscribePost } from '../../../controller/index'
import axios from 'axios';
import { Shimmer } from 'react-shimmer';

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15
}

export default class Content extends Component {

  render(){
    return (
  	<>
    	<section className="contacts-section2">

        <div className="contacts-container01">
          <span className="contacts-text">Privacy</span>
          <h2 className="contacts-text01 fw-bold">{ this.props.storeData !== null ?  this.props.storeData.privacy_policy.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
          <span className="contacts-text02">
            { this.props.storeData !== null ? LineBreak(this.props.storeData.privacy_policy.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} /> }
          </span>
        </div>

        <div className="contacts-container01">
          <h2 className="contacts-text01 fw-bold mt-5">{ this.props.storeData !== null ?  this.props.storeData.umum.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
          <span className="contacts-text02 w-100">
          <ul>
            {this.props.storeData !== null ? (
              this.props.storeData.umum.split('|')[1].split(',').map((item, index) => (
                <li
                  key={index}
                  className="contacts-text02"
                  style={{ textDecoration: 'none', listStyle: 'decimal', textAlign: 'left' }}
                >
                  {item.trim()}
                </li>
              ))
            ) : (
              <Shimmer width={"20rem"} height={"4rem"} />
            )}
          </ul>
          </span>
        </div>

        <div className="contacts-container01">
          <h2 className="contacts-text01 fw-bold mt-5">{ this.props.storeData !== null ?  this.props.storeData.mutu.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
          <span className="contacts-text02 w-100">
          <ul>
            {this.props.storeData !== null ? (
              this.props.storeData.mutu.split('|')[1].split(',').map((item, index) => (
                <li
                  key={index}
                  className="contacts-text02"
                  style={{ textDecoration: 'none', listStyle: 'decimal', textAlign: 'left' }}
                >
                  {item.trim()}
                </li>
              ))
            ) : (
              <Shimmer width={"20rem"} height={"4rem"} />
            )}
          </ul>
          </span>
        </div>

        <div className="contacts-container01">
          <h2 className="contacts-text01 fw-bold mt-5">{ this.props.storeData !== null ?  this.props.storeData.keamanan_informasi.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
          <span className="contacts-text02 w-100">
          <ul>
            {this.props.storeData !== null ? (
              this.props.storeData.keamanan_informasi.split('|')[1].split(',').map((item, index) => (
                <li
                  key={index}
                  className="contacts-text02"
                  style={{ textDecoration: 'none', listStyle: 'decimal', textAlign: 'left' }}
                >
                  {item.trim()}
                </li>
              ))
            ) : (
              <Shimmer width={"20rem"} height={"4rem"} />
            )}
          </ul>
          </span>
        </div>

      </section>

  	</>
      )
  }
}