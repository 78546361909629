import Header from '../../../components/Header';
import Banner from '../../../components/Banner';
import AfterBanner from '../../../components/AfterBanner';
import Interested from '../../../components/Interested';
import Footer from '../../../components/Footer';
import Content from './Content';
import { bigTv as mediaQuery, bigScreen, normalScreen } from '../../../components/MediaQueries'
import '../../../assets/supportpage.css';

import  React from 'react'
import { SubscribePage, LineBreak, AboutUs, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

function Subscribe({ isCompatible }) {
  //Google Analitik
  ReactGA.event({ category: "user", action: "Support Subscribe" });

  const [result, setResult] = React.useState(null);
  const langs = useSelector((state) => state.langs);
  const [statusLang] = React.useState(langs[0].idLang);
  const [interest, setInterest] = React.useState(null);

  let langCondition = statusLang !== langs[0].idLang;

  React.useEffect(() => {
    CreateToken().then((data) => {
      SubscribePage()
        .then((data) => {
          setResult(data.response[0]);
        })
        .catch((err) => {
          setResult(err);
        });

      AboutUs()
        .then((data) => {
          setInterest(data.response[0]);
        })
        .catch((err) => {
          setResult(err);
        });
    });
    // console.log(InterestedPage())
  }, [langCondition]);

  let eBanner = (
    <h1
      className={
        mediaQuery
          ? "banner-text fw-bold container max-width-1100px w-100"
          : "banner-text fw-bold"
      }
      style={{ lineHeight: "1" }}
    >
      {result !== null ? (
        result.tentang_1.split("|")[0]
      ) : (
        <Shimmer width={"20rem"} height={"4rem"} />
      )}
      <br />
      <span className="solutioncorporate-text002">
        {result !== null ? (
          result.tentang_1.split("|")[1]
        ) : (
          <Shimmer width={"20rem"} height={"4rem"} />
        )}
      </span>
    </h1>
  );
  let sBanner_desc = (
    <div className={mediaQuery ? "container max-width-1100px" : ""}>
      {result !== null ? (
        LineBreak(result.tentang_1.split("|")[2])
      ) : (
        <Shimmer width={"20rem"} height={"4rem"} />
      )}
    </div>
  );
  let eStyleBanner = { width: "100%" };
  let eStyleBannerText = { width: "65%" };

  let sAfterBanner_title =
    result !== null ? (
      LineBreak(result.tentang_2)
    ) : (
      <Shimmer width={"20rem"} height={"4rem"} />
    );
  let sAfterBanner_desc = (
    <span className="fs-18">
      {result !== null ? (
        LineBreak(result.tentang_3)
      ) : (
        <Shimmer width={"20rem"} height={"4rem"} />
      )}
    </span>
  );

  let mainBannerPage = isCompatible
    ? "https://minio.nexa.net.id/nexa/banner_support.webp"
    : "https://minio.nexa.net.id/nexa/banner_support.png";
  let eTitle =
    result !== null
      ? `${result.tentang_1.split("|")[0]} ${result.tentang_1.split("|")[1]}`
      : "";
  let desc = result !== null ? result.tentang_3 : "";

  let mediaCondition;
  if (normalScreen) {
    mediaCondition = { top: "0px", height: "300px" };
  } else if (bigScreen) {
    mediaCondition = { top: "150px", minHeight: "550px" };
  } else {
    mediaCondition = { top: "-80px", height: "200px" };
  }

  return (
    <div>
      <Helmet>
        <title>{`Nexa - ${eTitle}`}</title>
        <meta property="og:title" content={eTitle}></meta>
        <meta property="og:site_name" content="Nexa"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:description" content={desc}></meta>
        <meta name="description" content={desc}></meta>
        <meta property="og:type" content="product"></meta>
        <meta property="og:image" content={mainBannerPage}></meta>
      </Helmet>
      <Banner
        title={eBanner}
        desc={sBanner_desc}
        url_image={mainBannerPage}
        style={mediaQuery ? { maxWidth: "unset" } : eStyleBanner}
        styleText={eStyleBannerText}
      />
      <AfterBanner
        title={sAfterBanner_title}
        desc={sAfterBanner_desc}
        styleSection={mediaCondition}
      />
      <Content />
      {/* { (interest == null) && <Interested storeData={null} /> } */}
      {interest !== null && <Interested storeData={interest} />}
    </div>
  );
}

export default Subscribe;
