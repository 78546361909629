import React, {Component} from 'react';
import MenuTabs from './ScrollableTabsButtonAuto';

export default class Portofolio extends Component {

    render(){
        return (
        	<section className="client-section6">
	          <div className="client-container04-mobile">
	            <span className="client-text16">{this.props.sub_title}</span>
	            <h1 className="client-text17 fw-bold" style={{textAlign:"center", fontSize:"1.8rem"}}>{this.props.title}</h1>
	            <span className="client-text18 w-100">
	            	{this.props.desc}
	            </span>
	          </div>
	          
	          <MenuTabs />
	        </section>
        )
    }
}