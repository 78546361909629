import React, {Component} from 'react';
import $ from 'jquery';

export default class Content extends Component {
	componentDidMount(){
		$(document).on('click', 'li', function(){
			$('li').removeClass('active');
			$('ul').toggleClass('expanded');
			$(this).addClass('active');
			var tab_id = $(this).attr('data-tab');
			$('.tab-content').removeClass('current');
			$(this).addClass('current');
			$('#'+tab_id).addClass('current');
		  });
	}

	render(){
		return (
			<>
			</>
		)
	}
}