import React  from 'react';
import Header 		from '../../../components/Header';
import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import Content 		from './Content';
import Footer 		from '../../../components/Footer';
import { bigTv as mediaQuery } from '../../../components/MediaQueries'
import '../../../assets/internetservice.css';
import ReactGA from 'react-ga'

function Services() {

	//Google Analitik
	ReactGA.event({category:"user", action:"Other Service"})
	

	let eBanner 			= <h1 className={(mediaQuery) ? "banner-text fw-bold solutioncorporate-text002 container max-width-1100px" : "banner-text fw-bold solutioncorporate-text002"}><span>Services</span></h1>;
	let sBanner_desc  		= <div className={(mediaQuery) ? "container max-width-1100px" : ""}>Connected Life Experience<br/> to Your Business</div>;
	let sAfterBanner_title 	= <span className="fs-36">Connections More<br/> Than Anything</span>;
	let sAfterBanner_desc 	= <span className="fs-20">Nexa helps to get the most out of your technology<br/> so you can focus on your commercial success. Tell us<br/> about your new project, or maybe you simply want to talk over a cup of coffee.</span>;
	let mainBannerPage		= './assets/images/banner/service.jpeg'
	return (
		<div>
			<Header />
			<Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={(mediaQuery) ? {"maxWidth":"unset"} : {}} />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} styleSection={(mediaQuery) ? {"top":"150px"} : {"height":"200px"}} />
			<Content />
			<Footer />
		</div>
		);
}

export default Services;