import React, {Component} from 'react';
import { Shimmer } from 'react-shimmer';
import AccordingCollapse from '../../../components/AccordingCollapse'

export default class Content extends Component {
	getData = () => {
		let res_data = [
		{
			'title': 'Social Media Management',
			'desc': 'The importance of social media presence can no longer be ignored. Spread your story fit to customer’s screen.'
		},
		{
			'title': 'Media Planning',
			'desc': 'Today’s media landscape requires a neutral perspective from an experienced team. Take objective views to develop integrated cross channel media campaigns.'
		},
		{
			'title': 'Multimedia Project',
			'desc': 'Creative concepts, producing, editing, motion graphics, and more.'
		},
		{
			'title': 'WhatsApp, Email & SMS Broadcast Engine',
			'desc': 'Your campaigns are delivered instantly in your customer’s pockets.'
		},
		{
			'title': 'Graphic Design as a Service',
			'desc': 'Tried-and-true creative processes, serve fresh and unique designs that you’ll love.'
		},
		{
			'title': 'OOH Advertising',
			'desc': 'In the digital age, OOH advertising is far from dead. Find an easy way to grab eyeballs of all ages and demographics with a single ad.'
		}
		];

		let storeNull = []
		for (let i = 0; i < 6; i++) {
			storeNull.push(<Shimmer width={"20rem"} height={"4rem"} />)
		}

		let storeData = (this.props.storeData !== null) ? this.props.storeData.split('|') : storeNull
		res_data.map((row, idx) => (
			row.desc = storeData[idx]
		))

		return res_data
	}


    render(){
    	return(
    		<section>
		        <AccordingCollapse data={this.getData()} title="Product & Services" sub_title=<span className="text-pink">Nexa Digital</span> textColor="text-pink" />
    		</section>
    	)
    }
}