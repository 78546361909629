import React, {Component} from 'react';
import { Shimmer } from 'react-shimmer';
import AccordingCollapse from '../../components/AccordingCollapse'

export default class Content extends Component {
	// getData = () => {
	// 	let res_data = [
	// 	{
	// 		'title': 'Design & Planning',
	// 		'desc': 'Through concept design engineering we develop accurate costs, data and models to bring confidence to this crucial project decision-making stage'
	// 	},
	// 	{
	// 		'title': 'Data Infrastructure Solution',
	// 		'desc': 'We’ll do from A to Z. Start with Data Center building, until the heavy lifting for functions such as maintenance, systems monitoring, reporting and technical support.'
	// 	},
	// 	{
	// 		'title': 'Monitoring / Situation Room',
	// 		'desc': 'Experienced control room designers where you have projects.'
	// 	},
	// 	{
	// 		'title': 'Hardware as a Service',
	// 		'desc': <p>Electronic Equipments and Supporting Instruments
	// 				Normalize cost and benefit from constantly refreshed and up-to-date
	// 				devices instead of capitalizing these assets and worrying about their devices aging. Renting ensures access to keep pace with technology that help you get to market faster.
	// 				Why rent our equipment :<br/>
	// 				- Competitive pricing<br/>
	// 				- Broad range of manufacturers<br/>
	// 				- One-stop-shop for all your needs <br/>- Extensively tested equipment</p>
	// 	},
	// 	{
	// 		'title': 'Infrastructure as a Service',
	// 		'desc': <div>
	// 					<p>Remove the key barriers of cost & complexity to be simple.</p>
	// 					<div className="companyabout-text04 text-black pb-0 mb-2">
	// 					<strong>A. Structured Cabling System & Wi-Fi Solution</strong>
	// 					</div>
	// 					<ol>
	// 					<li>Structure Cabling Design & Installation</li>
	// 					<li>Fiber Optic Cabling</li>
	// 					<li>WLAN Network Installation</li>
	// 					<li>Wireless LAN Solutions</li>
	// 					</ol>

	// 					<div className="companyabout-text04 text-black pb-0 mb-2">
	// 					<strong>B. Fiber Optic Distribution</strong>
	// 					</div>
	// 					<ol>
	// 					<li>Survey and Design</li>
	// 					<li>Services Locating</li>
	// 					<li>Direct Laying / Mole Ploughing</li>
	// 					<li>Directional Drilling</li>
	// 					<li>Conventional / Automated Trenching</li>
	// 					<li>Cable Hauling and Blowing</li>
	// 					<li>Manhole Construction and Conduit Installation</li>
	// 					<li>Aerial Construction – Adss Tension Stringing</li>
	// 					<li>Splicing, Testing and Cutover Of Fibre Optics</li>
	// 					</ol>
	// 				</div>

	// 	}
	// 	];

	// 	return res_data;
	// }

	getData = () => {

		let res_data = [
			{
				'title': 'Design & Planning',
				'desc': 'Through concept design engineering we develop accurate costs, data and models to bring confidence to this crucial project decision-making stage'
			},
			{
				'title': 'Data Infrastructure Solution',
				'desc': 'We’ll do from A to Z. Start with Data Center building, until the heavy lifting for functions such as maintenance, systems monitoring, reporting and technical support.'
			},
			{
				'title': 'Monitoring / Situation Room',
				'desc': 'Experienced control room designers where you have projects.'
			},
			{
				'title': 'Hardware as a Service',
				'desc': <p>Electronic Equipments and Supporting Instruments
						Normalize cost and benefit from constantly refreshed and up-to-date
						devices instead of capitalizing these assets and worrying about their devices aging. Renting ensures access to keep pace with technology that help you get to market faster.
						Why rent our equipment :<br/>
						- Competitive pricing<br/>
						- Broad range of manufacturers<br/>
						- One-stop-shop for all your needs <br/>- Extensively tested equipment</p>
			},
			{
				'title': 'Infrastructure as a Service',
				'desc': <div>
							<p>Remove the key barriers of cost & complexity to be simple.</p>
							<div className="companyabout-text04 text-black pb-0 mb-2">
							<strong>A. Structured Cabling System & Wi-Fi Solution</strong>
							</div>
							<ol>
							<li>Structure Cabling Design & Installation</li>
							<li>Fiber Optic Cabling</li>
							<li>WLAN Network Installation</li>
							<li>Wireless LAN Solutions</li>
							</ol>
	
							<div className="companyabout-text04 text-black pb-0 mb-2">
							<strong>B. Fiber Optic Distribution</strong>
							</div>
							<ol>
							<li>Survey and Design</li>
							<li>Services Locating</li>
							<li>Direct Laying / Mole Ploughing</li>
							<li>Directional Drilling</li>
							<li>Conventional / Automated Trenching</li>
							<li>Cable Hauling and Blowing</li>
							<li>Manhole Construction and Conduit Installation</li>
							<li>Aerial Construction – Adss Tension Stringing</li>
							<li>Splicing, Testing and Cutover Of Fibre Optics</li>
							</ol>
						</div>
	
			}
			];
		let storeNull = [], storeEng = []
		for (let i = 0; i < 5; i++) {
			storeNull.push(<Shimmer width={"20rem"} height={"4rem"} />)
		}
		res_data.map(row => storeEng.push(row.desc) )

		let storeData = (this.props.storeData !== null) ? (this.props.storeData.lang ? storeEng : this.props.storeData.result) : storeNull
		res_data.map((row, idx) => (
			row.desc = storeData[idx]
		))

		return res_data;
	}


    render(){
    	return(
    		<section>
		    	<AccordingCollapse data={this.getData()} addClass={'pe-3 ps-3'} sub_title=<span className="text-primary">Nexa Solution</span> title="Managed Services" textColor="text-primary" />
    		</section>
    	)
    }
}