import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import reportWebVitals from './reportWebVitals';
import  { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import langApp from './store/lang/Lang';
import Header from './components/Header';
import Footer from './components/Footer';
import {isMobile} from 'react-device-detect';
import MobileHeader from './mobile/components/Header';
import FooterMobile from './mobile/components/Footer';

const initialState = { 
  langs: [
  {
    id: 1,
    name: 'Indonesia',
    idLang: 2,
  }
]
};

let apakahHeaderMobil = (isMobile) ? <MobileHeader /> : <Header />
let apakahFooterMobil = (isMobile) ? <FooterMobile /> : <Footer />


const store = createStore(langApp, initialState);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store} >
    <noscript>You need to enable JavaScript to run this app.</noscript>
  	<BrowserRouter>
  		<ScrollToTop />
        {apakahHeaderMobil}
    	  <App />
        {apakahFooterMobil}
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
