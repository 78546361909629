import React, { Component } from "react";
// import Container from 'react-bootstrap/Container';
import Portofolio from "../../../components/Portofolio";
import YoutubeEmbed from "../../../mobile/components/YoutubeEmbed";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { NavLink } from "react-router-dom";
import OurPartners from "../../../components/OurPartners";
import { bigTv as mediaQuery } from "../../../components/MediaQueries";
import { iPadScreen as mediaQueryIpad } from "../../../components/MediaQueries";
import {
  LineBreak,
  formatTanggal,
  stripHtml,
  detectDevice,
} from "../../../controller/index";
import '../../../assets/splide-sea-green.min.css';
import { Shimmer } from "react-shimmer";
import { browserName, browserVersion } from "react-device-detect";

let isCompatible = detectDevice();

console.log(browserName);
console.log(browserVersion);

let iNumber = 1;
export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_data:
        document.querySelectorAll("#__newsSlider .splide__slide").length - 2,
    };
  }

  componentDidMount() {
    this.totalDataArticle = setInterval(() => this.setTotalArticle(), 1000);
    // console.log(sessionStorage.getItem('lang'))
  }

  componentWillUnmount() {
    clearInterval(this.totalDataArticle);
  }

  formatTanggal = (paramDate, id) => {
    let date = new Date(paramDate);
    let tahun = date.getFullYear();
    let bulan = date.getMonth();
    let tanggal = date.getDate();
    // let hari = date.getDay();

    let bulanIndonesia = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    let bulanEnglish = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (parseInt(id) === 2) {
      bulan = bulanIndonesia[bulan];
    } else {
      bulan = bulanEnglish[bulan];
    }
    return `${tanggal} ${bulan} ${tahun}`;
  };

  setTotalArticle() {
    this.setState({
      total_data:
        document.querySelectorAll("#__newsSlider .splide__slide").length - 2,
    });
  }

  handleSlidePrev = () => {
    let totalData =
      document.querySelectorAll("#__newsSlider .splide__slide").length - 2;
    document.querySelector(".numberSlide").innerHTML =
      (iNumber <= 1 ? 1 : (iNumber -= 1)) + " / " + totalData;
    document.querySelector(".news-prev").click();
  };

  handleSlideNext = async () => {
    let totalData =
      document.querySelectorAll("#__newsSlider .splide__slide").length - 2;
    document.querySelector(".numberSlide").innerHTML =
      (iNumber >= totalData ? totalData : (iNumber += 1)) + " / " + totalData;
    document.querySelector(".news-next").click();
  };

  handleArticlePrev = () => {
    document
      .querySelector(
        "#__newsWhyNexa .splide__arrows .splide__arrow.splide__arrow--prev"
      )
      .click();
  };

  handleArticleNext = () => {
    document
      .querySelector(
        "#__newsWhyNexa .splide__arrows .splide__arrow.splide__arrow--next"
      )
      .click();
  };

  directHelpdesk = () => {
    window.open("http://wa.gmedia.id/hd", "_blank");
  };

  handleListYoutube = () => {
    let cards = [];
    let res_data = [
      {
        url_video: "https://www.youtube.com/embed/pgt1plVRnmY",
        title: "Testimoni 1",
      },
      {
        url_video: "https://www.youtube.com/embed/JW1tPKh8deo",
        title: "Testimoni 2",
      },
      {
        url_video: "https://www.youtube.com/embed/49-VDRqCfaE",
        title: "Testimoni 3",
      },
    ];

    res_data.forEach((res, index) => {
      cards.push(
        <YoutubeEmbed
          embedId={res.url_video.replace("https://www.youtube.com/embed/", "")}
          key={index}
          heightNumber="180"
          classContainer="col-sm-6 col-md-4 pe-3 ps-3 pb-3"
        />
      );
    });

    return (
      <div className="mt-5 row" style={{ maxWidth: "1100px" }}>
        {cards}
      </div>
    );
  };

  createCardNews = () => {
    let cards = [],
      res_data = [],
      sBackground = [
        "https://images.unsplash.com/photo-1573164713712-03790a178651?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE5fHxzZXJ2ZXJ8ZW58MHx8fHwxNjUyODQ1OTIz&ixlib=rb-1.2.1&w=400",
        "https://images.unsplash.com/photo-1553481187-be93c21490a9?crop=entropy&cs=tinysrgb&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fGdhbWV8ZW58MHx8fHwxNjUyOTQ1MzUx&ixlib=rb-1.2.1&q=80&raw_url=true&w=400",
        "https://images.unsplash.com/photo-1564457461758-8ff96e439e83?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHNlcnZlcnxlbnwwfHx8fDE2NTI4NDU5MjM&ixlib=rb-1.2.1&w=400",
      ];
    for (let i = 1; i <= 6; i++) {
      res_data.push({
        id: i,
        kategori: "ENTERTAINMENT " + i,
        url_image: sBackground[Math.floor(Math.random() * sBackground.length)],
        title: "AI is Applicable in Helping your Business Level of Sales",
        desc: "In a recent report titled ‘AI Guided Selling, Gartner found that the COVID-19 pandemic has caught many sales off guard.",
        date: "1" + i + " October 202" + i,
      });
    }

    if (this.props.news !== null) {
      res_data = this.props.news.slice(0, 6);
    } else {
      res_data = [];
    }

    res_data.forEach((response, index) => {
      cards.push(
        <NavLink
          to={"/news_detail?id=" + response.kode}
          key={response.title}
          className="p-0 mb-5"
          style={{ marginRight: "3vw", display: "inline-block" }}
        >
          <article className="newscard-container">
            <div className="newscard-image" style={{ height: "235px" }}>
              <img
                loading="lazy"
                alt={`Nexa News - ${response.title}`}
                src={response.image}
                className="h-100"
                style={{ objectFit: "cover" }}
              />
              <div className="newscard-container1">
                <span className="newscard-text">{response.label}</span>
              </div>
            </div>
            <div className="newscard-content">
              <h2 className="newscard-text1">
                <span>{response.title}</span>
              </h2>
              <span className="newscard-conten">
                <span>
                  {stripHtml(response.deskripsi)
                    .substr(0, 200)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ")}
                  ...
                </span>
              </span>
            </div>
            <div style={{ "margin-bottom": "10px", "margin-left": "2rem" }}>
              <span className="newscard-text2">
                <span>
                  {this.formatTanggal(response.tanggal, response.id_bahasa)}
                </span>
              </span>
            </div>
          </article>
        </NavLink>
      );
    });

    return cards;
  };

  createSlider = () => {
    let aCards = [];
    let res_data = [
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_0.png",
      },
    ];

    if (this.props.news !== null) {
      res_data = this.props.news;
    } else {
      res_data.forEach((row) => {
        row.image = <Shimmer width={"20rem"} height={"4rem"} />;
        row.title = <Shimmer width={"20rem"} height={"4rem"} />;
        row.deskripsi = "";
        row.label = <Shimmer width={"20rem"} height={"4rem"} />;
      });
    }

    // console.log(this.props.news, 'content')
    let nums_news = 1;
    res_data.forEach((row, index) => {
      aCards.push(
        <SplideSlide
          key={index}
          className="p-2"
          count={res_data.length}
          page={index + 1}
        >
          <article className="newscard-container">
            <NavLink to={"/news_detail?id=" + row.kode}>
              <div className="newscard-image" style={{ height: "235px" }}>
                <img
                  loading="lazy"
                  alt={`Nexa News - ${row.title}`}
                  src={row.image}
                  className="h-100"
                  style={{ objectFit: "cover" }}
                />
                <div className="newscard-container1">
                  <span className="newscard-text">{row.label}</span>
                </div>
              </div>
              <div className="newscard-content">
                <h2 className="newscard-text1">
                  <span className="max-lines">{row.title}</span>
                </h2>
                <span className="newscard-conten">
                  <span>
                    {row.deskripsi
                      ? stripHtml(row.deskripsi)
                          .slice(0, 200)
                          .split(" ")
                          .slice(0, -1)
                          .join(" ")
                      : ""}
                    ...
                  </span>
                </span>
              </div>
              <div style={{ marginBottom: "10px", marginLeft: "2rem" }}>
                <span className="newscard-text2">
                  <span>{formatTanggal(row.tanggal, row.id_bahasa)}</span>
                </span>
              </div>
            </NavLink>
          </article>
        </SplideSlide>
      );
      nums_news++;
    });

    aCards.push(<SplideSlide key="empty" page={res_data.length}></SplideSlide>);

    return (
      <Splide
        className="p-0"
        id="__newsSlider"
        style={mediaQuery ? { width: "unset" } : {}}
        options={{
          perPage: mediaQuery ? 3 : mediaQueryIpad ? 1 : 2,
          perMove: 1,
          gap: mediaQuery ? "3vw" : "6vw",
        }}
        aria-label="My Favorite Images"
      >
        {aCards}

        <div className="splide__arrows splide__arrows--ltr d-none">
          <button
            className="splide__arrow splide__arrow--prev news-prev"
            type="button"
            aria-label="Previous slide"
            aria-controls="splide02-track"
          ></button>

          <button
            className="splide__arrow splide__arrow--next news-next"
            type="button"
            aria-label="Go to first slide"
            aria-controls="splide02-track"
          ></button>
        </div>
      </Splide>
    );
  };

  createSliderArticle = () => {
    let cards = [],
      res_data = [
        {
          title:
            this.props.homeLangData != null ? (
              this.props.homeLangData.whyNexaCard1
            ) : (
              <Shimmer width={"20rem"} height={"4rem"} />
            ),
          url_image: isCompatible
            ? "https://minio.nexa.net.id/nexa/why_3.webp"
            : "https://minio.nexa.net.id/nexa/why_3.jpeg",
        },
        {
          title:
            this.props.homeLangData != null ? (
              this.props.homeLangData.whyNexaCard2
            ) : (
              <Shimmer width={"20rem"} height={"4rem"} />
            ),
          url_image: isCompatible
            ? "https://minio.nexa.net.id/nexa/why_2.webp"
            : "https://minio.nexa.net.id/nexa/why_2.jpeg",
        },
        {
          title:
            this.props.homeLangData != null ? (
              this.props.homeLangData.whyNexaCard3
            ) : (
              <Shimmer width={"20rem"} height={"4rem"} />
            ),
          url_image: isCompatible
            ? "https://minio.nexa.net.id/nexa/why_1.webp"
            : "https://minio.nexa.net.id/nexa/why_1.jpeg",
        },
      ];

    res_data.forEach((res, index) => {
      cards.push(
        <SplideSlide
          key={index}
          className="p-2 app-component-container"
          style={{ width: "unset !important" }}
        >
          <div className="app-component-container1" style={{ width: "32rem" }}>
            <img alt="" src={res.url_image} className="app-component-image" />
            <div className="app-component-text foreground-gradient ps-4 w-100">
              <span
                className="pb-3 pe-2"
                style={{ bottom: "0", position: "absolute" }}
              >
                {res.title}
              </span>
            </div>
          </div>
        </SplideSlide>
      );
    });

    cards.push(
      <SplideSlide
        key="empty"
        className="p-2 app-component-container"
        style={{ width: "unset !important" }}
      >
        <div className="app-component-container1"></div>
      </SplideSlide>
    );

    return cards;
  };

  renderArticle = () => {
    return (
      <Splide
        className="p-0 home-container19 w-100"
        id="__newsWhyNexa"
        options={{
          rewind: true,
          perPage: mediaQuery ? 2 : 1,
          perMove: 1,
          gap: "17vw",
        }}
        aria-label="My Favorite Images"
      >
        {this.createSliderArticle()}
      </Splide>
    );
  };

  render() {
    return (
      <div>
        <style jsx="true">
          {`
            /* Container needed to position the button. Adjust the width as needed */
            .container {
              position: relative;
              width: 100%;
            }

            /* Make the image responsive */
            .container img {
              width: 100%;
              height: auto;
            }

            /* Style the button and place it in the middle of the container/image */
            .container .btn1 {
              position: absolute;
              top: 76%;
              left: 18%;
              transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              background-color: #555;
              color: white;
              font-size: 16px;
              padding: 0px 0px;
              border: none;
              cursor: pointer;
              border-radius: 5px;
            }

            .container .btn2 {
              position: absolute;
              top: 76%;
              left: 47%;
              transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              background-color: #555;
              color: white;
              font-size: 16px;
              padding: 0px 0px;
              border: none;
              cursor: pointer;
              border-radius: 5px;
            }

            .img-btn {
              width: 100px;
            }

            .container .btn1:hover {
              background-color: rgba(0, 0, 0, 0.3);
            }
            .container .btn2:hover {
              background-color: rgba(0, 0, 0, 0.3);
            }

            .container_download {
              margin-top: -4rem;
              margin-left: 0.4rem;
            }

            .max-lines {
              display: block; /* or inline-block */
              text-overflow: ellipsis;
              word-wrap: break-word;
              overflow: hidden;
              max-height: 3.6em;
              line-height: 1.8em;
            }
          `}
        </style>

        <div className="button-wa-fixed">
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"
          >
            <svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed">
              <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
            </svg>
          </a>
        </div>

        <section className="home-section2">
          <div
            className="home-maxwith1"
            style={mediaQuery ? { top: "-200px" } : {}}
          >
            <div className="row m-0 d-flex justify-content-center">
              <div className="home-container02 card col-4 mt-4 border-0 pe-0">
                <img
                  alt=""
                  src={
                    isCompatible
                      ? "https://minio.nexa.net.id/nexa/nexatel.webp"
                      : "https://minio.nexa.net.id/nexa/nexatel.png"
                  }
                  className="home-image mb-2 card-img-top"
                />
                <div className="card-body mb-2 lh-1-4 pe-0">
                  <div className="card-text" style={{ lineHeight: "1.6" }}>
                    {LineBreak(this.props.storeData[0].services.tel.desc)}
                  </div>
                </div>
                <NavLink
                  to="/nexatel"
                  className="card-footer home-button button ps-4 ms-3 border-0"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                  <span className="learn">Learn More</span>
                </NavLink>
              </div>
              <div className="home-container02 card col-4 mt-4 border-0 pe-0">
                <img
                  alt=""
                  src={
                    isCompatible
                      ? "https://minio.nexa.net.id/nexa/nexaapps.webp"
                      : "https://minio.nexa.net.id/nexa/nexaapps.png"
                  }
                  className="home-image mb-2 card-img-top"
                />
                <div className="card-body mb-2 lh-1-4 pe-0">
                  <div className="card-text" style={{ lineHeight: "1.6" }}>
                    {LineBreak(this.props.storeData[0].services.app.desc)}
                  </div>
                </div>
                <NavLink
                  to="/nexaapps"
                  className="card-footer home-button button ps-3 border-0"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                  <span className="learn">Learn More</span>
                </NavLink>
              </div>
              <div className="home-container02 card col-4 mt-4 border-0 pe-0">
                <img
                  alt=""
                  src={
                    isCompatible
                      ? "https://minio.nexa.net.id/nexa/nexadata.webp"
                      : "https://minio.nexa.net.id/nexa/nexadata.png"
                  }
                  className="home-image01 mb-2 card-img-top"
                />
                <div className="card-body mb-2 lh-1-4 pe-0">
                  <div className="card-text" style={{ lineHeight: "1.6" }}>
                    {LineBreak(this.props.storeData[0].services.data.desc)}
                  </div>
                </div>
                <NavLink
                  to="/nexadata"
                  className="card-footer home-button button ps-3 border-0"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                  <span className="learn">Learn More</span>
                </NavLink>
              </div>
              <div className="home-container02 card col-4 mt-4 border-0 pe-0">
                <img
                  alt=""
                  src={
                    isCompatible
                      ? "https://minio.nexa.net.id/nexa/nexadigital.webp"
                      : "https://minio.nexa.net.id/nexa/nexadigital.png"
                  }
                  className="home-image01 mb-2 card-img-top"
                />
                <div
                  className="card-body mb-2 lh-1-4 pe-0"
                  style={{ lineHeight: "1.6" }}
                >
                  <div className="card-text">
                    {LineBreak(this.props.storeData[0].services.digital.desc)}
                  </div>
                </div>
                <NavLink
                  to="/nexadigital"
                  className="card-footer home-button button ps-3 border-0"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                  <span className="learn">Learn More</span>
                </NavLink>
              </div>
              <div className="home-container02 card col-4 mt-4 border-0 pe-0">
                <img
                  alt=""
                  src={
                    isCompatible
                      ? "https://minio.nexa.net.id/nexa/nexasolution.webp"
                      : "https://minio.nexa.net.id/nexa/nexasolution.png"
                  }
                  className="home-image01 mb-2 card-img-top"
                />
                <div
                  className="card-body mb-2 lh-1-4 pe-0"
                  style={{ lineHeight: "1.6" }}
                >
                  <div className="card-text">
                    {LineBreak(this.props.storeData[0].services.solution.desc)}
                  </div>
                </div>
                <NavLink
                  to="/solution"
                  className="card-footer home-button button ps-3 border-0"
                >
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                  <span className="learn">Learn More</span>
                </NavLink>
              </div>
              <div className="home-container02 card col-4 mt-4 border-0 pe-0">
                <span className="card-body home-text05 pe-0 ps-0">
                  <span className="card-text home-text06">
                    {this.props.storeData[0].services.conn.desc.split("|")[0]}
                  </span>
                  <br />
                  <span className="home-text07">
                    {this.props.storeData[0].services.conn.desc.split("|")[1]}
                  </span>
                </span>
              </div>
            </div>
            <div className="home-container05">
              <div className="home-container06">
                <div className="home-container-mobile">
                  <div className="home-container07">
                    <div className="home-nexatel1">
                      <img
                        alt=""
                        src="./playground_assets/nexa%20tel%20logo%20copy-200h.png"
                        className="home-image05 card-img-top"
                      />
                      <button className="home-button05 button">
                        <svg viewBox="0 0 1024 1024" className="home-icon10">
                          <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                        <span className="learn">Learn More</span>
                      </button>
                    </div>
                    <div className="home-nexadigital1">
                      <img
                        alt=""
                        src="./playground_assets/nexa%20digital%20logo%20copy-200h.png"
                        className="home-image06 card-img-top"
                      />
                      <button className="home-button06 button learn">
                        <svg viewBox="0 0 1024 1024" className="home-icon12">
                          <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                        </svg>
                        <span>Learn More</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="home-container08">
                  <div className="home-nexaapps1">
                    <img
                      alt=""
                      src="./playground_assets/nexa%20apps%20logo%20copy-200h.png"
                      className="home-image07 card-img-top"
                    />
                    <button className="home-button07 button">
                      <svg viewBox="0 0 1024 1024" className="home-icon14">
                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                      <span className="learn">Learn More</span>
                    </button>
                  </div>
                  <div className="home-nexasolution1">
                    <img
                      alt=""
                      src="./playground_assets/nexa%20solution%20logo%20copy-200h.png"
                      className="home-image08 card-img-top"
                    />
                    <button className="home-button08 button">
                      <svg viewBox="0 0 1024 1024" className="home-icon16">
                        <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                      </svg>
                      <span className="learn">Learn More</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="home-container09">
                <div className="home-nexadata2">
                  <img
                    alt=""
                    src="./playground_assets/nexa%20data%20logo%20copy-200h.png"
                    className="home-image09 card-img-top"
                  />
                  <button className="home-button09 button">
                    <svg viewBox="0 0 1024 1024" className="home-icon18">
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    <span className="learn">Learn More</span>
                  </button>
                </div>
                <div className="home-nexadata3">
                  <span className="home-text13">
                    <span className="home-text14">Connections</span>
                    <span className="home-text15">More</span>
                    <br />
                    <span className="home-text16">Than</span>
                    <span className="home-text17">Anything</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="home-section3"
          style={mediaQuery ? { marginTop: "10vh" } : { marginTop: "32rem" }}
        >
          <div className="home-container10">
            <span className="home-text18">
              {this.props.storeData[0].news.title.split("|")[0]}
            </span>
            <h2 className="home-text19 fw-bold">
              {this.props.storeData[0].news.title.split("|")[1]}
            </h2>
          </div>
          <div
            className={
              mediaQuery ? "home-container11" : "home-container11 p-0 pl-2"
            }
            style={
              mediaQuery
                ? { maxWidth: "1366px", display: "unset", paddingLeft: "6rem" }
                : { display: "unset", maxWidth: "1170px", marginLeft: "8vw" }
            }
          >
            {this.createCardNews()}
            {/* digantii */}
          </div>
          <div className="home-container12">
            <div className="d-flex flex-wrap align-self-center w-75 mainNumber">
              <div
                className="home-container15 col"
                style={{ width: "unset", alignSelf: "center" }}
              ></div>
            </div>
            <div className="home-container13">
              <NavLink to="/news">
                <p>Selengkapnya </p>
              </NavLink>
              {/* <svg
                viewBox="0 0 1024 1024"
                className="home-icon22"
                onClick={this.handleSlideNext}
              >
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg> */}
            </div>
          </div>
        </section>

        <section className="home-section4">
          <div className="home-container16">
            <span className="home-text20">
              <span></span>
              <span>Business</span>
            </span>
            <h2 className="home-text23">Why Nexa?</h2>
            <span className="home-text24">
              <span>
                {this.props.homeLangData != null ? (
                  this.props.homeLangData.whyNexaDesc1
                ) : (
                  <Shimmer width={"20rem"} height={"4rem"} />
                )}
              </span>
              <br />
              <span>
                {this.props.homeLangData != null ? (
                  this.props.homeLangData.whyNexaDesc2
                ) : (
                  <Shimmer width={"20rem"} height={"4rem"} />
                )}
              </span>
            </span>
            <NavLink to="/contact">
              <button className="home-register button">Try Now</button>
            </NavLink>
          </div>
          <div className="home-container17 pr-0">
            <div className="home-container18 w-100">{this.renderArticle()}</div>
            <div className="home-container22">
              <svg
                viewBox="0 0 1024 1024"
                className="home-icon24"
                onClick={this.handleArticlePrev}
              >
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                className="home-icon26"
                onClick={this.handleArticleNext}
              >
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
          </div>
        </section>

        <section className="home-section5">
          <div className="home-container30">
            <iframe
              loading="lazy"
              title="video"
              src="https://www.youtube.com/embed/-dhjSMCLdv4"
              className="home-iframe"
            ></iframe>
          </div>
        </section>

        {/* <div className="home-section6 mx-auto" style={{"height": "350px"}}>
	          	<div className="home-container31 p-0 row">
		          	<div className="nexatel-container18">
		          		<span className="nexatel-text40">    </span>
		          		<h2 className="nexatel-text41">Testimoni</h2>
		          	</div>
	          		{this.handleListYoutube()}
	          	</div>
	        </div> */}

        <Portofolio
          sub_title=" "
          title={
            this.props.homeLangData != null
              ? this.props.homeLangData.titlePortofolio
              : ""
          }
          desc={LineBreak(this.props.storeData[0].portofolio.desc)}
        />
        <OurPartners />

        {/* <iframe width="100%" height="650px" frameborder="0" src="https://nexanet.speedtestcustom.com"></iframe> */}

        <section className="home-section6 mx-auto" style={{ height: "350px" }}>
          <div className="home-container31">
            <div className="home-container32">
              <h2 className="home-text34">
                {this.props.homeLangData != null ? (
                  this.props.homeLangData.supportTitle
                ) : (
                  <Shimmer width={"20rem"} height={"4rem"} />
                )}
              </h2>
              <a href="http://wa.gmedia.id/hd" target="__blank">
                <div className="home-container33 direct-helpdesk">
                  <svg viewBox="0 0 1024 1024" className="home-icon-wa me-2">
                    <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
                  </svg>
                  <h2 className="home-text35 pt-2 fs-24"> +62 811-2971-000</h2>
                </div>
              </a>
              <a href="http://wa.me/6282115555646" target="__blank">
                <div className="home-container33 direct-helpdesk">
                  <svg viewBox="0 0 1024 1024" className="home-icon-wa me-2">
                    <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
                  </svg>
                  <h2 className="home-text35 pt-2 fs-24"> +62 8211-5555-646</h2>
                </div>
              </a>
              <span className="home-text36">
                {this.props.homeLangData != null ? (
                  this.props.homeLangData.supportDesc
                ) : (
                  <Shimmer width={"20rem"} height={"4rem"} />
                )}
              </span>
            </div>
            <div className="home-container34">
              <div className="home-container35">
                <svg viewBox="0 0 1024 1024" className="home-icon-kontak">
                  <path d="M512 42q160 0 272 113t112 271v428q0 52-38 90t-90 38h-256v-86h298v-42h-170v-342h170v-86q0-124-87-211t-211-87-211 87-87 211v86h170v342h-128q-52 0-90-38t-38-90v-300q0-158 112-271t272-113z"></path>
                </svg>
                <h2 className="home-text37">
                  {this.props.homeLangData != null ? (
                    this.props.homeLangData.kontakTitle
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </h2>
              </div>
              <span className="home-text38">
                <span>
                  {this.props.homeLangData != null ? (
                    this.props.homeLangData.kontakDesc
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </span>
                {/* <span>and always ready to help you.</span> */}
              </span>
              {/* <NavLink to="/subscribe"><button className="home-register2 button">Contact Now</button></NavLink> */}
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"
              >
                <button className="home-register2 button">Contact Now</button>
              </a>
            </div>
            <div className="home-container36">
              <div className="home-container37">
                <h2 className="home-text41">
                  {" "}
                  {this.props.homeLangData != null ? (
                    this.props.homeLangData.downloadTitle
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </h2>
              </div>
              <span className="home-text42">
                <div className="container">
                  <img
                    alt="download_now"
                    src={this.props.img_download}
                    className="img-responsive w-100"
                  />
                  <div className="container_download col">
                    <button
                      onClick={() =>
                        window.open(this.props.link_android, "_blank")
                      }
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        alt="Download to Google Play"
                        className="img-responsive"
                        style={{ width: "5rem" }}
                        src="https://minio.nexa.net.id/nexa/android.png"
                      ></img>
                    </button>
                    <button
                      onClick={() => window.open(this.props.link_ios, "_blank")}
                      style={{ backgroundColor: "transparent" }}
                    >
                      <img
                        alt="Download to App Store"
                        className="img-responsive"
                        style={{ width: "5rem" }}
                        src="https://minio.nexa.net.id/nexa/aiso.png"
                      ></img>
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
