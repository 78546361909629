import Banner from '../../components/Banner';
import AfterBanner from '../../components/AfterBanner';
import Content from './Content';
import Interested2 from '../../components/Interested2';
import Footer from '../../components/Footer';

import  React from 'react'
import { useSelector } from 'react-redux'
import { AboutUs, LineBreak, CreateToken, detectDevice } from '../../../controller/index'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice();

export default function Home() {
	const [result, setResult] = React.useState(null)

	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((dataToken) => {
			AboutUs().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
	
	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text fw-bold" style={{fontSize:"1.6rem"}}><span>{result !== null ? result.tentang_1.split('|')[0] : <Shimmer width={1366} height={800} /> }</span></h1>;;
	let eTitle				= result !== null ? result.tentang_1.split('|')[0] : "Tentang Kami"
	let sBanner_desc 		= result !== null ? LineBreak(result.tentang_1.split('|')[1]) : <Shimmer  width={"inherit"} height={"5rem"}/>
	let sAfterBanner_title 	= result !== null ? LineBreak(result.tentang_2) : <Shimmer  width={"inherit"} height={"5rem"}/>
	let sAfterBanner_desc 	= result !== null ? LineBreak(result.tentang_3) : <Shimmer width={"inherit"} height={"5rem"} />
	let mainBannerPage		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_about.webp" : "https://minio.nexa.net.id/nexa/banner_about.jpeg";
	let eStyle = { "width" : "100%", "gridGap":"unset" };
	return (
		<>
	    	{/* <Banner slider={false} title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={eStyle} /> */}
	    	
			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			<Banner slider={false} title={eBanner} url_image={mainBannerPage} style={eStyle} />

			<AfterBanner title=	{sAfterBanner_title} desc={sAfterBanner_desc} styleSection={{"minHeight":"200px"}} button={false} />
	    	<Content storeData={(result !== null) && result } />
	    	{ (result == null) && <Interested2 storeData={null} /> }
			{ (result !== null) && <Interested2 storeData={result} /> }
		
	    	
	    </>
	)
}