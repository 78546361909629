import React, {Component} from 'react';
// import Container from 'react-bootstrap/Container';
import '../../assets/companyabout.css';

export default class Interested2 extends Component {
  constructor(props){
    super(props)
    if (typeof this.props.storeData !== 'undefined') {
      this.state = {
        title: (this.props.storeData !== null) ? ((this.props.storeData.footer !== null) ? this.props.storeData.footer.split('|')[0]:null) : null,
        desc: (this.props.storeData !== null) ? ((this.props.storeData.footer !== null) ? this.props.storeData.footer.split('|')[1]:null) : null,
      }
    } else {
      this.state = {
        title: this.getTitle(),
        desc: this.getDesc()
      }
    }
    console.log(this.state, this.props)
  }

  getTitle = () => {
    return 'Interested to Try'
  }
  getDesc = () => {
    return <span>For new customers, you can find out <br/> more by contacting us.</span>
  }
  render(){
      return (
        <section className="companyabout-container11" style={{backgroundImage: `url("https://minio.nexa.net.id/nexa/nexa-footer-background-1500w.png")` }}>
          <div className="companyabout-container12">
            <h2 className="companyabout-text61 fw-bold text-center" style={{fontSize:"2rem"}}>
              {/* { (this.state.title !== null) ? this.state.title : this.getTitle() } */}
              {this.props.storeData !== null ? this.props.storeData.footer.split('|')[0] : this.getTitle()}
            </h2>
            <span className="companyabout-text64" style={{"width":"300px"}}>
              { (this.props.storeData !== null) ? this.props.storeData.footer.split('|')[1]  : this.getDesc() }
            </span>
            <a target='_blank' href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"><button className="companyabout-register2 button">Contact Us</button></a>
          </div>
        </section>
      )
  }
}
