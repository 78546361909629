import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import MainSolutions 	from '../../components/MainSolutions';
import OtherSolutions 	from '../../components/OtherSolutions';
import Interested2 	from '../../components/Interested2';
import Content 		from './Content';
import Footer 		from '../../components/Footer';
import '../../../assets/solutioncorporate.css';

import React from 'react'
import { HospitallyPage, LineBreak, AboutUs, CreateToken, detectDevice } from '../../../controller/index'
import {useSelector} from 'react-redux'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice()

function Hospitally() {
	const [result, setResult] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	const [Interested, setInterested] = React.useState(null)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((Data) => {
			HospitallyPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
	
			AboutUs().then((data) => {
				setInterested(data.response[0])
			}).catch(err => {
				console.log(err)
			})
		})
	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text"><span>{(result !== null) ? result.tentang_1.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}:</span>
              <br/>
              <span className="solutioncorporate-text002">{(result !== null) ? result.tentang_1.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} />}</span>
            </h1>;
	let eTitle				= (result !== null) ? `${result.tentang_1.split('|')[0]} ${result.tentang_1.split('|')[1]}` : ""
	let sBanner_desc  		= (result !== null) ? LineBreak(result.tentang_1.split('|')[2]) : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_title 	= (result !== null) ? result.tentang_2.replaceAll('<br/>', '') : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_desc 	= (result !== null) ? result.tentang_3.replaceAll('<br/>', '') : <Shimmer width={"20rem"} height={"4rem"} />
	let mainBannerPage		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_hospital.webp" : "https://minio.nexa.net.id/nexa/banner_hospital.jpeg"
	let desc				= (result !== null) ? result.tentang_3 : ""

	return (
		<div>
			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>
			
			{/* <Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={{"width": "100%"}} /> */}
			<Banner title={eBanner} url_image={mainBannerPage} style={{"width": "100%"}} />
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			<MainSolutions 
				title="Hospitally" 
				sub_title={(result !== null) && result.product_services.split('|')[0]}
				storeData={(result !== null) && result.product_services.split('|')} 
			/>
			{ (result == null) && <OtherSolutions storeData={null} /> }
			{ (result !== null) && <OtherSolutions storeData={result.another} /> }
			<Content />
			<Interested2 storeData={Interested} />
			
		</div>
		);
}

export default Hospitally;