import  React from 'react'
import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import Content 		from './Content';
import '../../assets/itsuport.css';

import { ItsPage, LineBreak, CreateToken, AboutUs } from '../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';
import Interested 	from '../../components/Interested';

function Its() {

	//Google Analitik
	ReactGA.event({category:"user", action:"IT Support"})
	
	const [result, setResult] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	const [interested, setInterested] = React.useState(null)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {

		CreateToken().then((data) => {

			AboutUs().then((data) => {
				setInterested(data.response[0]) 
			})

			ItsPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
	}, [langCondition]);
	
	let eBanner 			= <h1 className="banner-text fw-bold"><span>{ result !== null ? result.site_support.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}</span></h1>;
	let sBanner_desc  		= result !== null ? LineBreak(result.site_support.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_title 	= <div className="fs-36">{ result !== null ? LineBreak(result.site_support.split('|')[2]) : <Shimmer width={"20rem"} height={"4rem"} /> }</div>;
	let sAfterBanner_desc 	= <div className="fs-22">{ result !== null ? LineBreak(result.site_support.split('|')[3]) : <Shimmer width={"20rem"} height={"4rem"} /> }</div>;

	let mainBannerPage 		= './assets/images/banner/internet_service.jpeg';
	let eTitle 				= result !== null ? result.site_support.split('|')[0] : ""
	let desc				= result !== null ? LineBreak(result.site_support.split('|')[3]) : ""
	
	return (
		<div>
			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content="Nexa - Nexa Level Experience" ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content="https://nexa.net.id/"></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
      		</Helmet>
			<Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			{ result === null && <Content storeData={null} /> }
			{ result !== null && <Content storeData={{'site': LineBreak(result.site_support.split('|')[4]),'call': LineBreak(result.call_support), 'managed': LineBreak(result.managed_itsupport) }} /> }
			{ (interested == null) && <Interested storeData={null} /> }
			{ (interested !== null) && <Interested storeData={interested} /> }

		</div>
		);
}

export default Its;