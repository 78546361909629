import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select'
import { padding, textAlign } from '@mui/system';
import { Popover } from 'bootstrap';
import { MenuItem } from '@mui/material';
import $ from 'jquery'
import {detectDevice} from "../../controller/index"


let base_url_portofolio = './assets/images/portofolio/';
let res_data = [
  {
    'title': 'Logistic',
    'images': [
    {
      'title': 'Dakota',
      'url': base_url_portofolio + 'Logistic/dakota.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'DHL',
      'url': base_url_portofolio + 'Logistic/dhl.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Expeditors',
      'url': base_url_portofolio + 'Logistic/expeditors.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'JNE',
      'url': base_url_portofolio + 'Logistic/jne.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'J&T',
      'url': base_url_portofolio + 'Logistic/jnt.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'FMCG',
    'images': [
    {
      'title': 'Aice',
      'url': base_url_portofolio + 'FMCG/aice.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Cimory',
      'url': base_url_portofolio + 'FMCG/cimory.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Indofood',
      'url': base_url_portofolio + 'FMCG/indofood.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Orang Tua',
      'url': base_url_portofolio + 'FMCG/orang_tua.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'So Good',
      'url': base_url_portofolio + 'FMCG/so_good.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Manufacture',
    'images': [
    {
      'title': 'Hino',
      'url': base_url_portofolio + 'Manufacture/hino.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sami',
      'url': base_url_portofolio + 'Manufacture/sami.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sri Boga',
      'url': base_url_portofolio + 'Manufacture/sriboga.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Government',
    'images': [
    {
      'title': 'Akpol',
      'url': base_url_portofolio + 'Goverment/akpol.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Angkasa Pura',
      'url': base_url_portofolio + 'Goverment/angkasa_pura.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'BKKBN',
      'url': base_url_portofolio + 'Goverment/bkkbn.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'BPJS Kesehatan',
      'url': base_url_portofolio + 'Goverment/bpjs_kesehatan.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'BPJS Ketenagakerjaan',
      'url': base_url_portofolio + 'Goverment/bpjs_ketenagakerjaan.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'BPOM',
      'url': base_url_portofolio + 'Goverment/BPOM.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Kelautan',
      'url': base_url_portofolio + 'Goverment/kelautan.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Kementrian Agama',
      'url': base_url_portofolio + 'Goverment/kementrian_agama.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Perhutani',
      'url': base_url_portofolio + 'Goverment/perhutani.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'PUPR',
      'url': base_url_portofolio + 'Goverment/pupr.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Healthcare',
    'images': [
    {
      'title': 'Hermina',
      'url': base_url_portofolio + 'Healthcare/hermnina.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Jec',
      'url': base_url_portofolio + 'Healthcare/jec.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'KMN',
      'url': base_url_portofolio + 'Healthcare/kmn.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Hospitality',
    'images': [
    {
      'title': 'Accorhotel',
      'url': base_url_portofolio + 'Hospitality/accorhotel.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Archipelago',
      'url': base_url_portofolio + 'Hospitality/archipelago.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Artotel',
      'url': base_url_portofolio + 'Hospitality/artotel.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'DHM',
      'url': base_url_portofolio + 'Hospitality/dhm.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Horison',
      'url': base_url_portofolio + 'Hospitality/horison.png',
      'style': {
        "height":"100%",
        width: "120%"
      }
    },
    {
      'title': 'IHG',
      'url': base_url_portofolio + 'Hospitality/ihg.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Louis Kienne',
      'url': base_url_portofolio + 'Hospitality/lois_kienne.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Mesastila',
      'url': base_url_portofolio + 'Hospitality/mesastila.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Santika',
      'url': base_url_portofolio + 'Hospitality/santika.png',
      'style': {
        "height":"100%"
      }
    },
    
    {
      'title': 'Tentrem',
      'url': base_url_portofolio + 'Hospitality/tentrem.png',
      'style': {
        "height":"100%",
        "width":"70%",
        "margin":"auto"
      }
    },
    {
      'title': 'Swiss Belhotel',
      'url': base_url_portofolio + 'Hospitality/swiss_belhotel.png',
      'style': {
        "height":"100%"
      }
    },
    ]
  },
  {
    'title': 'Corporate',
    'images': [
    {
      'title': 'Carfix',
      'url': base_url_portofolio + 'Corporate/carfix.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Digimap',
      'url': base_url_portofolio + 'Corporate/digimap.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Gramedia',
      'url': base_url_portofolio + 'Corporate/gramedia.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Nasmoco',
      'url': base_url_portofolio + 'Corporate/nasmoco.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Optik Tunggal',
      'url': base_url_portofolio + 'Corporate/optik_tunggal.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Timezone',
      'url': base_url_portofolio + 'Corporate/timezone.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'XXI',
      'url': base_url_portofolio + 'Corporate/xxi.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Finance',
    'images': [
    {
      'title': 'BRI',
      'url': base_url_portofolio + 'Finance/bri.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'LIPPO',
      'url': base_url_portofolio + 'Finance/lipo.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'DBS',
      'url': base_url_portofolio + 'Finance/dbs.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'FIF',
      'url': base_url_portofolio + 'Finance/fif.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Nasari',
      'url': base_url_portofolio + 'Finance/nasari.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Restu Group',
      'url': base_url_portofolio + 'Finance/restu_group.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Cosmetics and Household',
    'images': [
    {
      'title': 'AJBS',
      'url': base_url_portofolio + 'Cosmetics/ajbs.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Farmer Market',
      'url': base_url_portofolio + 'Cosmetics/farmer_market.png',
      'style': {
        "height":"100%",
        margin:"auto"
      }
    },
    {
      'title': 'Herborist',
      'url': base_url_portofolio + 'Cosmetics/herboist.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Matahari',
      'url': base_url_portofolio + 'Cosmetics/matahari.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Miniso',
      'url': base_url_portofolio + 'Cosmetics/miniso.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Natasha',
      'url': base_url_portofolio + 'Cosmetics/natasha.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Toms',
      'url': base_url_portofolio + 'Cosmetics/toms.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Vitalis',
      'url': base_url_portofolio + 'Cosmetics/vitalis.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'You',
      'url': base_url_portofolio + 'Cosmetics/you.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Food and Beverages',
    'images': [
    {
      'title': 'Excelso',
      'url': base_url_portofolio + 'Food/exelxo.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Janji Jiwa',
      'url': base_url_portofolio + 'Food/janji_jiwa.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Starbucks',
      'url': base_url_portofolio + 'Food/starback.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Tanamera',
      'url': base_url_portofolio + 'Food/tanamera.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Building Construction',
    'images': [
    {
      'title': 'Adhi Karya',
      'url': base_url_portofolio + 'Building/adhi_karya.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Varia Beton',
      'url': base_url_portofolio + 'Building/varia_beton.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Electronics',
    'images': [
    {
      'title': 'Arisa',
      'url': base_url_portofolio + 'Electronics/arisa.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Infrastructure, Utility, and Transportation',
    'images': [
    {
      'title': 'Siba Surya',
      'url': base_url_portofolio + 'Infrastructure/siba_surya.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Tower Bersama',
      'url': base_url_portofolio + 'Infrastructure/tower_bersama.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Industrial Park',
    'images': [
    
    {
      'title': 'KIK',
      'url': base_url_portofolio + 'Industrial/kik.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'KIW',
      'url': base_url_portofolio + 'Industrial/kiw.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'KIC',
      'url': base_url_portofolio + 'Industrial/kic.png',
      'style': {
        "height":"100%"
      }
    },
    ]
  },
  {
    'title': 'Textile and Garment',
    'images': [
    {
      'title': 'Apacinti',
      'url': base_url_portofolio + 'Textile/apacinti.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Busana Apparel',
      'url': base_url_portofolio + 'Textile/busana_apparel.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Mas',
      'url': base_url_portofolio + 'Textile/mas.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sai Group',
      'url': base_url_portofolio + 'Textile/sai_group.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Property and Real Estate',
    'images': [
    {
      'title': 'Ciputra',
      'url': base_url_portofolio + 'Property/citra.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Paramount',
      'url': base_url_portofolio + 'Property/paramount.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Pollux',
      'url': base_url_portofolio + 'Property/pollux.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sinarmas',
      'url': base_url_portofolio + 'Property/sinarmas.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Pharmaceuticals',
    'images': [
    {
      'title': 'Erela',
      'url': base_url_portofolio + 'Pharmaceuticals/erla.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Erlimpex',
      'url': base_url_portofolio + 'Pharmaceuticals/erlimpex.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sampharindo',
      'url': base_url_portofolio + 'Pharmaceuticals/samparindo.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sido Muncul',
      'url': base_url_portofolio + 'Pharmaceuticals/sido_muncul.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  },
  {
    'title': 'Media News',
    'images': [
    {
      'title': 'Elshinta',
      'url': base_url_portofolio + 'Media/elshinta.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Jateng Pos',
      'url': base_url_portofolio + 'Media/jatengpos.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'RMOL',
      'url': base_url_portofolio + 'Media/rmol.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'RRI',
      'url': base_url_portofolio + 'Media/rri.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Sonora',
      'url': base_url_portofolio + 'Media/sonora.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Suara Merdeka',
      'url': base_url_portofolio + 'Media/suara_merdeka.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Trax',
      'url': base_url_portofolio + 'Media/trax.png',
      'style': {
        "height":"100%"
      }
    },
    {
      'title': 'Tribun Jateng',
      'url': base_url_portofolio + 'Media/tribun_jateng.png',
      'style': {
        "height":"100%"
      }
    }
    ]
  }
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
   
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 0, textAlign:"center", width:"30rem"}}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

let isCompatible = detectDevice();

export default function ScrollableTabsButtonAuto(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // $(document).on('click', 'li', function(){
  //   $('li').removeClass('active');
  //   $('ul').toggleClass('expanded');
  //   $(this).addClass('active');
  //   var tab_id = $(this).attr('data-tab');
  //   $('.tab-content').removeClass('current');
  //   $(this).addClass('current');
  //   $('#'+tab_id).addClass('current');
  // }); // Dipindah ke componen utama pada componentDidMount(){}
  

  return (
  <>
    <style jsx="true"> 
    {
      `
      .tabs-container nav {
        margin: 0 auto;
        background: #F37021;
      }

      .tabs-container nav ul {
        list-style: none;
        margin: 0;
        padding: 0;
        border: 1px solid #ccc;
        padding: 0 10px;
      }

      .tabs-container nav ul li {
        padding: 1rem;
        cursor: pointer;
        color: #A7A7A7;
        border-top: 1px solid #eee;
        margin-left: -10px; 
        margin-right: -10px;
        padding-left: 25px;
      }

      .tabs-container nav ul li:hover { color: #FFFFF;}

      .tabs-container nav ul li.active {
        background-color : #F37021;
        color : #FFFFFF;
        border-top: none;
      }


      /* the nitty gritty */

      .tabs-container {
        position: relative;
        height: 4em;  
      }

      .tabs-container nav {
        position: relative;
        width: 100%;
      }

      .tabs-container nav ul {
        display: flex;
        flex-direction: column;
      }

      .tabs-container nav ul li {
        order: 1;
        
      }

      .tabs-container nav ul li.active {
        order: 0;
      }

      .tabs-container nav ul li.active:after {
        
        width: 1px;
        height: 1px;
        border: 10px solid transparent;
        border-top: 5px solid #555;
        content: '';
        position: absolute;
        right: 1.5em;
        top: 1.4em;
        z-index: 9999;
        display: inline-block;
        width: .85em;
        height: .8em;
        margin: -.4em 0 0 .4em;
        vertical-align: middle;
        content: "";
        border: .3em solid;
        border-width: 0 .15em .15em 0;
        transform: rotateZ(45deg)
        
      }

      .tabs-container nav ul li:not(.active) {
        
        position: absolute;
        top: -999em;
      }


      .tabs-container nav ul.expanded li.active:after{
        border-top-color: transparent;
        border-bottom-color: #FFFFFF;
       
      }

      .tabs-container nav ul.expanded li:not(.active) {
        
        position: relative;
        top: auto;
      }

      @media all and (min-width: 60em) {

        nav {
          background: transparent;
        }

        .tabs-container nav ul li.active {
          
          box-shadow: inset 0 -3px 0 #5098B3;
        }

        .tabs-container nav ul {
          display: flex;
          flex-direction: row;
          justify-content: center;
          white-space: nowrap;
          overflow: hidden;
          border: none;
          padding: 0;
        }

        .tabs-container nav ul li {
          display: inline-block;
          margin: 0 1rem 0 1rem;
          padding: 0 0 .2rem 0;
          border: none;
        }

        .tabs-container nav ul li:not(.active) {
          position: relative;
          top: auto;
        }

        .tabs-container nav ul li.active {
          order: 1;
        }

        .tabs-container nav ul li.active:after {
          display: none;
        }
      }

      .tab-content{
          display: none;
          
      }
      .tab-content.current{
          display: flex;
      }

      .dropdown-menu {
        max-height: 20em;
        width: 18em;
        overflow-y: scroll;
        margin: auto;
        // background-color : #F37021;
        caret-color: #FFFFFF; 
        display: none;
      } 
      
      `
    } 
    </style>

    
    {/* <div className='nexatel-container19 m-auto' style={{paddingLeft:"2rem", paddingRight:"2rem"}}> */}
    <div className='nexatel-container19 m-auto p-2'>
      
      <img alt='Nexa Clients' loading='lazy' className='text-center' src={isCompatible ? "https://minio.nexa.net.id/nexa/portofolio_mobile.webp" : "https://minio.nexa.net.id/nexa/portofolio_mobile.png"} style={{width:"95%"}}></img>
    </div>
    
    </>
  );
} 
//Code Lama
/**
 * 
 * 
 */
 //<Box sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'none' }}>
//       <div className=''>

//       <div className="tabs-container justify-content-center align-items-center" style={{width:"75%", margin:"auto"}}>
//         <div>
       
        
//         <nav className="tabs">
//           <ul className='dropdown-menu' style={{"--bs-dropdown-border-radius": "1rem",}}>
            
//             {
//             res_data.map((row, idx) => {
//               // console.log(a11yProps(idx).id)
              
//               return (
//               <li className={`${(idx===0)? 'active': ''}`} data-tab={a11yProps(idx).id}>{row.title}</li>
              
              
//               )
//             })
//           }
          

//           </ul>
//         </nav>
//       </div>
//       </div>
//       </div>
      
      
//         {
//           res_data.map((row, idx) => {
//             return (
            
//             // <TabPanel key={idx} value={value} index={idx} >
//             // <div className="client-container-client-mobile">
//             //     <div className="client-container07 row">
//             //       <div className='row align-self-center'>
//             //     {
//             //       row.images.map((res, index) => {
//             //         return (
//             //               <div className='col-6 align-self-center' style={{margin:"auto"}}>
//             //               <div key={index} className="row" style={{margin:"auto"}}>
//             //                 <img alt="" src={res.url} className=" w-51" style={res.style} />
                              
//             //                 {/* <span className="client-image text-center" style={{fontSize: "1rem", margin:"1em"}}></span> */}
//             //               </div>
//             //               {/* <div className='text-center'>{res.title}</div> */}
//             //               </div>
//             //         )
//             //       })
//             //     }
//             //     </div>
//             //   </div>
//             // </div>
            
//             // </TabPanel>
//             <div className={`tab-content ${(idx === 0) ? 'current' : ''}`} id={`simple-tab-${idx}`}>
//               <div className="client-container-client-mobile">
//                  <div className="client-container07 row">
//                    <div className='row align-self-center' style={{padding:0}}>
//                  {
//                   row.images.map((res, index) => {
//                     return (
//                           <div className='col-6 align-self-center' style={{margin:"auto"}}>
//                           <div key={index} className="row" style={{margin:"auto"}}>
//                             <img alt="" src={res.url} className="" style={res.style} />
//                             {{/* <img alt="" src={res.url} className=" w-100" style={res.style} /> */}}
                              
//                             {{/* <span className="client-image text-center" style={{fontSize: "1rem", margin:"1em"}}></span> */}}
//                           </div>
//                           {{/* <div className='text-center'>{res.title}</div> */}}
//                           </div>
//                     )
//                   })
//                 }
//                 </div>
//               </div>
//             </div>

//             </div>

//             )
//           })
//         }
// //</Box>
      
