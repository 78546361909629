import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import { NavLink } from 'react-router-dom';

export default class FooterMobile extends Component {
    createElementCircle = () => {
      return (
        <>
        &nbsp;&nbsp;
            <svg height="10" width="10">
              <circle cx="2.5" cy="2.5" r="2" fill="black" />
              Sorry, your browser does not support inline SVG.  
            </svg>
        &nbsp;
        </>
      )
    }
    renderFooterKota = () => {
      return (
        <>
        JAKARTA {this.createElementCircle()}
        SEMARANG {this.createElementCircle()}
        YOGYAKARTA {this.createElementCircle()}
        SOLO {this.createElementCircle()}
        SALATIGA {this.createElementCircle()}
        MAGELANG {this.createElementCircle()}
        PURWOKERTO {this.createElementCircle()}
        SURABAYA {this.createElementCircle()}
        BALI {this.createElementCircle()}
        MATARAM
        </>
      )
    }
    render(){
        const getDate = new Date()
        return (
        <>
        <style jsx="true">
        {`
          .bg-none { background: none}
          .mobile-footer .row {
            padding-left: var(--dl-space-space-threeunits);
            padding-right: var(--dl-space-space-threeunits);
          }

          .mobile-footer .row {
            padding-left: 1rem;
            padding-right: 1rem;
          }
          .mobile-footer {
            background-color: #f5f5f5;
          }
        `}
        </style>
        <footer className="mobile-footer">
          <section>
            <div className="row">
              <div className="col-12 pt-4">
                  <NavLink to="/">
                  <img
                    alt="Nexa Logo Mobile"
                    loading='lazy'
                    src="https://minio.nexa.net.id/nexa/logo_nexa_500.png"
                    className="foothers-image"
                  />
                  </NavLink>
                  <span className="foothers-text">
                <span>Next Level Experience</span>
              </span>
              </div>
              
              <div className="col-4">
                <div className="foothers-companyfoother pt-3">
                  <NavLink className="foothers-text01" to="/company"><span className="foothers-text01"><span>Company</span></span></NavLink>
                  <span className="foothers-text02">
                    <NavLink to="/aboutus"><span>About Us</span></NavLink>
                    <br />
                    <span>​</span>
                    <NavLink to="/career"><span>Career</span></NavLink>
                    <br />
                    <NavLink to="/client"><span>Clients</span></NavLink>
                    <br />
                    <NavLink to="/contact"><span>Contact</span></NavLink>
                    <span></span>
                  </span>
                </div>
              </div>

              <div className="col-4">
                <div className="foothers-companyfoother pt-3">
                  <NavLink className="foothers-text01" to="/service"><span className="foothers-text01"><span>Service</span></span></NavLink>
                  <span className="foothers-text02">
                    <NavLink to="/nexatel"><span>Nexatel</span></NavLink>
                    <br />
                    <NavLink to="/nexaapps"><span>Nexa Apps</span></NavLink>
                    <br />
                    <NavLink to="/nexadata"><span>Nexa Data</span></NavLink>
                    <br />
                    <NavLink to="/nexadigital"><span>Nexa Digital</span></NavLink>
                    <br />
                    <NavLink to="/solution"><span>Nexa Solutions</span></NavLink>
                  </span>
                </div>
              </div>

              <div className="col-4">
                <div className="foothers-companyfoother pt-3">
                  <NavLink className="foothers-text01" to="/solution"><span className="foothers-text01"><span>Solutions</span></span></NavLink>
                  <span className="foothers-text02">
                    <NavLink to="/corporate"><span>Corporate</span></NavLink>
                    <br />
                    <NavLink to="/education"><span>Education</span></NavLink>
                    <br />
                    <NavLink to="/goverment"><span>Government</span></NavLink>
                    <br />
                    <NavLink to="/hospitality"><span>Hospitality</span></NavLink>
                    <br />
                    <NavLink to="/manufacture"><span>Manufacture</span></NavLink>
                  </span>
                </div>
              </div>

              <div className="col-4">
                <div className="foothers-companyfoother pt-3">
                  <NavLink className="foothers-text01" to="/support"><span className="foothers-text01"><span>Support</span></span></NavLink>
                  <span className="foothers-text02">
                    <NavLink to="/areas"><span>Areas</span></NavLink>
                    <br />
                    <NavLink to="/subscribe"><span>Subscription</span></NavLink>
                    <br />
                    <span className="d-none">Payment Method</span>
                  </span>
                </div>
              </div>

              <div className="col-4">
                <div className="foothers-companyfoother pt-3">
                  <NavLink className="foothers-text01" style={{marginBottom:"auto"}} to="/news"><span className="foothers-text01"><span>News & Article</span></span></NavLink>
                  <span className="foothers-text02">
                    <NavLink to="/news"><span>News &amp; Article</span></NavLink>
                    <br />
                    <span className="d-none">Event</span>
                  </span>
                </div>
              </div>

              <div className="col-4">
                <div className="foothers-companyfoother pt-3">
                  <div className="foothers-text01" to="/news"><span className="foothers-text01"><span>Find us at</span></span></div>
                  <span className="foothers-text02">
                    <div className="card-body ps-0 pe-0 text-center">
                        <svg onClick={()=> window.open("https://www.linkedin.com/company/78380888/", "_blank")} viewBox="0 0 877.7142857142857 1024" className="foothers-icon me-2">
                          <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z">
                          </path>
                        </svg>
                        <svg onClick={()=> window.open("https://www.instagram.com/nexanet.id/", "_blank")} viewBox="0 0 1024 1024" className="foothers-icon me-2">
                          <path d="M298.667 42.667c-70.699 0-134.741 28.715-181.035 74.965s-74.965 110.336-74.965 181.035v426.667c0 70.699 28.715 134.741 74.965 181.035s110.336 74.965 181.035 74.965h426.667c70.699 0 134.741-28.715 181.035-74.965s74.965-110.336 74.965-181.035v-426.667c0-70.699-28.715-134.741-74.965-181.035s-110.336-74.965-181.035-74.965zM298.667 128h426.667c47.147 0 89.728 19.072 120.661 50.005s50.005 73.515 50.005 120.661v426.667c0 47.147-19.072 89.728-50.005 120.661s-73.515 50.005-120.661 50.005h-426.667c-47.147 0-89.728-19.072-120.661-50.005s-50.005-73.515-50.005-120.661v-426.667c0-47.147 19.072-89.728 50.005-120.661s73.515-50.005 120.661-50.005zM724.864 478.848c-6.4-41.472-24.363-79.232-50.944-109.525-32.171-36.736-76.971-62.507-128.384-70.144-19.797-3.157-41.387-3.285-63.019-0.085-58.283 8.619-107.563 40.149-140.032 83.925s-48.341 100.139-39.68 158.379 40.149 107.563 83.925 140.032 100.139 48.341 158.379 39.68 107.563-40.149 140.032-83.925 48.341-100.139 39.68-158.379zM640.469 491.392c5.205 34.987-4.267 68.651-23.808 95.019s-49.067 45.184-84.011 50.347-68.651-4.267-95.019-23.808-45.184-49.067-50.347-84.011 4.267-68.651 23.808-95.019 49.067-45.184 84.011-50.347c13.355-1.963 26.24-1.792 37.12-0.085 31.573 4.693 58.283 20.053 77.568 42.069 16 18.261 26.88 41.088 30.72 65.835zM789.333 277.333c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667 19.115 42.667 42.667 42.667 42.667-19.115 42.667-42.667z">
                          </path>
                        </svg>
                        <svg onClick={()=> window.open("https://www.facebook.com/Nexanetid-109427088241490/", "_blank")} viewBox="0 0 877.7142857142857 1024" className="foothers-icon me-2">
                          <path
                            d="M713.143 73.143c90.857 0 164.571 73.714 164.571 164.571v548.571c0 90.857-73.714 164.571-164.571 164.571h-107.429v-340h113.714l17.143-132.571h-130.857v-84.571c0-38.286 10.286-64 65.714-64l69.714-0.571v-118.286c-12-1.714-53.714-5.143-101.714-5.143-101.143 0-170.857 61.714-170.857 174.857v97.714h-114.286v132.571h114.286v340h-304c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571z">
                            </path>
                        </svg>
                    </div>
                  </span>
                </div>
              </div>

            </div>
          </section>

          <section className="foothers-text75">
            <div className="row p-4">
              <div className="col text-center">
                @ Copyright {getDate.getFullYear()} <br/>PT Internet Mulia Untuk Negeri. All Rights Reserved.
              </div>
            </div>
          </section>
        </footer>
        </>
        )
    }
}