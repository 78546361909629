import Header 		from '../../../components/Header';
import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import MainSolutions 	from '../../../components/MainSolutions';
import OtherSolutions 	from '../../../components/OtherSolutions';
import Interested 	from '../../../components/Interested';
import Content 		from './Content';
import Footer 		from '../../../components/Footer';
import { bigTv as mediaQuery, bigScreen, normalScreen } from '../../../components/MediaQueries'
import '../../../assets/solutioneducation.css';

import React from 'react'
import { EducationPage, LineBreak, AboutUs, CreateToken, detectDevice } from '../../../controller/index'
import {useSelector} from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

// let isCompatible = detectDevice()

function Education({ isCompatible }) {
  //Google Analitik
  ReactGA.event({ category: "user", action: "Solution Education" });

  const [result, setResult] = React.useState(null);
  // const [isCompatible, setIsCompatible] = React.useState(1);
  const langs = useSelector((state) => state.langs);
  const [statusLang] = React.useState(langs[0].idLang);
  const [tertarik, setTertarik] = React.useState(null);

  let langCondition = statusLang !== langs[0].idLang;

  React.useEffect(() => {
    // let valueCompatible = detectDevice();
    // setIsCompatible(valueCompatible);
    CreateToken().then((data) => {
      EducationPage()
        .then((data) => {
          setResult(data.response[0]);
        })
        .catch((err) => {
          setResult(err);
        });

      AboutUs()
        .then((data) => {
          setTertarik(data.response[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [langCondition]);

  let eBanner = (
    <h1
      className={
        mediaQuery
          ? "solutioncorporate-text container max-width-1100px"
          : "solutioncorporate-text"
      }
    >
      <span>
        {result !== null ? (
          result.tentang_1.split("|")[0]
        ) : (
          <Shimmer width={"20rem"} height={"4rem"} />
        )}
        :
      </span>
      <br />
      <span className="solutioncorporate-text002">
        {result !== null ? (
          result.tentang_1.split("|")[1]
        ) : (
          <Shimmer width={"20rem"} height={"4rem"} />
        )}
      </span>
    </h1>
  );
  let eTitle =
    result !== null
      ? `${result.tentang_1.split("|")[0]} ${result.tentang_1.split("|")[1]}`
      : "";
  let sBanner_desc = (
    <div className={mediaQuery ? "container max-width-1100px" : ""}>
      {result !== null ? (
        LineBreak(result.tentang_1.split("|")[2])
      ) : (
        <Shimmer width={"20rem"} height={"4rem"} />
      )}
    </div>
  );
  let sAfterBanner_title =
    result !== null ? (
      LineBreak(result.tentang_2)
    ) : (
      <Shimmer width={"20rem"} height={"4rem"} />
    );
  let sAfterBanner_desc = (
    <span className="fs-18">
      {result !== null ? (
        LineBreak(result.tentang_3)
      ) : (
        <Shimmer width={"20rem"} height={"4rem"} />
      )}
    </span>
  );
  let mainBannerPage = isCompatible
    ? "https://minio.nexa.net.id/nexa/banner_education.webp"
    : "https://minio.nexa.net.id/nexa/banner_education.jpeg";

  let desc = result !== null ? result.tentang_3 : "";

  let mediaCondition;
  if (normalScreen) {
    mediaCondition = { top: "-10px", height: "200px" };
  } else if (bigScreen) {
    mediaCondition = { top: "190px", height: "400px" };
  } else {
    mediaCondition = { top: "-80px", height: "200px" };
  }

  return (
    <div>
      <Helmet>
        <title>{`Nexa - ${eTitle}`}</title>
        <meta property="og:title" content={eTitle}></meta>
        <meta property="og:site_name" content="Nexa"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:description" content={desc}></meta>
        <meta name="description" content={desc}></meta>
        <meta property="og:type" content="product"></meta>
        <meta property="og:image" content={mainBannerPage}></meta>
      </Helmet>

      <Banner
        title={eBanner}
        desc={sBanner_desc}
        url_image={mainBannerPage}
        style={mediaQuery ? { maxWidth: "unset" } : {}}
      />
      <AfterBanner
        title={sAfterBanner_title}
        desc={sAfterBanner_desc}
        styleSection={mediaCondition}
      />
      <MainSolutions
        title="Education"
        sub_title={result !== null && result.product_services.split("|")[0]}
        storeData={result !== null && result.product_services.split("|")}
      />
      {result == null && <OtherSolutions storeData={null} />}
      {result !== null && <OtherSolutions storeData={result.another} />}
      <Content />
      <Interested storeData={tertarik} />
    </div>
  );
}

export default Education;