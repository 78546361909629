import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Shimmer } from 'react-shimmer';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ScrollableTabsButtonAuto({storeData}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <div className="row nexadata-container05">
    <Box className="nexadata-container05">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className=" m-0 textCapitalize d-flex justify-content-center"
        aria-label="scrollable auto tabs example" id="__nexaDataTabs" style={{ "minHeight": "auto"}}>
          <Tab label="B2B" {...a11yProps(0)} className="nexadata-button1 button" />
          <Tab label="Consumer" {...a11yProps(1)} className="nexadata-button1 button" />
      </Tabs>
      
    </Box>
    	<TabPanel value={value} index={0}>
        { 
          	<div className="internetservice-container07" style={{"alignItems":"start"}}>
	            
	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
	            	<div className="card-title internetservice-text020">Metro Ethernet (Metro-E)</div>
	            	<div className="card-body internetservice-text023 pe-0 ps-0">
	                  { storeData !== null ? storeData.b2.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} /> }
	            	</div>
	            </div>
	            {/* <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
	            	<div className="card-title internetservice-text020">Metro Wireless (Metro-W)</div>
	            	<div className="card-body internetservice-text023 pe-0 ps-0">
	            	{ storeData !== null ? storeData.b2.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} /> }
	            	</div>
	            </div> */}
	          </div>
	          
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
      	{ 
        	<div className="internetservice-container07" style={{"alignItems":"start", "height":"auto"}}>
	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
	            	<div className="card-title internetservice-text020">Business</div>
	            	<div className="card-body internetservice-text023">
		            <ol type="a" className="ps-3 business">
		              	<li>
		              		<div className="row">
			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Prime</div>
			              		<div>
									
			              		{ storeData !== null ? <div dangerouslySetInnerHTML={{ __html: storeData.consumer.split('|')[0]}} />  : <Shimmer width={"20rem"} height={"4rem"} /> }
			              		</div>
		              		</div>
		              	</li>
		              	<li>
		              		<div className="row">
			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Impressive</div>
			              		<div>
			              		{ storeData !== null ? <div dangerouslySetInnerHTML={{ __html: storeData.consumer.split('|')[1]}} /> : <Shimmer width={"20rem"} height={"4rem"} /> }
			              		</div>
		              		</div>
		              	</li>
		            </ol>
	            	</div>
	            </div>

	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
	            	<div className="card-title internetservice-text020">Home</div>
	            	<div className="card-body internetservice-text023">
		            <ol type="a" className="ps-3 business">
		              	<li>
		              		<div className="row">
			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Nexa Home</div>
			              		<div>
			              		{ storeData !== null ? storeData.consumer.split('|')[2] : <Shimmer width={"20rem"} height={"4rem"} /> }
			              		</div>
		              		</div>
		              	</li>
		              	{/* <li>
		              		<div className="row">
			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Zigy</div>
			              		<div>
			              		{ storeData !== null ? storeData.consumer.split('|')[4] : <Shimmer width={"20rem"} height={"4rem"} /> }
			              		</div>
		              		</div>
		              	</li> */}
	              	</ol>
	            	</div>
	            </div>

	        </div>
        }
      </TabPanel>
    </div>
  );
}

export default class Content extends Component {
    render(){
        return (
        <section>

			<div className='button-wa-fixed'>
				<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
					<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed">
	                  <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
	                </svg>
				</a>
			</div>

        	<section className="internetservice-container03">
	          <div className="internetservice-container04">
	            <span className="internetservice-text018">Nexatel</span>
	            <h2 className="internetservice-text019 fw-bold">Business Category</h2>
	          </div>
	          <ScrollableTabsButtonAuto storeData={this.props.storeData} />
	        </section>

		    <section className="internetservice-section6 mt-0" style={{marginBottom: "2rem"}}>
	          <div className="internetservice-container14">
	            <span className="internetservice-text033">Nexatel</span>
	            <h2 className="internetservice-text034 fw-bold">Service Features</h2>
	          </div>
	          <div className="internetservice-container15">
	            <div className="internetservice-container16">
	              <div className="internetservice-container17">
	                <img loading="lazy" alt="Icon SLA" src="https://minio.nexa.net.id/nexa/icon_sla.png" className="internetservice-image08"/>
	                <span className="internetservice-text035">SLA 99,8%</span>
	              </div>
	              <div className="internetservice-container18">
	                <img loading="lazy"  alt="Icon Redundancy Network" src="https://minio.nexa.net.id/nexa/icon_redundancy_network.png" className="internetservice-image09"/>
	                <span className="internetservice-text036">
	                  <span>Redundancy</span>
	                  <br/>
	                  <span></span>
	                  <span>Network</span>
	                </span>
	              </div>
	              <div className="internetservice-container19">
	                <img loading="lazy"  alt="Icon Lastmile Backup" src="https://minio.nexa.net.id/nexa/icon_lastmile.png" className="internetservice-image10"/>
	                <span className="internetservice-text041">
	                  <span>Lastmile Backup</span>
	                </span>
	              </div>
	              <div className="internetservice-container20">
	                <img loading="lazy"  alt="Icon 24/7" src="https://minio.nexa.net.id/nexa/icon_on_call_support.png" className="internetservice-image11"/>
	                <span className="internetservice-text044">
	                  <span>24/7 Out-of-hours</span>
	                  <br/>
	                  <span>Support</span>
	                </span>
	              </div>
	            </div>
	            <div className="internetservice-container21">
	              <div className="internetservice-container22">
	                <img loading="lazy"  alt="Icon Reliable Service" src="https://minio.nexa.net.id/nexa/icon_reliable_service.png" className="internetservice-image12"/>
	                <span className="internetservice-text047">Reliable Service</span>
	              </div>
	              <div className="internetservice-container23">
	                <img loading="lazy"  alt="Icon CDN" src="https://minio.nexa.net.id/nexa/icon_delivery_network.png" className="internetservice-image13"/>
	                <span className="internetservice-text048">
	                  <span>Content Delivery</span>
	                  <br/>
	                  <span>Network</span>
	                </span>
	              </div>
	              <div className="internetservice-container24">
	                <img loading="lazy"  alt="Icon Symetric" src="https://minio.nexa.net.id/nexa/icon_symetric_bandwidth.png" className="internetservice-image14"/>
	                <span className="internetservice-text051">
	                  <span>Symetric</span>
	                  <br/>
	                  <span></span>
	                  <span>Bandwidth</span>
	                </span>
	              </div>
	              <div className="internetservice-container25">
	                <img loading="lazy"  alt="Icon Own Infrastructure" src="https://minio.nexa.net.id/nexa/icon_own_infrastructure.png" className="internetservice-image15"/>
	                <span className="internetservice-text056">
	                  <span>Own Infrastructure</span>
	                </span>
	              </div>
	            </div>
	            <div className="internetservice-container26">
	              <div className="internetservice-container27">
	                <img loading="lazy"  alt="Icon Early Warning" src="https://minio.nexa.net.id/nexa/icon_early_warning.png" className="internetservice-image16"/>
	                <span className="internetservice-text058">
	                  <span>Early Warning</span>
	                  <br/>
	                  <span>System</span>
	                </span>
	              </div>
	              <div className="internetservice-container28">
	                <img loading="lazy"  alt="Icon Bandwidth" src="https://minio.nexa.net.id/nexa/icon_bandwidth_management.png" className="internetservice-image17"/>
	                <span className="internetservice-text061">
	                  <span>Bandwidth</span>
	                  <br/>
	                  <span>Management</span>
	                </span>
	              </div>
	              <div className="internetservice-container29">
	                <img loading="lazy"  alt="Icon LAN Troubleshooting" src="https://minio.nexa.net.id/nexa/icon_lan_troubleshooting.png" className="internetservice-image18"/>
	                <span className="internetservice-text064">
	                  <span>LAN Troubleshooting</span>
	                </span>
	              </div>
	              <div className="internetservice-container30">
	                <img loading="lazy"  alt="Icon Regular Visit" src="https://minio.nexa.net.id/nexa/icon_regular_visit.png" className="internetservice-image19"/>
	                <span className="internetservice-text066">
	                  <span>Regular Visit</span>
	                </span>
	              </div>
	            </div>
	            <div className="internetservice-container31">
	              <div className="internetservice-container32">
	                <img loading="lazy"  alt="Icon BOD" src="https://minio.nexa.net.id/nexa/icon_bandwith_on_demand.png" className="internetservice-image20"/>
	                <span className="internetservice-text068">
	                  <span>Bandwith on </span>
	                  <span>Demand</span>
	                </span>
	              </div>
	              <div className="internetservice-container33">
	                <img loading="lazy"  alt="Icon Static IP Public" src="https://minio.nexa.net.id/nexa/icon_static_ip_public.png" className="internetservice-image21"/>
	                <span className="internetservice-text071">
	                  <span>Static IP Public</span>
	                </span>
	              </div>
	              <div className="internetservice-container34">
	                <img loading="lazy"  alt="Icon Deep Analytic" src="https://minio.nexa.net.id/nexa/icon_deep_analytic.png" className="internetservice-image22"/>
	                <span className="internetservice-text073">
	                  <span>Deep Analytic</span>
	                </span>
	              </div>
	            </div>
	          </div>
	        </section>
        </section>
        )
    }
}