import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Shimmer } from "react-shimmer";
import "../../../assets/detailnews.css";
import { stripHtml } from "../../../controller/index";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { bigTv as mediaQuery } from "../../../components/MediaQueries";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// import Card from 'react-bootstrap/Card';

let iNumber = 1;
export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_data:
        document.querySelectorAll("#__newsSlider .splide__slide").length - 2,
    };
  }

  componentDidMount() {
    this.totalDataArticle = setInterval(() => this.setTotalArticle(), 1000);
    // console.log(sessionStorage.getItem('lang'))
  }

  componentWillUnmount() {
    clearInterval(this.totalDataArticle);
  }

  setTotalArticle() {
    this.setState({
      total_data:
        document.querySelectorAll("#__newsSlider .splide__slide").length - 2,
    });
  }

  handleSlidePrev = () => {
    let totalData =
      document.querySelectorAll("#__newsSlider .splide__slide").length - 2;
    document.querySelector(".numberSlide").innerHTML =
      (iNumber <= 1 ? 1 : (iNumber -= 1)) + " / " + totalData;
    document.querySelector(".news-prev").click();
  };

  handleSlideNext = async () => {
    let totalData =
      document.querySelectorAll("#__newsSlider .splide__slide").length - 2;
    document.querySelector(".numberSlide").innerHTML =
      (iNumber >= totalData ? totalData : (iNumber += 1)) + " / " + totalData;
    document.querySelector(".news-next").click();
  };

  formatTanggal = (paramDate, id) => {
    let date = new Date(paramDate);
    let tahun = date.getFullYear();
    let bulan = date.getMonth();
    let tanggal = date.getDate();
    // let hari = date.getDay();

    let bulanIndonesia = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    let bulanEnglish = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (parseInt(id) === 2) {
      bulan = bulanIndonesia[bulan];
    } else {
      bulan = bulanEnglish[bulan];
    }
    return `${tanggal} ${bulan} ${tahun}`;
  };

  formatTitle = (getData) => {
    if (typeof getData !== "undefined") {
      let makeArr = getData.split(" ");
      let setLength = Math.floor(makeArr.length / 2);

      let newText = makeArr.map((row, idx) =>
        idx === setLength ? (
          <React.Fragment key={row}>
            {row}
            <br />
          </React.Fragment>
        ) : (
          <React.Fragment key={row}>{row}&nbsp;</React.Fragment>
        )
      );
      return newText;
    } else {
      return <Shimmer width={"20rem"} height={"4rem"} />;
    }
  };

  createSlider = () => {
    let aCards = [];
    let res_data = [
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_2.png",
      },
    ];

    if (this.props.news !== null) {
      res_data = this.props.news;
    } else {
      res_data.forEach((row) => {
        row.image = <Shimmer width={"20rem"} height={"4rem"} />;
        row.title = <Shimmer width={"20rem"} height={"4rem"} />;
        row.deskripsi = "";
        row.label = <Shimmer width={"20rem"} height={"4rem"} />;
      });
    }

    // console.log(this.props.news, 'content')

    res_data.forEach((row, index) => {
      aCards.push(
        <SplideSlide
          key={index}
          className="p-2"
          count={res_data.length}
          page={index + 1}
        >
          <div className="newscard-container">
            <NavLink to={"/news_detail?id=" + row.kode}>
              <article>
                <div className="newscard-image" style={{ height: "235px" }}>
                  <img
                    loading="lazy"
                    alt={`Nexa News - ${row.title}`}
                    src={row.image}
                    className="h-100"
                    style={{ objectFit: "cover" }}
                  />
                  <div className="newscard-container1">
                    <span className="newscard-text">{row.label}</span>
                  </div>
                </div>
                <div className="newscard-content">
                  <h2 className="newscard-text1">
                    <span>{row.title}</span>
                  </h2>
                  <span className="newscard-conten">
                    <span>{stripHtml(row.deskripsi).substr(0, 100)}...</span>
                  </span>
                </div>
                <div style={{ "margin-bottom": "10px", "margin-left": "2rem" }}>
                  <span className="newscard-text2">
                    <span>
                      {this.formatTanggal(row.tanggal, row.id_bahasa)}
                    </span>
                  </span>
                </div>
              </article>
            </NavLink>
          </div>
        </SplideSlide>
      );
    });

    // aCards.push(<SplideSlide key="empty" page={res_data.length}></SplideSlide>)

    return (
      <Splide
        className="p-0"
        id="__newsSlider"
        style={mediaQuery ? { width: "unset" } : {}}
        options={{
          perPage: 3,
          perMove: 1,
          gap: mediaQuery ? "9vw" : "9vw",
        }}
        aria-label="My Favorite Images"
      >
        {aCards}

        <div className="splide__arrows splide__arrows--ltr d-none">
          <button
            className="splide__arrow splide__arrow--prev news-prev"
            type="button"
            aria-label="Previous slide"
            aria-controls="splide02-track"
          ></button>

          <button
            className="splide__arrow splide__arrow--next news-next"
            type="button"
            aria-label="Go to first slide"
            aria-controls="splide02-track"
          ></button>
        </div>
      </Splide>
    );
  };

  render() {
    return (
      <React.Fragment>
        <article>
          <div className="detailnews-section2">
            <div className="detailnews-container01">
              <span className="detailnews-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.text.titleNews,
                  }}
                />
              </span>
              <h1 className="detailnews-text001 d-flex justify-content-center">
                <span>
                  {this.props.storeData !== null ? (
                    this.formatTitle(this.props.storeData.title)
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </span>
              </h1>
              <span className="detailnews-text004">
                <span>
                  {this.props.text.posted}{" "}
                  {this.props.storeData !== null ? (
                    this.formatTanggal(
                      this.props.storeData.tanggal,
                      this.props.storeData.id_bahasa
                    )
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </span>
              </span>
              <span className="detailnews-text005">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ marginLeft: "10px" }}
                  href={`https://www.facebook.com/sharer.php?u=${encodeURI(
                    window.location.href
                  )}`}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      className="bi bi-facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </span>
                </a>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{ marginLeft: "10px" }}
                  href={`https://www.instagram.com/?url=${encodeURI(
                    window.location.href
                  )}`}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      fill="currentColor"
                      className="bi bi-instagram"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                    </svg>
                  </span>
                </a>
              </span>
            </div>
            <div className="detailnews-container02">
              <img
                loading="lazy"
                alt={`Nexa News - ${
                  this.props.storeData !== null
                    ? this.props.storeData.title
                    : ""
                }`}
                src={
                  this.props.storeData !== null
                    ? this.props.storeData.image
                    : "https://via.placeholder.com/480x480"
                }
                className="detailnews-image"
              />
            </div>
          </div>

          <div className="detailnews-container03">
            <div className="detailnews-section3">
              <Row>
                <Col md={8}>
                  <span className="detailnews-text006 container">
                    {this.props.storeData !== null ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: this.props.storeData.deskripsi,
                        }}
                      />
                    ) : (
                      <Shimmer width={"20rem"} height={"4rem"} />
                    )}
                  </span>
                </Col>

                <Col md={4} className="detailnews-side-container">
                  <Row className="detailnews-row-side-container">
                    <span className="detailnews-row-side-container-margin1">
                      <strong style={{ fontSize: "1.3rem" }}>Author</strong>
                    </span>

                    <Row>
                      <Col
                        md={3}
                        className="detailnews-row-side-container-margin2"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="50"
                          height="50"
                          fill="currentColor"
                          className="bi bi-person-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                          <path
                            fill-rule="evenodd"
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                          />
                        </svg>
                      </Col>
                      <Col
                        md={5}
                        className="detailnews-row-side-container-text-center"
                      >
                        <span>Nexa Marketing Communication</span>
                      </Col>
                      <Col md={4}></Col>
                    </Row>
                  </Row>

                  <Row className="detailnews-row-side-container">
                    <Row>
                      <span className="detailnews-row-side-container-margin1">
                        <strong style={{ fontSize: "1.3rem" }}>Category</strong>
                      </span>
                    </Row>
                    <Row>
                      <span className="detailnews-row-side-container-margin3">
                        {this.props.storeData !== null
                          ? this.props.storeData.label
                          : ""}
                      </span>
                    </Row>
                  </Row>

                  <Row style={{ margin: "1rem" }}>
                    <div className="container-download-img">
                      <img
                        className="detailnews-row-side-container-img "
                        loading="lazy"
                        alt="Download Banner"
                        src="https://minio.nexa.net.id/nexa/iklan_ingris_long_high.png"
                      ></img>
                      {/* <div className='container-download-btn'>
											<button onClick={()=> window.open(this.props.link_android, "_blank")} style={{backgroundColor:"transparent"}}><img alt='Download to Google Play' className='img-responsive' style={{width:"5rem"}} src="https://minio.nexa.net.id/nexa/android.png"></img></button> 
											<button onClick={()=> window.open(this.props.link_ios, "_blank")} style={{backgroundColor:"transparent"}}><img alt="Download to App Store" className='img-responsive' style={{width:"5rem"}} src="https://minio.nexa.net.id/nexa/aiso.png"></img></button>
										</div> */}
                    </div>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </article>

        <div className="detailnews-section4">
          <div className="detailnews-container04">
            <span className="detailnews-text040">
              <div
                dangerouslySetInnerHTML={{ __html: this.props.text.titleNews }}
              />
            </span>
            <h2 className="detailnews-text041">
              <div
                dangerouslySetInnerHTML={{ __html: this.props.text.otherNews }}
              />
            </h2>
          </div>
          <div className="detailnews-container05">
            <div className="detailnews-container06 row container mx-auto">
              {this.createSlider()}

              <div className="home-container12">
                <div className="home-container13">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="home-icon20"
                    onClick={this.handleSlidePrev}
                  >
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
                  </svg>
                  <svg
                    viewBox="0 0 1024 1024"
                    className="home-icon22"
                    onClick={this.handleSlideNext}
                  >
                    <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                  </svg>
                </div>
                <div className="d-flex flex-wrap align-self-center w-100 mainNumber">
                  <span
                    className="col-1 numberSlide"
                    style={{ width: "100px" }}
                  >
                    1 / {this.state.total_data}
                  </span>
                  <div
                    className="home-container15 col"
                    style={{ width: "unset", alignSelf: "center" }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
