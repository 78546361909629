import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';


export default class MainSolutions extends Component {
	dataCorporate = () => {
		return [
			{
				'title': 'nexatel',
				'url':'/nexatel',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_inet.png',
						'title': 'Internet Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_support.png',
						'title': 'IT Support',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_infra.png',
						'title': 'Network Infrastructure',
					}
				]
			},
			{
				'title': 'nexaaps',
				'url':'/nexaapps',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_web.png',
						'title': 'Custom Software Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_web.png',
						'title': 'Web Design And Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_mobile.png',
						'title': 'Mobile Application Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'API Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_pay.png',
						'title': 'Payment Integration',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_staff.png',
						'title': 'IT Staff',
					},
				]
			},
			{
				'title': 'nexadata',
				'url':'/nexadata',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_colocation.png',
						'title': 'Colocation',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_hosting.png',
						'title': 'Hosting Solutions',
					},
				]
			},
			{
				'title': 'nexadigital',
				'url':'/nexadigital',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Media Planning',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Multimedia Project',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_email.png',
						'title': 'Whatsapp, Email, & SMS Broadcast Engine',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Graphic Design as a Service',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'OOH Advertising',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Social Media Management',
					},
				]
			},
			{
				'title': 'solution',
				'url':'/solution',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Data Center',
					}
				]
			}
			
		]
	}

	dataEducation = () => {
		return [
			{
				'title': 'nexatel',
				'url':'/nexatel',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_inet.png',
						'title': 'Internet Services',
					}
				]
			},
			{
				'title': 'nexaapps',
				'url':'/nexaapps',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Custom Software Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_web.png',
						'title': 'Web Design And Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_pay.png',
						'title': 'Payment Integration',
					}
				]
			},
			{
				'title': 'nexadata',
				'url':'/nexadata',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_colocation.png',
						'title': 'Colocation',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_hosting.png',
						'title': 'Hosting Solutions',
					}
				]
			},
			{
				'title': 'nexadigital',
				'url':'/nexadigital',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_email.png',
						'title': 'Whatsapp, Email, & SMS Broadcast Engine',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Data Center Infrastructure Solution',
					}
				]
			},
			{
				'title': 'solution',
				'url':'/solution',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Hardware and Supporting Instruments as a Service',
					}
				]
			}
		]
	}

	dataGoverment = () => {
		return [
			{
				'title': 'nexatel',
				'url':'/nexatel',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_inet.png',
						'title': 'Internet Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_support.png',
						'title': 'IT Support',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_infra.png',
						'title': 'Network Infrastructure',
					}
				]
			},
			{
				'title': 'nexaapps',
				'url':'/nexaapps',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Custom Software Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_web.png',
						'title': 'Web Design And Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Mobile Application Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'API Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_pay.png',
						'title': 'Payment Integration',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_staff.png',
						'title': 'IT Staff',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_colocation.png',
						'title': 'Colocation',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_hosting.png',
						'title': 'Hosting Solutions',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_email.png',
						'title': 'Whatsapp, Email, & SMS Broadcast Engine',
					}
				]
			},
			{
				'title': 'solution',
				'url':'/solution',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Design & Planning',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Data Center Infrastructure Solution',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Monitoring / Situation Room',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Hardware and Supporting Instruments as a Service',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Infrastructure as a Service',
					}
				]
			}
		]
	}

	dataHospitally = () => {
		return [
			{
				'title': 'nexatel',
				'url':'/nexatel',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_inet.png',
						'title': 'Internet Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_support.png',
						'title': 'IT Support',
					}
				]
			},
			{
				'title': 'nexaapps',
				'url':'/nexaapps',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Custom Software Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_web.png',
						'title': 'Web Design And Development',
					}
				]
			},
			{
				'title': 'nexadata',
				'url':'/nexadata',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_colocation.png',
						'title': 'Colocation',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_hosting.png',
						'title': 'Hosting Solutions',
					}
				]
			},
			{
				'title': 'nexadigital',
				'url':'/nexadigital',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_email.png',
						'title': 'Whatsapp, Email, & SMS Broadcast Engine',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Social Media Management',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Graphic Design as a Service',
					}
				]
			}
		]
	}

	dataManufacture = () => {
		return [
			{
				'title': 'nexatel',
				'url':'/nexatel',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_inet.png',
						'title': 'Internet Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_support.png',
						'title': 'IT Support',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_infra.png',
						'title': 'Network Infrastructure',
					}
				]
			},
			{
				'title': 'nexaapps',
				'url':'/nexaapps',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Custom Software Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_web.png',
						'title': 'Web Design And Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Mobile Application Development',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'API Development Services',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_pay.png',
						'title': 'Payment Integration',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_it_staff.png',
						'title': 'IT Staff',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_colocation.png',
						'title': 'Colocation',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_hosting.png',
						'title': 'Hosting Solutions',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_email.png',
						'title': 'Whatsapp, Email, & SMS Broadcast Engine',
					}
				]
			},
			{
				'title': 'solution',
				'url':'/solution',
				'arr_data':[
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Data Center Infrastructure Solution',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Hardware and Supporting Instruments as a Service',
					},
					{
						'url_image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
						'title': 'Infrastructure as a Service',
					}
				]
			}
		]
	}

	checkData = () => {
		let data = [] 
		const cond = (this.props.storeData === false || typeof this.props.storeData === 'undefined')
		switch (this.props.title){
			case 'Corporate':
				data = this.dataCorporate(cond);
			break;
			case 'Education':
				data = this.dataEducation(cond);
			break;
			case 'Government':
				data = this.dataGoverment(cond)
			break;
			case 'Hospitally':
				data = this.dataHospitally(cond)
			break;
			case 'Manufacture':
				data = this.dataManufacture(cond)
			break;
			default:
			console.log('No case: L30 func checkData MainSolutions')
			break;
		}
		return data;
	}
    render(){
        return (
        <section>
        <style>
        {`
        	.card-img-top {
        		width: auto !important;
        		height: 80px;
        	}
        	.card.support:hover {
        		transition-timing-function: ease-out;
        		transition: 0.2s;
        		transform: translateY(-20px);
        	}
        	.box-shadow {
        		box-shadow: 5px 5px 10px 0px #d4d4d4;
        	}
        `}
        </style>
	    <section className="solutioneducation-container03">
          <div className="solutioneducation-container04">
            <span className="solutioneducation-text17">{sessionStorage.getItem('lang') === "1" ? "Solution" : "Solusi"}</span>
            <h2 className="solutioneducation-text18">{sessionStorage.getItem('lang') === "1" ? "Our Product" : "Produk Kami"}</h2>
            <span className="solutioneducation-text19">
              <span></span>
              <span>{(typeof this.props.sub_title !== 'undefined') ? this.props.sub_title : `Other Solutions for ${this.props.title}`}</span>
            </span>
          </div>
          <div className="solutioneducation-container11 pt-5 mx-auto" style={{"backgroundColor": "#f5f5f5", "maxWidth":"1100px"}}>
	          <div className="row p-4" style={{"height":"auto"}}>
	          {
	          	this.checkData().map((row, index) => {
	          		return(
	          		<React.Fragment key={index}>
	          			{
	          				row.arr_data.map((item, idx) => {
	          					return(
	          						<div key={idx} className="col-3 col-md-3 col-lg-3 mb-3">
			          					<NavLink to={row.url} className="card support box-shadow" style={{"minHeight": "200px", "borderRadius":"10%"}}>
			          						<img loading='lazy' alt={`Nexa - ${item.title}`} src={item.url_image} className="solutioneducation-image align-self-center w-auto card-img-top mt-3"/>
			          						<span className="solutioneducation-text22 card-body">
			          						<div className="card-text">{item.title}</div>
			          						</span>
			          					</NavLink>
	          						</div>
	          					)
	          				})
	          			}
	          		</React.Fragment>
	          		)

	          	})
	          }
	          </div>
          </div>
        </section>
        </section>
        )
    }
}