import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import AccordingCollapse from '../../components/AccordingCollapse'
import { LineBreak, DetailCareer } from '../../../controller/index'
import { Shimmer } from 'react-shimmer';

export default class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {data: '', id: 0, obj:[]};
	}

	findRoute = () => {
		let content = document.querySelector('.nexasolution-section3')
			content.scrollIntoView()
	}

	getData = (id) => {
			let hasil = []
	
			DetailCareer(id).then((data) => {
				data = data.response
				// console.log(data)
				//remove OBJ yang tidak penting
	
				delete data[0]['id']
				delete data[0]['insert_at']
				delete data[0]['insert_by']
				delete data[0]['update_at']
				delete data[0]['update_by']
				delete data[0]['flag']
				delete data[0]['kode']
				delete data[0]['id_bahasa']
				delete data[0]['kategori']
	
				let bahasa = sessionStorage.getItem('lang')
				//Assign New Obj Key Name
				
				let arr_key = ["Position", "Job Description", "Experience", "Skill", "Qualification", 
					"Allowance", "Incentive", "Working Hours", "Location"]
	
				if(bahasa === "2"){
					arr_key = ["Posisi", "Deskripsi Pekerjaan", "Pengalaman", "Keterampilan", "Kualifikasi",
					"Tunjangan", "Insentif", "Jam Kerja", "Lokasi"]
				}
	
				delete Object.assign(data[0], {[arr_key[0]]: data[0]["position"] })["position"]
				delete Object.assign(data[0], {[arr_key[1]]: data[0]["job_desc"] })["job_desc"]
				delete Object.assign(data[0], {[arr_key[2]]: data[0]["experience"] })["experience"]
				delete Object.assign(data[0], {[arr_key[3]]: data[0]["skill"] })["skill"]
				delete Object.assign(data[0], {[arr_key[4]]: data[0]["qualification"] })["qualification"]
				delete Object.assign(data[0], {[arr_key[5]]: data[0]["allowance"] })["allowance"]
				delete Object.assign(data[0], {[arr_key[6]]: data[0]["insentif"] })["insentif"]
				delete Object.assign(data[0], {[arr_key[7]]: data[0]["work_time"] })["work_time"]
				delete Object.assign(data[0], {[arr_key[8]]: data[0]["location"] })["location"]

				
	
				hasil = data.map((data) => {
					let arr = []
					let keys = Object.keys(data)
					keys.forEach(element => {
						arr.push({"title": element, "desc": data[element]})
					});
					return arr
				})
				// console.log(hasil)
				// hasil = JSON.stringify(hasil)
				this.setState({obj: hasil[0]}, this.findRoute())

			}).catch(err => {
				console.log(err)
			})
	}

	sendJob = (e) => {
		
		this.setState({
			data: e.currentTarget.getAttribute('data'),
			id: e.currentTarget.getAttribute('dataid')

		}, this.getData(e.currentTarget.getAttribute('dataid')))
	}

	createElementCircle = () => {
      return (
        <>
        &nbsp;&nbsp;
            <svg height="10" width="10">
              <circle cx="2.5" cy="2.5" r="2" fill="black" />
              Sorry, your browser does not support inline SVG.  
            </svg>
        &nbsp;&nbsp;
        </>
      )
    }

    render(){

		let ListCareer = () => {
			let res_data = []
			let card = []

			if(this.props.storeDataCareer != null){
				res_data = this.props.storeDataCareer
			}else{
				res_data = []
			}
			// console.log(res_data)
			//Grouping Berdasarkan Kode job
			const groupedMap = res_data.reduce(
				
				(entryMap, e) => entryMap.set(e.kategori, [...entryMap.get(e.kategori)||[], e]),
				new Map()
			);
			let keyName = Array.from( groupedMap.keys()) //Get nama key
			
			keyName.forEach(element => {

				card.push(
						
						<div className="career-container04">
							<div className="career-container06">
							<h2 className="career-text025">{element}</h2>
								{groupedMap.get(element).map(data => {
									// console.log(data.position)
									return (
										
										<div className="career-container07">
											<svg viewBox="0 0 1024 1024" className="career-icon">
											<path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
											</svg>
											<a href="#" data={data.position} dataid={data.id} onClick={this.sendJob}><span className="career-text026">{data.position}</span></a>
										</div>
									)
								})}
								
							</div>
							<br></br>
						</div>
						
				)
			});
			
			return card

		}

	    return (

			

		<section>

		<section className="career-section2 d-block">
			<section>
				<div className="career-container04">
					<span className="career-text017">{sessionStorage.getItem('lang') === "1" ? "Career" : "Karir"}</span>
					<h2 className="career-text018 fw-bold">{sessionStorage.getItem('lang') === "1" ? "Job Vacancy" : "Lowongan"}</h2>
					<span className="career-text019" style={{textAlign: "justify"}}>
					{ (this.props.storeData !== null) ? LineBreak(this.props.storeData.lowongan_tersedia) : <Shimmer width={"20rem"} height={"4rem"} />}
					</span>
				</div>
				{ListCareer()}
			</section>
		</section>


		{(this.state.id === 0) ? '' :  <AccordingCollapse dataFromParent={this.state.data} sub_title="Career" title="Job Vacancy" addClassName={'bg-white'} data={this.state.obj} />}

		<section>
		<div className="contacts-section4">
			<div className="contacts-container42">
				<span className="contacts-text50">
					<span>{sessionStorage.getItem('lang') === "1" ? "Contact" : "Kontak"}</span>
				</span>
				<h2 className="contacts-text52 fw-bold">
					<span>{sessionStorage.getItem('lang') === "1" ? "Our Branch" : "Cabang Kami"}</span>
				</h2>
				<span className="contacts-text54 text-black-50">
					JAKARTA {this.createElementCircle()}
					YOGYAKARTA {this.createElementCircle()}
					SOLO {this.createElementCircle()}
					SALATIGA {this.createElementCircle()}
					MAGELANG
					<br/>
					<br/>
					PURWOKERTO {this.createElementCircle()} 
					SURABAYA {this.createElementCircle()} 
					BALI {this.createElementCircle()} 
					MATARAM
				</span>
			</div>
		</div>
		</section>

		<section className="career-section4" style={{ backgroundImage: `url("https://minio.nexa.net.id/nexa/nexa-footer-background-1500w.png")` }}>
		<div className="career-container85">
			<span className="career-text103"><span>Career</span></span>
			<h2 className="career-text105 fw-bold">
			<span>PT. Internet Mulia Untuk Negeri</span>
			</h2>
			<span className="career-text107">
			<span>
				Jl. Jangli Dalam No 29 J, Candisari, Semarang Selatan,
			</span>
			<br/>
			<span>Semarang 50254 | Email : rekrutmen.smg@nexa.net.id</span>
			</span>
			<a href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"><button className="career-register3 button">Contact Us</button></a>
		</div>
		</section>
			
		</section>

		


        )
    }
}