import React, {Component} from 'react';
import MenuTabs from './ScrollableTabsButtonAuto';

export default class Portofolio2 extends Component {

    render(){
        return (
        	<section className="client-section6">
	          <div className="client-container04-mobile">
	            <span className="client-text16 pb-3">{this.props.sub_title}</span>
	            <h2 className="client-text17 fw-bold" style={{textAlign:"center", fontSize:"2.5rem"}}>{this.props.title}</h2>
	            <span className="client-text18 w-100">
	            	{this.props.desc}
	            </span>
	          </div>
	          
	          <MenuTabs />
	        </section>
        )
    }
}