import Footer from '../../components/Footer';
import Content from './Content';
import Maps from '../../../components/Maps';
import '../../../assets/supportpagearea.css';

import  React from 'react'
import { SupportPage, LineBreak, CreateToken } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

function Areas() {
  const [result, setResult] = React.useState(null)
  const langs = useSelector(state => state.langs);
  const [statusLang] = React.useState(langs[0].idLang)

  let langCondition = (statusLang !== langs[0].idLang)

  React.useEffect(() => {
   CreateToken().then((Data) => {
    SupportPage().then((data) => {
      setResult(data.response[0]) 
    }).catch((err) => {
      setResult(err)
    })
   })
  }, [langCondition]);

  let eTitle = "Nexa's Coverage"
  let desc = `Kini Nexa hadir di beberapa kota besar, untuk memenuhi kebutuhan pelanggan tercinta.
  Jika Anda memerlukan informasi lebih lanjut, silakan menghubungi nomor telepon di bawah ini,
  sesuai dengan lokasi tempat tinggal Anda.`
  let mainBannerPage = ""

  return (
    <div>
      <Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

      { result === null && <Content storeData={null} />}
      { result !== null && <Content storeData={LineBreak(result.tentang_1)} />}
      
      <section className="supportpagearea-section2 pt-3 mt-0">
        <map className="supportpagearea-container1">
          <Maps centerView={{lat: -7.522159, lng: 110.65794}} zoomView={9} />
        </map>
      </section>
      
    </div>
  );
}

export default Areas;
