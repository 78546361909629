import React, {Component, Fragment} from 'react';
import Container from 'react-bootstrap/Container';
import Accordion from 'react-bootstrap/Accordion'

export default class AccordingCollapse extends Component {
    loopingAccording = () => {
		let cardsAccording = []
		let res_data = this.props.data;

		res_data.forEach((res, index) => {
			cardsAccording.push(
			
			<Accordion.Item eventKey={index} key={index} className="p-3 mb-3">
				<Accordion.Header>
					<h2 style={{"fontSize":"1.2em"}} className={"nexasolution-text12 " + this.props.textColor}>{index+1}. {res.title}</h2>
				</Accordion.Header>
				<Accordion.Body className="text-muted">
					{res.desc}
				</Accordion.Body>
			</Accordion.Item>
			)
		})

		return (<Accordion className="w-100">{cardsAccording}</Accordion>)
	}


    render(){
    	return(
    		<Fragment>
		    	<section className={"nexasolution-section3 "+this.props.addClass}>
		    	<Container>
		          <div className="nexasolution-container03">
		            <span className="nexasolution-text10">{this.props.sub_title}</span>
		            <h2 className="nexasolution-text11 fw-bold" style={{fontSize:"1.5rem"}}>{ (typeof this.props.dataFromParent === 'undefined') ? this.props.title : this.props.dataFromParent }</h2>
		          </div>
		          

		          {this.loopingAccording()}
		        </Container>
		        </section>
    		</Fragment>
    	)
    }
}