
import Content from './Content';
import '../../../assets/contacts.css';

import  React from 'react'
import { ContactPage, CreateToken } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet';

function Contact() {

  //Google Analitik
  ReactGA.event({category: "user", action: "Company Contact"})

  const [result, setResult] = React.useState(null)
  const langs = useSelector(state => state.langs);
  const [statusLang] = React.useState(langs[0].idLang)

  let langCondition = (statusLang !== langs[0].idLang)

  React.useEffect(() => {

    CreateToken().then((data) => {
      ContactPage().then((data) => {
        setResult(data.response[0]) 
      }).catch((err) => {
        setResult(err)
      })
    })
    
  }, [langCondition]);

  
  let eTitle              = result ? result.contact_us.split('|')[0] : "Kontak Kami"
  let sAfterBanner_title  = result ? result.contact_us.split('|')[1] : "Ini Deskripsi"

  return (
    <div>
      <Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				{/* <meta property="og:image" content={mainBannerPage}></meta> */}
			</Helmet>

      { result == null && <Content storeData={null} /> }
      { result !== null && <Content storeData={result} /> }
      
    </div>
  );
}

export default Contact;
