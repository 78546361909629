
import Banner 		from '../../../components/Banner';
import Content 		from './Content';
import { bigTv as mediaQuery } from '../../../components/MediaQueries'
import '../../../assets/news.css';

import  React from 'react'
import { getArticle, LineBreak, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

function List({ isCompatible }) {
  //Google Analitik
  // ReactGA(
  // 	'User',
  // 	'List News'
  // );

  const langArticle = useSelector((state) => state.articlePage);
  const langs = useSelector((state) => state.langs);

  const [result, setResult] = React.useState(null);
  const [statusLang] = React.useState(langs[0].idLang);
  let [textListNews, setTextListNews] = React.useState(null);

  let langCondition = statusLang !== langs[0].idLang;

  let textListNewsFunc = () => {
    let lang = sessionStorage.getItem("lang");

    if (parseInt(lang) === 2) {
      return {
        textPost: "Dipost pada",
      };
    } else {
      return {
        textPost: "Posted on",
      };
    }
  };

  React.useEffect(() => {
    CreateToken().then((data) => {
      getArticle()
        .then((data) => {
          setResult(data.response.news_articles);
        })
        .catch((err) => {
          console.log("setTel", err);
        });
    });

    setTextListNews(textListNewsFunc());
  }, [langCondition]);

  // console.log('result', result)

  let eBanner = (
    <h1
      className={
        mediaQuery
          ? "banner-text fw-bold max-width-1100px"
          : "banner-text fw-bold"
      }
    >
      <span>{langArticle[0].title.split("|")[0]}</span>
      <br />
      <span className="solutioncorporate-text002">
        {langArticle[0].title.split("|")[1]}
      </span>
    </h1>
  );
  let sBanner_desc = (
    <div className={mediaQuery ? "max-width-1100px" : ""}>
      {LineBreak(langArticle[0].desc)}
    </div>
  );
  let mainBannerPage = isCompatible
    ? "https://minio.nexa.net.id/nexa/banner_news.webp"
    : "https://minio.nexa.net.id/nexa/banner_news.png";
  let eStyle = { width: "unset", gridGap: "unset", "padding-left": "10%" };
  let eTitle = "Nexa News";
  let desc = langArticle[0].desc;

  return (
    <div>
      <Helmet>
        <title>{`Nexa - ${eTitle}`}</title>
        <meta property="og:title" content={eTitle}></meta>
        <meta property="og:site_name" content="Nexa"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:description" content={desc}></meta>
        <meta name="description" content={desc}></meta>
        <meta property="og:type" content="product"></meta>
        <meta property="og:image" content={mainBannerPage}></meta>
      </Helmet>
      <Banner
        title={eBanner}
        desc={sBanner_desc}
        url_image={mainBannerPage}
        style={mediaQuery ? eStyle : {}}
      />
      {result === null && <Content storeData={null} />}
      {result !== null && <Content storeData={result} text={textListNews} />}
    </div>
  );
}

export default List;