import React, {Component} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink } from 'react-router-dom';
import '../assets/home.css';
import { useDispatch } from 'react-redux';
import { onChangeLang } from '../store/lang/Lang';
import { bigTv as mediaQuery } from '../components/MediaQueries';
import {  Info, 
          Work, 
          PeopleAlt, 
          PermContactCalendar, 
          CorporateFare, 
          School, 
          LocalHospital, 
          HomeWork, 
          AccountBalance, 
          SignalCellularAlt, 
          DataThresholding, 
          PlayLesson, 
          AppShortcut, 
          EmojiObjects, 
          Newspaper } from '@mui/icons-material';  

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Container from 'react-bootstrap/Container';



class HeaderView extends Component {
    constructor(props){
      super(props)
      this.state = {
        checkBahasa: (parseInt(sessionStorage.getItem('lang')) === 1) ? 'English' : 'Indonesia',
        checkIdBahasa: parseInt(sessionStorage.getItem('lang')),
        flag:(parseInt(sessionStorage.getItem('lang')) === 1) ? this.UriEnglish() : this.UriIndonesia()
      }
      // console.log('PROP HEDADER', this.props, this.state)
    }

    UriEnglish = () => {
      return 'https://flagicons.lipis.dev/flags/4x3/gb.svg'
    }

    UriIndonesia = () => {
      return 'https://flagicons.lipis.dev/flags/4x3/id.svg'
    }

    onChangeLang = (id) => {
      sessionStorage.setItem('lang', id)
      this.setState({
        checkBahasa: (parseInt(sessionStorage.getItem('lang')) === 1) ? 'English' : 'Indonesia',
        checkIdBahasa: id,
        flag: (parseInt(sessionStorage.getItem('lang')) === 1) ? this.UriEnglish() : this.UriIndonesia()
      })

      this.props.parentCallback(id)
    }
    render(){
        return (
        <header data-role="Header" className="navigasi-navigasi p-0">
          <nav className="navigasi-container m-auto justify-content-around" style={{"maxWidth": "60rem"}}>
            <NavLink to="/" className="navigasi-navlink">
              <img loading='lazy'
                alt="Main Logo"
                src="https://minio.nexa.net.id/nexa/nexa_logo_1500.png"
                className="navigasi-image"
                style={{"height":"56px !importtant"}}
              />
            </NavLink>
            <nav className="navigasi-container1">
              <nav className="navigasi-nav">
                <nav className="navigasi-nav1">
                  <Dropdown>
                    <Dropdown.Toggle
                        className="text-grey-500 bg-white boder-0"
                        style={{"border":"none"}}
                        variant="light" id="__dropdownCompany">
                        Company
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <NavLink to="/company" className="text-grey-500 dropdown-item">
                          <p className="w-100">About Us</p>
                        </NavLink>

                        <NavLink to="/career" className="text-grey-500 dropdown-item">
                          <p className="w-100">Career</p>
                        </NavLink>

                        <NavLink to="/client" className="text-grey-500 dropdown-item">
                          <p className="w-100">Clients</p>
                        </NavLink>

                        <NavLink to="/contact" className="text-grey-500 dropdown-item">
                          <p className="w-100">Contact</p>
                        </NavLink>

                        <NavLink to="/privacy_policy" className="text-grey-500 dropdown-item">
                          <p className="w-100">Privacy Policy</p>
                        </NavLink>

                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle
                        className="text-grey-500 bg-white boder-0"
                        style={{"border":"none"}}
                        variant="light" id="__dropdownCompany">
                        Service
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <NavLink to="/nexatel" className="text-grey-500 dropdown-item">
                          <p className="w-100">Nexatel</p>
                        </NavLink>

                        <NavLink to="/nexaapps" className="text-grey-500 dropdown-item">
                          <p className="w-100">Nexa Apps</p>
                        </NavLink>

                        <NavLink to="/nexadata" className="text-grey-500 dropdown-item">
                          <p className="w-100">Nexa Data</p>
                        </NavLink>

                        <NavLink to="/nexadigital" className="text-grey-500 dropdown-item">
                          <p className="w-100">Nexa Digital</p>
                        </NavLink>

                        <NavLink to="/solution" className="text-grey-500 dropdown-item">
                          <p className="w-100">Nexa Solutions</p>
                        </NavLink>

                    </Dropdown.Menu>
                  </Dropdown>

                  
                  <Dropdown>
                    <Dropdown.Toggle
                        className="text-grey-500 bg-white boder-0"
                        style={{"border":"none"}}
                        variant="light" id="__dropdownCompany">
                        Solution
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <NavLink to="/corporate" className="text-grey-500 dropdown-item">
                          <p className="w-100">Corporate</p>
                        </NavLink>

                        <NavLink to="/education" className="text-grey-500 dropdown-item">
                          <p className="w-100">Education</p>
                        </NavLink>

                        <NavLink to="/goverment" className="text-grey-500 dropdown-item">
                          <p className="w-100">Goverment</p>
                        </NavLink>

                        <NavLink to="/hospitality" className="text-grey-500 dropdown-item">
                          <p className="w-100">Hospitality</p>
                        </NavLink>

                        <NavLink to="/manufacture" className="text-grey-500 dropdown-item">
                          <p className="w-100">Manufacture</p>
                        </NavLink>

                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle
                        className="text-grey-500 bg-white boder-0"
                        style={{"border":"none"}}
                        variant="light" id="__dropdownCompany">
                        Support
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <NavLink to="/areas" className="text-grey-500 dropdown-item">
                          <p className="w-100">Areas</p>
                        </NavLink>

                        <NavLink to="/subscribe" className="text-grey-500 dropdown-item">
                          <p className="w-100">Subscription</p>
                        </NavLink>

                        {/*<NavLink to="/payment" className="text-grey-500 dropdown-item">
                          <div className="w-100">Payment Method</div>
                        </NavLink>*/}

                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle
                        className="text-grey-500 bg-white boder-0"
                        style={{"border":"none"}}
                        variant="light" id="__dropdownCompany">
                        News & Article
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <NavLink to="/news" className="text-grey-500 dropdown-item">
                          <p className="w-100">News & Article</p>
                        </NavLink>

                        {/*<NavLink to="/subscribe" className="text-grey-500 dropdown-item">
                          <div className="w-100">Event</div>
                        </NavLink>*/}

                    </Dropdown.Menu>
                  </Dropdown>
                </nav>
              </nav>
            </nav>
            <nav className="navigasi-btn-group">
              <NavLink to="/areas" className="navigasi-login">
                <section>Area</section>
              </NavLink>
              <Dropdown>
                    <Dropdown.Toggle
                        className="text-grey-500 bg-white boder-0 navigasi-login button d-flex"
                        style={{"border":"none"}}
                        variant="light" id="__dropdownFlag">
                        <img alt="" src={this.state.flag} className="align-self-center me-2" style={{"width":"20px"}} /> 
                        {this.state.checkBahasa}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                          (this.state.checkIdBahasa === 1) ? 
                          <button onClick={() => { this.onChangeLang(2) }} className="text-grey-500 dropdown-item d-flex">
                            <img alt="" src={this.UriIndonesia()} className="align-self-center me-2" style={{"width":"20px"}} /> 
                            <p className="w-100">Indonesia</p>
                          </button>
                          :
                          <button onClick={() => { this.onChangeLang(1) }} className="text-grey-500 dropdown-item d-flex">
                            <img alt="" src={this.UriEnglish()} className="align-self-center me-2" style={{"width":"20px"}} /> 
                            <p className="w-100">English</p>
                          </button>
                        }

                    </Dropdown.Menu>
                  </Dropdown>

              {mediaQuery ? <NavLink to="/contact" className="navigasi-register button">
                <section>Contact Us</section>
              </NavLink> : ""}
            
            
            </nav>
          </nav>
          <nav data-type="BurgerMenu" className="navigasi-burger-menu">
            <svg viewBox="0 0 1024 1024" className="navigasi-icon">
              <path
                d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              ></path>
            </svg>
          </nav>
          <nav data-type="MobileMenu" className="navigasi-mobile-menu">
            <div className="navigasi-nav2">
              <div className="navigasi-container2">
                <img
                  alt=""
                  src="https://presentation-website-assets.teleporthq.io/logos/logo.png"
                  className="navigasi-image1"
                />
                <div data-type="CloseMobileMenu" className="navigasi-menu-close">
                  <svg viewBox="0 0 1024 1024" className="navigasi-icon02">
                    <path
                      d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div>
              <svg viewBox="0 0 950.8571428571428 1024" className="navigasi-icon04">
                <path
                  d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
                ></path></svg
              ><svg
                viewBox="0 0 877.7142857142857 1024"
                className="navigasi-icon06"
              >
                <path
                  d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
                ></path></svg
              ><svg
                viewBox="0 0 602.2582857142856 1024"
                className="navigasi-icon08"
              >
                <path
                  d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
                ></path>
              </svg>
            </div>
          </nav>
        </header>
        )
    }
}

function HeaderViewHamburger({props}) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const arrMenu = [
	  {
	  	'title': 'Company',
	  	'url': '/company',
	  	'sub_menu': [
		  	{
		  		'title': 'About Us',
		  		'url': '/aboutus',
		  		'icon': <Info />
		  	},
		  	{
		  		'title': 'Career',
		  		'url': '/career',
		  		'icon': <Work />
		  	},
		  	{
		  		'title': 'Clients',
		  		'url': '/client',
		  		'icon': <PeopleAlt />
		  	},
		  	{
		  		'title': 'Contact',
		  		'url': '/contact',
		  		'icon': <PermContactCalendar />
		  	},
	  	]
	  },
	  {
	  	'title': 'Services',
	  	'url': '/services',
	  	'sub_menu': [
		  	{
		  		'title': 'Nexatel',
		  		'url': '/nexatel',
		  		'icon': <SignalCellularAlt />
		  	},
		  	{
		  		'title': 'Nexa Apps',
		  		'url': '/nexaapps',
		  		'icon': <AppShortcut />
		  	},
		  	{
		  		'title': 'Nexa Data',
		  		'url': '/nexadata',
		  		'icon': <DataThresholding />
		  	},
		  	{
		  		'title': 'Nexa Digital',
		  		'url': '/nexadigital',
		  		'icon': <PlayLesson />
		  	},
		  	{
		  		'title': 'Nexa Solutions',
		  		'url': '/solution',
		  		'icon': <EmojiObjects />
		  	},
	  	]
	  },
	  {
	  	'title': 'Solution',
	  	'url': '/solution',
	  	'sub_menu': [
		  	{
		  		'title': 'Corporate',
		  		'url': '/corporate',
		  		'icon': <CorporateFare />
		  	},
		  	{
		  		'title': 'Education',
		  		'url': '/education',
		  		'icon': <School />
		  	},
		  	{
		  		'title': 'Government',
		  		'url': '/goverment',
		  		'icon': <AccountBalance />
		  	},
		  	{
		  		'title': 'Hospitality',
		  		'url': '/hospitality',
		  		'icon': <LocalHospital />
		  	},
		  	{
		  		'title': 'Manufacture',
		  		'url': '/manufacture',
		  		'icon': <HomeWork />
		  	},
	  	]
	  },
	  {
	  	'title': 'Support',
	  	'url': '/support',
	  	'sub_menu': [
		  	{
		  		'title': 'Areas',
		  		'url': '/areas',
		  		'icon': <CorporateFare />
		  	},
		  	{
		  		'title': 'Subscription',
		  		'url': '/subscribe',
		  		'icon': <School />
		  	}
	  	]
	  },
	  {
	  	'title': 'News & Article',
	  	'url': '/news',
	  	'sub_menu': [
		  	{
		  		'title': 'News & Article',
		  		'url': '/news',
		  		'icon': <Newspaper />
		  	}
	  	]
	  }
  ]

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >	
    <div className="card">
	    <NavLink to="/">
	    	<img alt="" src="https  ://minio.nexa.net.id/nexa/nexa_logo_1500.png" className="img-card-top pe-5 ps-5 pb-2 pt-2 w-75 align-self-center" />
	    </NavLink>
    </div>
    <Divider />
    {arrMenu.map((text, index) => (
    <List 
    	key={index}
      	sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
      	component="nav"
      	aria-labelledby="nested-list-subheader"
      	subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {text.title}
        </ListSubheader>
      }>
      {
      	text.sub_menu.map((row, idx) => (
      	<NavLink to={row.url} key={row.title}>
	      	<ListItem disablePadding>
	            <ListItemButton
	            	selected={selectedIndex === row.title}
          			onClick={(event) => handleListItemClick(event, row.title)}
          		>
	              <ListItemIcon>
	              	{row.icon}
	                
	              </ListItemIcon>
	              <ListItemText primary={row.title} />
	            </ListItemButton>
	        </ListItem>
        </NavLink>
      	))
      }
      </List>
      ))}
      
      <Divider />

    </Box>
  );

  const UriEnglish = () => {
  	return 'https://flagicons.lipis.dev/flags/4x3/gb.svg'
  }

  const UriIndonesia = () => {
  	return 'https://flagicons.lipis.dev/flags/4x3/id.svg'
  }

  const [bahasa, setBahasa] = React.useState( parseInt(sessionStorage.getItem('lang')) );

  const checkBahasa = () => {
  	return (bahasa === 1) ? 'English' : 'Indonesia'
  }

  const boolBahasa = () => {
  	return bahasa === 1
  }

  const dispatch = useDispatch();

  const onChangeBahasa = (id) => {
  	sessionStorage.setItem('lang', id)
  	setBahasa(parseInt(id))
  	dispatch(onChangeLang(id))
  }

  return (
    <React.Fragment>
    	<style jsx="true">
    	{`
    		.dropdown-toggle::after{
    			display:none;
    		}
    		.btn-check:focus+.btn, .btn:focus,
    		.btn-check:active+.btn:focus, .btn-check:checked+.btn:focus, .btn.active:focus, .btn.show:focus, .btn:active:focus
    		{ box-shadow: unset }
    	`}
    	</style>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          
			<nav className="navbar navbar-light bg-light w-100 relative" id="__mainHeader">
				<Container>
				<NavLink className="navbar-brand" to="/" onClick={(event) => handleListItemClick(event, 1)}>&nbsp;
					<img alt="" src="https://minio.nexa.net.id/nexa/nexa_logo_1500.png" width="auto" height="30" className="d-inline-block align-top"/>
				</NavLink>
				<Dropdown className="ms-auto">
					<Dropdown.Toggle
						className="text-grey-500 boder-0 d-flex"
						style={{"border":"none"}}
						variant="light" id="__mFlag">
						<img alt="" src={ (boolBahasa()) ? UriEnglish() : UriIndonesia() } className="align-self-center me-2" style={{"width":"20px"}} /> 
						{checkBahasa()}
					</Dropdown.Toggle>

					<Dropdown.Menu style={{width:"20px"}}>
						<button onClick={() => { (boolBahasa()) ? onChangeBahasa(2) : onChangeBahasa(1) }} className="text-grey-500 dropdown-item d-flex">
						<img alt="" src={ (boolBahasa()) ? UriIndonesia() : UriEnglish() } className="align-self-center me-2" style={{"width":"20px"}} /> 
						<div className="w-100">{ (boolBahasa()) ? 'Indonesia' : 'English'}</div>
						</button>
					</Dropdown.Menu>
				</Dropdown>
				<Button onClick={toggleDrawer(anchor, true)}>
					&nbsp;
					<svg viewBox="0 0 1024 1024" className="navigasi-icon">
		             	<path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
		            </svg>
				</Button>
			  </Container>
			</nav>

          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      
    </React.Fragment>
  );
}



function Header(){
  const dispatch = useDispatch();
  return(
    <>
    {mediaQuery ?  <HeaderView parentCallback={ (ev) => dispatch(onChangeLang(ev)) } /> : <HeaderViewHamburger />}
    </>
  )
}

export default Header
