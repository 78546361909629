import React, {useState, useEffect } from 'react'
import { GoogleMap, useLoadScript, Circle } from "@react-google-maps/api"
import data_location from './MapLocation'
import { Shimmer } from 'react-shimmer'

const loadOptionCircle = (radius = 0) => {
  let options = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.20,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: (radius === 0) ? 1000 : radius,
    zIndex: 1
  }

  return options;
}

const onLoadCircle = circle => {
  // console.log('Circle onLoad circle: ', circle)
}

const onUnmountCircle = circle => {
  // console.log('Circle onUnmount circle: ', circle)
}

const onClickMaps = e => {
  console.log('onClickMaps', e.latLng.lat(), e.latLng.lng())
}


export default function Maps({zoomView=0, centerView=0}) {
  // console.log(zoomView, centerView,'MapsProp')
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY,
  });

  if (!isLoaded) return <div><Shimmer width={"20rem"} height={"4rem"} /></div>
  return <MapContainer zoomData={zoomView} centerData={centerView} key={Math.random()} />;
}

function MapContainer({zoomData, centerData}){
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => setIsMounted(true), []);
  const center = {lat: -8.236723, lng: 112.223676}

  if (isMounted) {
    return(
    
      <GoogleMap
      zoom={ (zoomData === 0) ? 7.9 : zoomData }
      center={ (centerData === 0) ? center : centerData  }
      onClick={onClickMaps}
      mapContainerClassName="map-container"
    >
    {
      <map>
      {
        data_location.map((row, index) => {
          return(
            <Circle key={index} onLoad={onLoadCircle} onUnmount={onUnmountCircle} center={row.latLng} options={loadOptionCircle(row.radius)} />
          )
        })
      }
      </map>
    }
    </GoogleMap>
    )
  }
}
