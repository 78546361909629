import { GA4React } from "ga-4-react";

const ga4react = new GA4React("G-FM5LN5TZ5F").initialize();

const trackPathForAnalytics = (path, search, title) => {
    // const { path, search, title } = data;
    ga4react
        .then((ga) => {
            ga.pageview(path, search, title);
        })
        .catch((err) => console.error(`Analytics failed: ${err}`));
};

export {trackPathForAnalytics};