import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import AccordingCollapse from '../../components/AccordingCollapse'
import { LineBreak, detectDevice } from '../../../controller/index'
import { Shimmer } from 'react-shimmer';

let isCompatible = detectDevice()

export default class Content extends Component {

	loopingData = () => {
		

		let El = []
		let res_data = [
			{
				'name': 'AKPOL',
				'desc': 'Attendance Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/akpol.webp' : 'https://minio.nexa.net.id/nexa/akpol.png'
			},
			{
				'name': 'Haitoko',
				'desc': 'Ecommerce Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/haitoko.webp' : 'https://minio.nexa.net.id/nexa/haitoko.png'
			},
			{
				'name': 'BAPENDA',
				'desc': 'Survey Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/bapenda.webp' : 'https://minio.nexa.net.id/nexa/bapenda.png'
			},
			{
				'name': 'Nexa Card',
				'desc': 'Digital Business Card',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/nexacard.webp' : 'https://minio.nexa.net.id/nexa/nexacard.png'
			},
			{
				'name': 'Perkasa Telkomselindo',
				'desc': 'Retail Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/perkasa.webp' : 'https://minio.nexa.net.id/nexa/perkasa.png'
			},
			{
				'name': 'Signa',
				'desc': 'HR Management System Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/signa.webp' : 'https://minio.nexa.net.id/nexa/signa.png'
			},
			{
				'name': 'Tentremmall.com',
				'desc': 'Company Profile Website',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/tentrem.webp' : 'https://minio.nexa.net.id/nexa/tentrem.png'
			},
			{
				'name': 'Ontime',
				'desc': 'Attendance Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/ontime.webp' : 'https://minio.nexa.net.id/nexa/ontime.png'
			},
			{
				'name': 'Os Bond',
				'desc': 'Token Gym Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/osbond.webp' : 'https://minio.nexa.net.id/nexa/osbond.png'
			},
			{
				'name': 'Yayasan Islam Amanah',
				'desc': 'Management System Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/yayasan.webp' : 'https://minio.nexa.net.id/nexa/yayasan.png'
			},
			{
				'name': 'Semarges',
				'desc': 'Event Application',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/semar.webp' : 'https://minio.nexa.net.id/nexa/semar.png'
			},
			{
				'name': 'PSP',
				'desc': 'Company Management System',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/psp.webp' : 'https://minio.nexa.net.id/nexa/psp.png'
			},
			{
				'name': 'Tuku Tiket',
				'desc': 'Event Booking System',
				'profile': isCompatible ? 'https://minio.nexa.net.id/nexa/tukutiket.webp' : 'https://minio.nexa.net.id/nexa/tukutiket.png'
			}
		]
		res_data.forEach((row, index) => {
			El.push(
				<div key={index} className="col-md-4 mb-4">
					<div className="card rounded-app"
						onMouseEnter={e => {
								document.querySelector('#__cardHover_'+index +' .row').classList.add('portofolio-hover')
								document.querySelector('#__cardHover_'+index +' .row .card-footer').classList.add('card-footer-border-white')
								document.getElementById('__cardHover_'+index).classList.add('shadow-lg')
								document.querySelector('#__cardHover_'+index +' .row .card-body span').classList.remove('text-danger')
							}}
							onMouseLeave={e => {
								document.querySelector('#__cardHover_'+index +' .row').classList.remove('portofolio-hover')
								document.querySelector('#__cardHover_'+index +' .row .card-footer').classList.remove('card-footer-border-white')
								document.getElementById('__cardHover_'+index).classList.remove('shadow-lg')
								document.querySelector('#__cardHover_'+index +' .row .card-body span').classList.add('text-danger')

							}}
						id={"__cardHover_"+index}>
						<img loading='lazy' alt={`${row.name} App`} src={row.profile} id={"__imgHover_"+index} className="card-img-top" />
						<div className="row m-0">
							<div className="card-body p-0 pt-2 mt-2 me-3 ms-3">
							<span className="text-danger fw-bold">{row.name}</span>
								<div className="card-footer bg-unset p-0 pt-2 mt-2 mb-3">
								{row.desc}
								</div>
							</div>
							
						</div>
						
					</div>
				</div>
			)
		})
		return El;
	}
	getData = () => {
		const res_data = [
		{
			'title': 'Custom Software Development Services',
			'desc': 'Don’t get us wrong, sometimes you don’t have the time or team to think about this. Yet, this is so important. Delivers solutions that meet the specific needs of your business, no matter the industry or size.'
		},
		{
			'title': 'Web Design & Development',
			'desc': 'Today’s media landscape requires a neutral perspective from an experienced team. Take objective views to develop integrated cross channel media campaigns.'
		},
		{
			'title': 'Mobile Application Development',
			'desc': 'With statistics indicating that most people who own smartphones rarely if ever turn their devices off, be a part of your users’ life as never before.'
		},
		{
			'title': 'API Development Services',
			'desc': 'Any successful business relies on seamless integration. API-driven businesses allows you to scale and grow at a tremendous speed.'
		},
		{
			'title': 'Payment Integration',
			'desc': 'Get hassle free payment integration experience! NEXA Apps will make it easier to work with multiple payment merchants. Accept digital payment anywhere in the universe. Integrated with different payment merchants such as Midtrans, Doku, Xendit, Moota, BCA VA API, you name it.'
		},
		{
			'title': 'Startup Development',
			'desc': 'Turn your ideas into a powerful start up that will offer endless benefits for your users’ needs'
		},
		{
			'title': 'IT Staffing',
			'desc': 'Achieve a higher level of growth by connecting you with the best specialists that will help propel your business positively'
		}
		];

		let storeNull = []
		for (let i = 0; i < 6; i++) {
			storeNull.push(<Shimmer  width={"inherit"} height={"inherit"} />)
		}

		let storeData = (this.props.storeData !== null) ? this.props.storeData.product.split('|') : storeNull
		res_data.map((row, idx) => (
			row.desc = storeData[idx]
		))

		return res_data
	}
	render(){
		return (
			
			<section>
				<div className='button-wa-fixed-mobile'>
					<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
						<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed-mobile">
						<path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
						</svg>
					</a>
				</div> 

			<section className="nexaapp-section2">
	          <div className="nexaapp-container05">
	            <span className="nexaapp-text014 text-danger">Nexa Apps</span>
	            <h2 className="nexaapp-text015 fw-bold fs-36">Service Features</h2>
	          </div>

			  	

		        <Container>
					<Row>
						<Col xs={12} md={4}>
							<Card className="bg-none border-0" style={{"maxWidth":"250px","margin":"auto"}}>
								<Card.Img variant="top" src="https://minio.nexa.net.id/nexa/icon_enterprise.png" className="align-self-center nexaapp-image02" />
								<Card.Body className="text-center">
									<Card.Title className="nexaapp-text020">
										{ this.props.storeData !== null ? LineBreak(this.props.storeData.features.split('|')[0]) : <Shimmer width={"20rem"} height={"4rem"} />}
									</Card.Title>
									<span className="nexaapp-text024">{ this.props.storeData !== null ? LineBreak(this.props.storeData.features.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} />}</span>
								</Card.Body>
							</Card>
						</Col>

						<Col xs={12} md={4}>
							<Card className="bg-none border-0" style={{"maxWidth":"250px","margin":"auto"}}>
								<Card.Img variant="top" src="https://minio.nexa.net.id/nexa/icon_bussiness_app.png" className="align-self-center nexaapp-image02" />
								<Card.Body className="text-center">
									<Card.Title className="nexaapp-text020">
										{ this.props.storeData !== null ? LineBreak(this.props.storeData.features.split('|')[2]) : <Shimmer width={"20rem"} height={"4rem"} />}
									</Card.Title>
									<span className="nexaapp-text024">{ this.props.storeData !== null ? LineBreak(this.props.storeData.features.split('|')[3]) : <Shimmer width={"20rem"} height={"4rem"} />}</span>
								</Card.Body>
							</Card>
						</Col>

						<Col xs={12} md={4}>
							<Card className="bg-none border-0" style={{"maxWidth":"250px","margin":"auto"}}>
								<Card.Img variant="top" src="https://minio.nexa.net.id/nexa/icon_redevelopment.png" className="align-self-center nexaapp-image02" />
								<Card.Body className="text-center">
									<Card.Title className="nexaapp-text020">
										{ this.props.storeData !== null ? LineBreak(this.props.storeData.features.split('|')[4]) : <Shimmer width={"20rem"} height={"4rem"} />}
									</Card.Title>
									<span className="nexaapp-text024">{ this.props.storeData !== null ? LineBreak(this.props.storeData.features.split('|')[5]) : <Shimmer width={"20rem"} height={"4rem"} />}</span>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>

	        </section>

	        <AccordingCollapse
	        	sub_title=<h2 className="text-danger">Nexa Apps</h2>
	        	title="Product & Services"
	        	data={this.getData()}
	        	addClass={'bg-white pe-3 ps-3'}
	        	textColor="text-danger" />
			
			<section className="nexaapp-section4">
			    <div className="nexaapp-container25">
			        <span className="nexaapp-text046 text-danger">
			            <span>Nexa Apps</span>
			        </span>
			        <h2 className="nexaapp-text048 fw-bold">
			            <span>Portfolios</span>
			        </h2>
			    </div>
			    <div className="nexaapp-container26">
			        <div className="nexaapp-container27">
				        <div className="row p-0 mt-5 me-5 ms-5">
				        	{this.loopingData()}
				        </div>
			            
			        </div>
			    </div>
			</section>
			</section>
		)
	}
}