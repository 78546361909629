import React, { Component, Fragment } from "react";
import { bigTv as mediaQuery } from "./MediaQueries";
import Accordion from "react-bootstrap/Accordion";

export default class AccordingCollapse extends Component {
  loopingAccording = () => {
    let cardsAccording = [];
    let res_data = this.props.data;
    console.log(res_data);
    const descExamp = "<b>This is bold text.</b>";
    res_data.forEach((res, index) => {
      cardsAccording.push(
        <Accordion.Item eventKey={index} key={index} className="p-3 mb-3">
          <Accordion.Header>
            <h2 className={"nexasolution-text12 " + this.props.textColor}>
              {index + 1}. {res.title}
            </h2>
          </Accordion.Header>
          <Accordion.Body
            className="text-muted"
            dangerouslySetInnerHTML={{ __html: res.desc }}
          ></Accordion.Body>
        </Accordion.Item>
      );
    });

    return (
      <Accordion
        className={mediaQuery ? "w-100 container max-width-1100px" : "w-100"}
      >
        {cardsAccording}
      </Accordion>
    );
  };

  render() {
    return (
      <Fragment>
        <section className={"nexasolution-section3 " + this.props.addClassName}>
          <div className="nexasolution-container03">
            <span className="nexasolution-text10">{this.props.sub_title}</span>
            <h2 className="nexasolution-text11 fw-bold">
              {typeof this.props.dataFromParent === "undefined"
                ? this.props.title
                : this.props.dataFromParent}
            </h2>
          </div>

          {this.loopingAccording()}
        </section>
      </Fragment>
    );
  }
}
