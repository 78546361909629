import React, {Component, useState, useEffect} from 'react';
import Swal from 'sweetalert2'
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api"
import { LineBreak, SubscribePost } from '../../../controller/index'
import axios from 'axios';
import { Shimmer } from 'react-shimmer';

const divStyle = {
  background: `white`,
  border: `1px solid #ccc`,
  padding: 15
}
const onClickMaps = e => {
  console.log('onClickMaps', e.latLng.lat(), e.latLng.lng())
}

function Maps({zoomView=0, centerView=0}) {
  // console.log(zoomView, centerView,'MapsProp')
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_KEY,
  });

  if (!isLoaded) return <div><Shimmer width={"20rem"} height={"4rem"} /></div>;
  return <MapContainer zoomData={zoomView} centerData={centerView} key={Math.random()} />;
}

function MapContainer({zoomData, centerData}){

  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsMounted(true), []);
  const center = {lat: -7.021314, lng: 110.427398}

  const OnClickMarker = (e) => {
  	setIsOpen(true)
  	console.log('onClickMarker')
	}

	const onCloseClick = e => {
	setIsOpen(false)
	console.log('InfoWindow close', e)
}

  if (isMounted) {
    return(
      <GoogleMap
      zoom={ (zoomData === 0) ? 15 : zoomData }
      center={ (centerData === 0) ? center : centerData  }
      onClick={onClickMaps}
      mapContainerClassName="map-container"
    >
    {
    	<>
    	<Marker onClick={OnClickMarker} position={{lat: -7.0213080499589084, lng: 110.42711278794319}} />
    	<InfoWindow position={{lat: -7.019202954309754, lng: 110.42712977909851}} onCloseClick={onCloseClick}>
      		<div style={divStyle}>
        		<h6>PT Internet Mulia Untuk Negeri</h6>
      		</div>
    	</InfoWindow>
    	{isOpen ? (

	    	<InfoWindow position={{lat: -7.019202954309754, lng: 110.42712977909851}} onCloseClick={onCloseClick}>
	      		<div style={divStyle}>
	        		<h6>PT Internet Mulia Untuk Negeri</h6>
	      		</div>
	    	</InfoWindow>

    	) : null}
    	</>
    }
    </GoogleMap>
    )
  }
}

export default class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contact: {
				'title': (this.props.storeData !== null) ? this.props.storeData.contact_us.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />,
				'desc': (this.props.storeData !== null) ? this.props.storeData.contact_us.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} />,
			},
			head_office: {
				'address': (this.props.storeData !== null) ? this.props.storeData.head_office.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />,
				'contact': (this.props.storeData !== null) ? this.props.storeData.head_office.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} />,
				'email': (this.props.storeData !== null) ? this.props.storeData.head_office.split('|')[2] : <Shimmer width={"20rem"} height={"4rem"} />,
        'maps': (this.props.storeData !== null) ? this.props.storeData.link_map : <Shimmer width={"20rem"} height={"4rem"} />
			},
			our_branch: {
				'title': (this.props.storeData !== null) ? this.props.storeData.our_branch.split('|')[0] : "",
				'desc': (this.props.storeData !== null) ? this.props.storeData.our_branch.split('|')[1] : ""
			},
      phone : {
        'phone': (this.props.storeData !== null) ? this.props.storeData.link_phone : <Shimmer width={"20rem"} height={"4rem"} />
      },
      isSubmitting: false,
      submitCount: 0
		};
	}


	postSubscription = async () => {
		let dump = {
			"nama"    : document.getElementById('fullname').value || "",
			"company" : document.getElementById('comp').value || "",
			"email"   : document.getElementById('mail').value || "",
			"address" : document.getElementById('address').value || "",
			"note"    : document.getElementById('msg').value || "",
			"no_tlp"  : document.getElementById('telp').value || "",
			// "insert_by" : "",
			// "update_by" : "admin"
		}

    if (this.state.submitCount > 0) {
      Swal.fire({
        title: 'Error!',
        text: 'You already submit this form!',
        icon: 'error',
        confirmButtonText: 'ok'
      })
      return false
    }

    //validasi
    for await (let key of Object.keys(dump)) {
      if (dump[key] === "") {
        Swal.fire({
          title: 'Error!',
          text: 'Please fill all form!',
          icon: 'error',
          confirmButtonText: 'ok'
        })
        return false
      }
    }

    this.setState({isSubmitting: true})

    //Send Email
    await axios.post("https://nexaclientsystem.nexagroup.id/service/email/send_mail_support", dump).then().catch((err) => {console.log(err)})

		SubscribePost(dump).then((data) => {
			if (data.metadata.status === 200) {

        this.setState({submitCount: this.state.submitCount+1})

        // import('react-facebook-pixel')
        // .then((x) => x.default)
        // .then((ReactPixel) => {
        //     ReactPixel.init("1546029366185128")
        //     ReactPixel.pageView()
        //     ReactPixel.track('Purchase', dump)
				// 		ReactPixel.fbq('tracks', 'Purchase', dump)
        // })
        
        // import('react-facebook-pixel')
        // .then((x) => x.default)
        // .then((ReactPixel) => {
        //     //1546029366185128 //ndak tau punya siapa ikut AI conference di ai.nexa.net.id
        //     //175844151523778 // mungkin punya nexa
        //     //3236223809985213 // yang digunakan sekarang
        //     //sama dengan yang index di public
        //     //di index menggunakan 1546029366185128

        //     ReactPixel.init("1546029366185128")
        //     ReactPixel.pageView()
        //     ReactPixel.trackCustom('SubmitApplication', dump)
        //     // ReactPixel.fbq('tracks', 'Purchase')
            
        // })

        // window.fbq('track', 'Purchase', dump)
        window.fbq('trackCustom', 'SubmitApplication', dump)

				Swal.fire({
					title: 'success!',
					text: data.metadata.message,
					icon: 'success',
					confirmButtonText: 'ok'
				})

        // return <MetaPixel type="Purchase" data={dump} />
			} else {
        // import('react-facebook-pixel')
        // .then((x) => x.default)
        // .then((ReactPixel) => {
        //     //1546029366185128 //ndak tau punya siapa ikut AI conference di ai.nexa.net.id
        //     //175844151523778 // mungkin punya nexa
        //     //3236223809985213 // yang digunakan sekarang
        //     //sama dengan yang index di public
        //     //di index menggunakan 1546029366185128

        //     ReactPixel.init("1546029366185128")
        //     ReactPixel.pageView()
        //     // ReactPixel.trackCustom('SubmitApplicationFailed', dump)
        //     // ReactPixel.fbq('tracks', 'PurchaseFailed')
            
        // })

        // window.fbq('track', 'PurchaseFailed', dump)
        // window.fbq('trackCustom', 'SubmitApplicationFailed', dump)

				Swal.fire({
					title: 'Error!',
					text: data.metadata.message,
					icon: 'error',
					confirmButtonText: 'ok'
				})
			}

      this.setState({isSubmitting: false})
		}).catch((err) => {
			console.log('post', err)
		})
	}
  
	createElementCircle = () => {
    return (
      <>
      &nbsp;&nbsp;
          <svg height="10" width="10">
            <circle cx="2.5" cy="2.5" r="2" fill="black" />
            Sorry, your browser does not support inline SVG.  
          </svg>
      &nbsp;&nbsp;
      </>
    )
  }

  customeLineBreak = (text) => {
		const newText = text.split('<br/>').map((str, idx) => (
			<React.Fragment key={str}>{str}&nbsp;
			{ idx === 4 && <><br/><br/></>}
			{ (idx !== 4 && idx !== 5 && idx !== text.split('<br/>').length-1)  && this.createElementCircle()}
			{ idx === 5 && this.createElementCircle()}
			</React.Fragment>
		))
		return newText;
  }

  render(){
    return (
  	<>
    	<section className="contacts-section2">
          <div className="contacts-container01">
            <span className="contacts-text">Contact</span>
            <h2 className="contacts-text01 fw-bold">{ this.props.storeData !== null ? this.props.storeData.contact_us.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} /> }</h2>
            <span className="contacts-text02">
              { this.props.storeData !== null ? LineBreak(this.props.storeData.contact_us.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} /> }
            </span>
          </div>
          <div className="contacts-container02">
            <div className="contacts-container03">
              <div className="contacts-container04">
                <svg viewBox="0 0 1024 1024" className="contacts-icon">
                  <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                </svg>
                <h2 className="contacts-text05">Head Office</h2>
              </div>
              <span className="contacts-text06">
                {/* { this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[0]) : 'fetching data address...' } */}
                <a rel="noopener noreferrer" target="_blank" href={""+this.state.head_office.maps}><span>{ this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[0]) : <Shimmer width={"20rem"} height={"4rem"} /> }</span></a>
              </span>
            </div>
            <div className="contacts-container05">
              <div className="contacts-container06">
                <svg viewBox="0 0 1024 1024" className="contacts-icon02">
                  <path d="M512 42q160 0 272 113t112 271v428q0 52-38 90t-90 38h-256v-86h298v-42h-170v-342h170v-86q0-124-87-211t-211-87-211 87-87 211v86h170v342h-128q-52 0-90-38t-38-90v-300q0-158 112-271t272-113z"></path>
                </svg>
                <h2 className="contacts-text11">Call Us</h2>
              </div>
              <span className="contacts-text12">
              {/* { this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[1]) : 'fetching data contact...' } */}
              {/* <a href={"tel:"+this.state.phone.phone}><span>{ this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} /> }</span></a> */}
              <a href='tel:024-850-9595'> <span> Call. 024-850-9595</span></a><br/>
              <a href="https://wa.gmedia.id/hd" target="__blank"> <span>Phone. +62 811-2971-000</span> </a> <br/>
              <a href="http://wa.me/6282115555646" target="__blank"> <span>Phone. +62 8211-5555-646</span> </a>
              
              </span>
            </div>
            <div className="contacts-container07">
              <div className="contacts-container08">
                <svg viewBox="0 0 1024 1024" className="contacts-icon04">
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <h2 className="contacts-text16">Email Us</h2>
              </div>
              <span className="contacts-text17">
                <a href={"mailto:"+this.state.head_office.email}><span>Email. { this.props.storeData !== null ? LineBreak(this.props.storeData.head_office.split('|')[2]) : <Shimmer width={"20rem"} height={"4rem"} /> }</span></a>
              </span>
            </div>
          </div>
      </section>

	    <section className="contacts-container17">
          <div className="contacts-section3 row">
            <div className="contacts-container18 col-6">
              <form>
              <div className="contacts-container19">
                <div className="contacts-container20">
                  <div className="contacts-container21">
                    <span className="contacts-text38">Name*</span>
                    <input type="text" placeholder="Your Name" id="fullname" className="form-control contacts-textinput input"/>
                    <div className="invalid-feedback">
                      Please provide a valid city.
                    </div>
                  </div>
                  <div className="contacts-container22">
                    <span className="contacts-text39">Email*</span>
                    <input type="text" placeholder="Email to reach you" id="mail" className="form-control contacts-textinput01 input"/>
                  </div>
                </div>
                <div className="contacts-container23">
                  <div className="contacts-container24">
                    <span className="contacts-text40">Company Name*</span>
                    <input type="text" placeholder="Your Company" id="comp" className="form-control contacts-textinput02 input"/>
                  </div>
                  <div className="contacts-container25">
                    <span className="contacts-text41">Telp No.*</span>
                    <input type="text" placeholder="Your Phone Number" id="telp" className="form-control contacts-textinput03 input"/>
                  </div>
                </div>
              </div>
              <div className="contacts-container26">
                <div className="contacts-container27">
                  <div className="contacts-container28">
                    <span className="contacts-text42">Address*</span>
                    <input type="text" placeholder="Your Address" id="address" className="form-control contacts-textinput04 input"/>
                  </div>
                  <div className="contacts-container29">
                    <span className="contacts-text43">Message*</span>
                    <textarea placeholder="Tell us about your needs!" id="msg" className="form-control contacts-textinput05 input"></textarea>
                  </div>
                </div>
              </div>
              <button type='submit' disabled={this.state.isSubmitting} className="contacts-register button" onClick={(e) => {this.postSubscription(); e.preventDefault()}}>Submit</button>
              </form>
            </div>

            <div className="contacts-container30 col-6">
            	<Maps />
            </div>
          </div>
      </section>

	    <section className="contacts-section4">
          <div className="contacts-container42">
            <span className="contacts-text50"><span>Contact</span></span>
            <h2 className="contacts-text52 fw-bold"><span>{ (this.props.storeData !== null) ? this.props.storeData.our_branch.split('|')[0] : "" }</span></h2>
            <span className="contacts-text54 text-black-50">
            { this.customeLineBreak(this.state.our_branch.desc) }
            </span>
            <a href="mailto:info@nexa.net.id"><button className="contacts-register2 button">Contact Us</button></a>
          </div>
      </section>

  	</>
      )
  }
}