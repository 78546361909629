import React, {Component} from 'react';
import { Shimmer } from 'react-shimmer';

export default class Content extends Component {
    constructor(props){
      super(props)
      this.state = {
        header : {
          height: "56px"
        }
      }
    }

    componentDidMount() {
      this.didMountHeaderHeight = setInterval(
        () => this.setHeaderHeight(),
        1000
        );

    }

    componentWillUnmount() {
      clearInterval(this.didMountHeaderHeight);
    }

    setHeaderHeight = () => {
      this.setState({
        header : {
          height: document.getElementById('__mainHeader').clientHeight + "px"
        }
      })
    }
    render(){
        return (
      	<section>
          <section className="supportpagearea-section2 pt-3 pb-0" style={{"marginTop": this.state.header.height}}>
              <div className="supportpagearea-container1">
                <span className="supportpagearea-text">Areas</span>
                <h1 className="supportpagearea-text1 fw-bold fs-36">Nexa’s Coverage</h1>
                <span className="supportpagearea-text2">
                { this.props.storeData !== null ? this.props.storeData : <Shimmer width={"20rem"} height={"4rem"} />}
                </span>
                
              </div>
          </section>
      	</section>
        )
    }
}