import React, {Component} from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Shimmer } from 'react-shimmer';
import { detectDevice } from '../../../controller';

let isCompatible = detectDevice()


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }} className="pe-0 ps-0">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ScrollableTabsButtonAuto({storeData}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <div className="row container" style={{"maxWidth":"30rem"}}>
  <style jsx="true">
  {`
  	.nexadata-features {
    flex: 0 0 auto;
    display: flex;
    grid-gap: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
	}
  	#__nexaBlue .MuiButtonBase-root.MuiTab-root.Mui-selected {
		color: #ffffff;
	    width: auto;
	    padding-left:2rem;
	    padding-right:2.1rem;
	    font-size: 16px;
	    text-align: center;
	    transition: 0.3s;
	    background-image: linear-gradient(270deg, #0d6efd 29.00%,#0d6efd 99.00%) !important;
	    border-radius: 10px;
	}
	#__nexaDataTabs .MuiButtonBase-root.MuiTab-root, #__nexaBlue .MuiButtonBase-root.MuiTab-root {
	    min-height: 36px;
	    width: auto;
	    padding: 0.5rem;
	    padding-left:1rem;
	    padding-right:1rem;
	    margin: 0px;
	    font-size: 16px;
	    font-weight: 340;
	    background-color: #fff;
	    color: var(--dl-color-gray-700);
	}

  `}
  </style>
    <Box className="">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className=" m-0 textCapitalize d-flex justify-content-center mobileData"
        aria-label="scrollable auto tabs example" id="__nexaBlue" style={{ "minHeight": "auto"}}>
          <Tab label="Colocation" {...a11yProps(0)} className="nexadata-button1 button" />
          <Tab label="Hosting Solution" {...a11yProps(1)} className="nexadata-button1 button" />
      </Tabs>
      
    </Box>
    	<TabPanel className="p-0" value={value} index={0}>
        { 
          <Card className="border-0 bg-none">
          	<Card.Body>
	          	<Card.Title className="nexadata-text15 text-primary">
	          		{ storeData !== null ? storeData.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}
	          	</Card.Title>
	          	<Card.Img loading='lazy' alt='Nexa Collect' variant="top" src={isCompatible ? "https://minio.nexa.net.id/nexa/collect.webp" : "https://minio.nexa.net.id/nexa/collect.png"} />
	          	<Card.Text className="nexadata-text17">
              { storeData !== null ? storeData.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} />}
	          	</Card.Text>
          	</Card.Body>
          </Card>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
      	{ 
          <Card className="border-0 bg-none">
          	<Card.Body className="pe-0 ps-0">
	          	<Card.Title className="nexadata-text15 text-primary">
	          		{ storeData !== null ? storeData.split('|')[2] : <Shimmer width={"20rem"} height={"4rem"} />}
	          	</Card.Title>
	          	<Card.Img loading='lazy' alt='Nexa Hosting'  variant="top" src={isCompatible ? "https://minio.nexa.net.id/nexa/hosting.webp" : "https://minio.nexa.net.id/nexa/hosting.png"} />
	          	<Card.Text className="nexadata-text17">
	          		 { storeData !== null ? storeData.split('|')[3] : <Shimmer width={"20rem"} height={"4rem"} />}
	          	</Card.Text>
          	</Card.Body>
          </Card>
        }
      </TabPanel>
    </div>
  );
}

export default class Content extends Component {

    render(){
        return (
        <section>
        <section className="nexadata-container03">
          <div className="nexadata-container04">
            <span className="nexadata-text13 text-primary">Nexa Data</span>
            <h2 className="nexadata-text14 fw-bold fs-32">Products &amp; Services</h2>
          </div>
          <div className="nexadata-container05">
            
            <ScrollableTabsButtonAuto storeData={this.props.storeData} />
            
          </div>
          
        </section>

        <section className="nexadata-section6">
          <div className="nexadata-container10">
            <span className="nexadata-text22 text-primary">Nexa Data</span>
            <h2 className="nexadata-text23 fw-bold fs-32">Service Features</h2>
          </div>
          <div className="container pe-4 ps-4">
            <div className="nexadata-container12 row" style={{"gridGap":"unset", "justifyContent":"unset"}}>
              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt='Icon SLA' src="https://minio.nexa.net.id/nexa/icon_sla_99_blue.png" className="nexadata-image"/>
                <span className="nexadata-text24">
                  <span>SLA 99,8%</span>
                  <br/>
                  <span></span>
                </span>
              </Col>
              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt="Icon NOC 24/7" src="https://minio.nexa.net.id/nexa/icon_noc_24-7.png" className="nexadata-image"/>
                <span className="nexadata-text28">
                  <span>NOC 24/7</span>
                  <br/>
                  <span></span>
                </span>
              </Col>
              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt="" src="https://minio.nexa.net.id/nexa/icon_dedicated.png" className="nexadata-image"/>
                <span className="nexadata-text32">
                  <span>Dedicated</span>
                  <br/>
                  <span>Internet</span>
                </span>
              </Col>
              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt="Nexa Quick Response Time" src="https://minio.nexa.net.id/nexa/icon_quick_response.png" className="nexadata-image"/>
                <span className="nexadata-text35">
                  <span>Quick</span>
                  <br/>
                  <span>Response Time</span>
                </span>
              </Col>

              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt="Icon Secure Network" src="https://minio.nexa.net.id/nexa/icon_secure_network.png" className="nexadata-image"/>
                <span className="nexadata-text38">
                  <span>Secure</span>
                  <br/>
                  <span>Network</span>
                </span>
              </Col>

              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt="Icon High Reliability" src="https://minio.nexa.net.id/nexa/icon_high_reliability.png" className="nexadata-image"/>
                <span className="nexadata-text44">
                  <span>High</span>
                  <br/>
                  <span>Reliability</span>
                  <br/>
                </span>
              </Col>

              <Col xs={6} md={4} className="nexadata-features mt-4">
                <img loading="lazy" alt="Icon Scalability" src="https://minio.nexa.net.id/nexa/icon_fast_flexible.png" className="nexadata-image"/>
                <span className="nexadata-text49">
                  <span>Fast &amp; Flexible</span>
                  <br/>
                  <span>Scalability</span>
                  <br/>
                  <span>as needed</span>
                </span>
              </Col>

            </div>
          </div>
        </section>
        </section>
        )
    }
}