import React, {Component} from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { bigTv as mediaQuery } from "./MediaQueries";
import '../assets/splide-sea-green.min.css';
export default class Banner extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  mainBannerPrev = () => {
    document.querySelector(".banner-prev").click();
  };
  mainBannerNext = () => {
    document.querySelector(".banner-next").click();
  };
  handleSlidePrev = () => {
    document.querySelector(".news-prev").click();
  };

  handleSlideNext = () => {
    document.querySelector(".news-next").click();
  };

  directHelpdesk = () => {
    window.open("http://wa.gmedia.id/hd", "_blank");
  };

  createSlider = () => {
    console.log("IMAGE URL", this.props.url_image);
    if (this.props.slider !== true) {
      return (
        // <div className="bannerandmenu-banner">
        //   <div className={(mediaQuery) ? "bannerandmenu-maxwith w-100" : "bannerandmenu-maxwith"} style={this.props.style}>
        //     {this.props.title}
        //     <span className={(mediaQuery) ? "bannerandmenu-text1 fs-24 w-100" : "bannerandmenu-text1 fs-24"} style={this.props.styleText}>
        //       <React.Fragment>{this.props.desc}</React.Fragment>
        //     </span>
        //   </div>
        //   {/*<img alt="" src={img} className="bannerandmenu-image" style={{"height":"unset"}}/>*/}
        //   <div className="bannerandmenu-image">
        //   	<Image alt="" fallback={<Shimmer width={1366} height={800} />} src={img} />
        //   </div>
        // </div>
        <section
          className={
            mediaQuery ? "bannerandmenu-banner h-auto" : "bannerandmenu-banner"
          }
        >
          <div
            className={
              mediaQuery
                ? "bannerandmenu-maxwith w-100"
                : "bannerandmenu-maxwith"
            }
            style={this.props.style}
          >
            {this.props.title}
            <span
              className={
                mediaQuery
                  ? "bannerandmenu-text1 fs-24 w-100"
                  : "bannerandmenu-text1 fs-24"
              }
              style={this.props.styleText}
            >
              <React.Fragment>{this.props.desc}</React.Fragment>
            </span>
          </div>
          <div
            style={{
              width: " 100vw",
              maxHeight: "1114px",
              objectFit: "cover",
              objectPosition: "1000px",
            }}
          >
            <img
              style={{
                width: "100vw",
                objectFit: "cover",
              }}
              alt=""
              src={this.props.url_image}
            />
          </div>
          {/* <div className="bannerandmenu-image"> */}
          {/* <img
              alt={`Nexa ${document.title}`}
              fallback={<Shimmer width={1366} height={800} />}
              src={this.props.url_image}
            /> */}
          {/* <LazyLoadImage
              src={this.props.url_image}
              alt={`Nexa ${document.title}`}
            />
          </div> */}
        </section>
      );
    }

    // console.log('this.props.arrayData', this.props.arrayData)

    let cards = [];
    this.props.arrayData.forEach((row, index) => {
      cards.push(
        <SplideSlide key={index} className="p-0" data-splide-interval="3000">
          <div
            className={
              mediaQuery
                ? "bannerandmenu-banner h-auto"
                : "bannerandmenu-banner"
            }
          >
            <div
              className={
                mediaQuery
                  ? "bannerandmenu-maxwith w-100"
                  : "bannerandmenu-maxwith"
              }
              style={this.props.style}
            >
              {row.title}
              <span
                className={
                  mediaQuery
                    ? "bannerandmenu-text1 fs-24 w-100"
                    : "bannerandmenu-text1 fs-24"
                }
              >
                <React.Fragment>{row.desc}</React.Fragment>
              </span>
            </div>
            <img
              loading="lazy"
              alt={row.title}
              src={row.url_image}
              className="bannerandmenu-image"
            />
          </div>
        </SplideSlide>
      );
    });

    return (
      <Splide
        className="p-0"
        id="__bannerSlider"
        data-splide-interval="0"
        options={{
          type: "loop",
          rewind: true,
          perPage: 1,
          perMove: 1,
          autoplay: true,
          pauseOnHover: false,
          resetProgress: false,
        }}
        aria-label="My Favorite Images"
      >
        <div className="splide__progress">
          <div className="splide__progress__bar" />
        </div>
        {cards}

        <div className="splide__arrows splide__arrows--ltr d-none">
          <button
            className="splide__arrow splide__arrow--prev banner-prev"
            type="button"
            aria-label="Previous slide"
            aria-controls="splide02-track"
          >
            Prev
          </button>

          <button
            className="splide__arrow splide__arrow--next banner-next"
            type="button"
            aria-label="Go to first slide"
            aria-controls="splide02-track"
          >
            Next
          </button>
        </div>
      </Splide>
    );
  };

  loadSingleBanner = () => {
    return (
      <div className="bannerandmenu-banner">
        <div className="bannerandmenu-maxwith" style={this.props.style}>
          {this.props.title}
          <span className="bannerandmenu-text1">
            <p>{this.props.desc}</p>
          </span>
        </div>
        <img
          loading="lazy"
          alt="Main Banner"
          src="https://minio.nexa.net.id/nexa/nexa-homepage-background-1500w.webp"
          className="bannerandmenu-image"
        />
      </div>
    );
  };

  render() {
    return <React.Fragment>{this.createSlider()}</React.Fragment>;
  }
}