// import { Splide } from '@splidejs/react-splide';
import React, {Component, useState} from 'react';
import { Shimmer } from 'react-shimmer';
import AccordingCollapse from '../../../components/AccordingCollapse'
import { LineBreak, DetailCareer } from '../../../controller/index'


export default class Content extends Component {
	constructor(props) {
		super(props);
		this.state = {data: '', id: 0, obj:[]};
	}

	findRoute = () => {
		let content = document.querySelector('.nexasolution-section3')
			content.scrollIntoView()
	}

	getData = (id) => {
			let hasil = []
	
			DetailCareer(id).then((data) => {
				data = data.response
			
				//remove OBJ yang tidak penting
	
				delete data[0]['id']
				delete data[0]['insert_at']
				delete data[0]['insert_by']
				delete data[0]['update_at']
				delete data[0]['update_by']
				delete data[0]['flag']
				delete data[0]['kode']
				delete data[0]['id_bahasa']
				delete data[0]['kategori']
				
	
				let bahasa = sessionStorage.getItem('lang')
				//Assign New Obj Key Name
				
				let arr_key = ["Position", "Job Description", "Experience", "Skill", "Qualification", 
					"Allowance", "Incentive", "Working Hours", "Location"]
	
				if(bahasa == 2){
					arr_key = ["Posisi", "Deskripsi Pekerjaan", "Pengalaman", "Keterampilan", "Kualifikasi",
					"Tunjangan", "Insentif", "Jam Kerja", "Lokasi"]
				}
	
				delete Object.assign(data[0], {[arr_key[0]]: data[0]["position"] })["position"]
				delete Object.assign(data[0], {[arr_key[1]]: data[0]["job_desc"] })["job_desc"]
				delete Object.assign(data[0], {[arr_key[2]]: data[0]["experience"] })["experience"]
				delete Object.assign(data[0], {[arr_key[3]]: data[0]["skill"] })["skill"]
				delete Object.assign(data[0], {[arr_key[4]]: data[0]["qualification"] })["qualification"]
				delete Object.assign(data[0], {[arr_key[5]]: data[0]["allowance"] })["allowance"]
				delete Object.assign(data[0], {[arr_key[6]]: data[0]["insentif"] })["insentif"]
				delete Object.assign(data[0], {[arr_key[7]]: data[0]["work_time"] })["work_time"]
				delete Object.assign(data[0], {[arr_key[8]]: data[0]["location"] })["location"]
				
	
				hasil = data.map((data) => {
					let arr = []
					let keys = Object.keys(data)
					keys.forEach(element => {
						arr.push({"title": element, "desc": data[element]})
					});
					return arr
				})
				// console.log(hasil)
				// hasil = JSON.stringify(hasil)
				this.setState({obj: hasil[0]}, this.findRoute())

			}).catch(err => {
				console.log(err)
			})
	}

	sendJob = (e) => {
		
		this.setState({
			data: e.currentTarget.getAttribute('data'),
			id: e.currentTarget.getAttribute('dataid')

		}, this.getData(e.currentTarget.getAttribute('dataid')))
	}

	createElementCircle = () => {
      return (
        <>
        &nbsp;&nbsp;
            <svg height="10" width="10">
              <circle cx="2.5" cy="2.5" r="2" fill="black" />
              Sorry, your browser does not support inline SVG.  
            </svg>
        &nbsp;&nbsp;
        </>
      )
    }

    render(){

		let ListCareer = () => {
			let res_data = []
			let card = []

			if(this.props.storeDataCareer != null){
				res_data = this.props.storeDataCareer
			}else{
				res_data = []
			}

			//Grouping Berdasarkan Kode job
			const groupedMap = res_data.reduce(
				(entryMap, e) => entryMap.set(e.kategori, [...entryMap.get(e.kategori)||[], e]),
				new Map()
			);
			let keyName = Array.from( groupedMap.keys()) //Get nama key
			
			keyName.forEach(element => {

				card.push(
						<div className="career-container05">
							<div className="career-container06">
							<h2 className="career-text025">{element}</h2>
								{groupedMap.get(element).map(data => {
									// console.log(data.position)
									return (
										
										<div className="career-container07">
											<svg viewBox="0 0 1024 1024" className="career-icon">
											<path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
											</svg>
											<a href="#" data={data.position} dataid={data.id} onClick={this.sendJob}><span className="career-text026">{data.position}</span></a>
										</div>
									)
								})}
								
							</div>
						</div>
				)
			});
			
			return card

		}

	    return (

		<section>

			<section className="career-section2">
	           <div className="career-container04">
	         	<span className="career-text017">{sessionStorage.getItem('lang') === "1" ? "Career" : "Karir"}</span>
	             <h2 className="career-text018 fw-bold">{sessionStorage.getItem('lang') === "1" ? "Job Vacancy" : "Lowongan"}</h2>
	            <span className="career-text019">
	              { (this.props.storeData !== null) ? LineBreak(this.props.storeData.lowongan_tersedia) : <Shimmer width={"20rem"} height={"4rem"} />}
	            </span>
				{ListCareer()}
	           </div>
			</section>

			{(this.state.id === 0) ? '' :  <AccordingCollapse dataFromParent={this.state.data} sub_title="Career" title="Job Vacancy" addClassName={'bg-white'} data={this.state.obj} />}

		<section className="contacts-section4">
			<div className="contacts-container42">
				<span className="contacts-text50">
					<span>{sessionStorage.getItem('lang') === "1" ? "Contact" : "Kontak"}</span>
				</span>
				<h2 className="contacts-text52 fw-bold">
					<span>{this.props.careerText !== undefined ? this.props.careerText.branchTitle : ""}</span>
					{/* {console.log(this.props.careerText)} */}
				</h2>
				<span className="contacts-text54 text-black-50">
					JAKARTA {this.createElementCircle()}
					SEMARANG {this.createElementCircle()}
					YOGYAKARTA {this.createElementCircle()}
					SOLO {this.createElementCircle()}
					SALATIGA {this.createElementCircle()}
					MAGELANG
					<br/>
					<br/>
					PURWOKERTO {this.createElementCircle()} 
					SURABAYA {this.createElementCircle()} 
					BALI {this.createElementCircle()} 
					MATARAM
				</span>
			</div>
		</section>

		<section className="career-section4" style={{ backgroundImage: `url("https://minio.nexa.net.id/nexa/nexa-footer-background-1500w.png")` }}>
			<div className="career-container85">
			<span className="career-text103"><span>Career</span></span>
			<h2 className="career-text105 fw-bold">
			<span>PT. Internet Mulia Untuk Negeri</span>
			</h2>
			<span className="career-text107">
			<span>
				Jl. Jangli Dalam No 29 J, Candisari, Semarang Selatan,
			</span>
			<br/>
			<span>Semarang 50254 | Email : rekrutmen.smg@nexa.net.id</span>
			</span>
			<a href="mailto:rekrutmen.smg@nexa.net.id"><button className="career-register3 button">Contact Us</button></a>
			</div>
		</section>
			
		</section>

		/**
		 * Referensi code lama
		 * 
		 * 	// <div>
		//     <div className="career-section2">
	    //       <div className="career-container04">
	    //         <span className="career-text017">Career</span>
	    //         <h1 className="career-text018 fw-bold">Job Vacancy</h1>
	    //         <span className="career-text019">
	    //           { (this.props.storeData !== null) ? LineBreak(this.props.storeData.lowongan_tersedia) : 'Fetching Data Loading...'}
	    //         </span>
	    //       </div>
		//   <div className="career-container05">
		//     <div className="career-container06">
		//       <h1 className="career-text025">Sales</h1>
		//       <div className="career-container07">
		//         <svg viewBox="0 0 1024 1024" className="career-icon">
		//           <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
		//         </svg>
		//         <a href="#sales_executive" data="Sales Executive" onClick={this.sendJob}><span className="career-text026">Sales Executive</span></a>
		//       </div>
		//       <div className="career-container07">
		//         <svg viewBox="0 0 1024 1024" className="career-icon002">
		//           <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
		//         </svg>
		//         <a href="#sales_admin" data="Sales Admin" onClick={this.sendJob}><span className="career-text027">Sales Admin</span></a>
		//       </div>
		//     </div>
	    //         <div className="career-container09">
	    //           <h1 className="career-text028">Network</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon004">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#ipcore" data="IP Core NOC" onClick={this.sendJob}><span className="career-text029">IP Core NOC</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon006">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#helpdesk" data="Help Desk NOC" onClick={this.sendJob}><span className="career-text030">Help Desk NOC</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon008">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#tech" data="Tech Support" onClick={this.sendJob}><span className="career-text031">Tech Support</span></a>
	    //           </div>
	    //         </div>
	    //         <div className="career-container13">
	    //           <h1 className="career-text032">Programmer</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon010">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#mobile" data="Mobile Programmer" onClick={this.sendJob}><span className="career-text033">Mobile Programmer</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon012">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#web" data="Web Programmer" onClick={this.sendJob}><span className="career-text034">Web Programmer</span></a>
	    //           </div>
	    //         </div>
	    //       </div>
	    //       <div className="career-container16 mt-4">
	    //         <div className="career-container17">
	    //           <h1 className="career-text035">Multimedia</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon014">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#producer" data="Associate Producer" onClick={this.sendJob}><span className="career-text036">Associate Producer</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon016">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#editor" data="Video Editor" onClick={this.sendJob}><span className="career-text037">Video Editor</span></a>
	    //           </div>
	    //         </div>
	    //         <div className="career-container20">
	    //           <h1 className="career-text038">Customer Relation</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon018">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#ro" data="Relationship Officer" onClick={this.sendJob}><span className="career-text039">
	    //               Customer Relationship Officer
	    //             </span></a>
	    //           </div>
	    //         </div>
	    //         <div className="career-container22">
	    //           <h1 className="career-text040">Data Center</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon020">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#facility" data="Facility Engineer" onClick={this.sendJob}><span className="career-text041">Facility Engineer</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon022">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#enginmanager" data="Facility Engineer Manager" onClick={this.sendJob}><span className="career-text042">Facility Engineer Manager</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon024">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#enginsuper" data="Facility Engineer Supervisor" onClick={this.sendJob}><span className="career-text043">Facility Engineer Supervisor</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon026">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#enginhead" data="Head of Facility Engineer" onClick={this.sendJob}><span className="career-text044">Head of Facility Engineer</span></a>
	    //           </div>
	    //         </div>
	    //       </div>
	    //       <div className="career-container27 mt-4">
	    //         <div className="career-container28">
	    //           <h1 className="career-text045">General Affair</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon028">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#legal" data="Legal Executive" onClick={this.sendJob}><span className="career-text046">Legal Executive</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon030">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#training" data="Training" onClick={this.sendJob}><span className="career-text047">Training</span></a>
	    //           </div>
	    //         </div>
	    //         <div className="career-container31">
	    //           <h1 className="career-text048">Marketing</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon032">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#account" data="Account Executive E-sports" onClick={this.sendJob}><span className="career-text049">Account Executive E-sports</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon034">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#brand" data="Brand Executive" onClick={this.sendJob}><span className="career-text050">Brand Executive</span></a>
	    //           </div>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon036">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#designs" data="Design Creative" onClick={this.sendJob}><span className="career-text051">Design Creative</span></a>
	    //           </div>
	    //         </div>
	    //         <div className="career-container35">
	    //           <h1 className="career-text052">Operation</h1>
	    //           <div className="career-container07">
	    //             <svg viewBox="0 0 1024 1024" className="career-icon038">
	    //               <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
	    //             </svg>
	    //             <a href="#Logistic" data="Logistic Staff" onClick={this.sendJob}><span className="career-text053">Logistic Staff</span></a>
	    //           </div>
	    //         </div>
	    //       </div>
	    //     </div>

	    //     <AccordingCollapse dataFromParent={this.state.data} sub_title="Career" title="Job Vacancy" addClassName={'bg-white'} data={this.getData()} />

	    //     <div className="contacts-section4">
		//         <div className="contacts-container42">
		//         	<span className="contacts-text50">
		//         		<span>Contact</span>
		//         	</span>
		//         	<h1 className="contacts-text52 fw-bold">
		//         		<span>Our Branch</span>
		//         	</h1>
		//         	<span className="contacts-text54 text-black-50">
		//         		JAKARTA {this.createElementCircle()}
		//         		YOGYAKARTA {this.createElementCircle()}
		//         		SOLO {this.createElementCircle()}
		//         		SALATIGA {this.createElementCircle()}
		//         		MAGELANG
		//         		<br/>
		//         		<br/>
		//         		PURWOKERTO {this.createElementCircle()} 
		//         		SURABAYA {this.createElementCircle()} 
		//         		BALI {this.createElementCircle()} 
		//         		MATARAM
		//         	</span>
		//         </div>
	    //     </div>

		//     <div className="career-section4" style={{ backgroundImage: `url("https://minio.nexa.net.id/nexa/nexa-footer-background-1500w.png")` }}>
	    //       <div className="career-container85">
	    //         <span className="career-text103"><span>Career</span></span>
	    //         <h1 className="career-text105 fw-bold">
	    //           <span>PT. Internet Mulia Untuk Negeri</span>
	    //         </h1>
	    //         <span className="career-text107">
	    //           <span>
	    //             Jl. Jangli Dalam No 29 J, Candisari, Semarang Selatan,
	    //           </span>
	    //           <br/>
	    //           <span>Semarang 50254 | Email : rekrutmen.smg@nexa.net.id</span>
	    //         </span>
	    //         <a href="mailto:rekrutmen.smg@nexa.net.id"><button className="career-register3 button">Contact Us</button></a>
	    //       </div>
	    //     </div>
        // </div>
        
		 */


        )
    }
}