import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

export default class OtherSolutions extends Component {
  loopOtherSolution = () => {
    let aCards = []
    let fetchData = [
      {
        'title': 'fetch data...',
        'url_image': 'https://via.placehold er.com/100x100?text=Fetch',
        'url_action': '/sdfdf'
      },
      {
        'title': 'fetch data...',
        'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
        'url_action': '/sdfdf'
      },
      {
        'title': 'fetch data...',
        'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
        'url_action': '/sdfdf'
      },
      {
        'title': 'fetch data...',
        'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
        'url_action': '/sdfdf'
      },
      {
        'title': 'fetch data...',
        'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
        'url_action': '/sdfdf'
      }
      ]
  
      let res_data = [
      {
        'title': 'Goverment',
        'url_image': 'https://minio.nexa.net.id/nexa/icon_gov.png',
        'url_action': '/goverment'
      },
      {
        'title': 'Education',
        'url_image': 'https://minio.nexa.net.id/nexa/icon_edu.png',
        'url_action': '/education'
      },
      {
        'title': 'Hospitally',
        'url_image': 'https://minio.nexa.net.id/nexa/icon_hos.png',
        'url_action': '/hospitality'
      },
      {
        'title': 'Manufacture',
        'url_image': 'https://minio.nexa.net.id/nexa/icon_manu.png',
        'url_action': '/manufacture'
      },
      {
        'title': 'Corporate',
        'url_image': 'https://minio.nexa.net.id/nexa/icon_corp.png',
        'url_action': '/corporate'
      }
      ]

    if (this.props.storeData === null || typeof this.props.storeData === 'undefined') {
      fetchData.forEach((row, index) => {
        aCards.push(
          <NavLink to={row.url_action} key={index} className="solutioneducation-container14 row">
              <img loading='lazy' alt={`Nexa - ${row.title}`} src={row.url_image} className="solutioneducation-image05 p-2"/>
              <span className="solutioneducation-text39">
                <span>{row.title}</span>
              </span>
            </NavLink>
          )
      })
    } else {
      res_data.map((row, idx) => row.title = this.props.storeData.replaceAll(' ', '').split('|')[idx] )

      res_data.forEach((row, index) => {
        aCards.push(
          <NavLink to={row.url_action} key={index} className="solutioneducation-container14 row">
              <img loading='lazy' alt={`Nexa - ${row.title}`} src={row.url_image} className="solutioneducation-image04 p-2"/>
              <span className="solutioneducation-text39">
                <span>{row.title}</span>
              </span>
            </NavLink>
          )
      })
    }
    
    return aCards;
  }
  render(){
      return (
      <section>
        <section className="solutioneducation-container11">
          <div className="solutioneducation-container12">
            <span className="solutioneducation-text37">{sessionStorage.getItem('lang') === "1" ? "Solution" : "Solusi"}</span>
            <h2 className="solutioneducation-text38">{sessionStorage.getItem('lang') === "1" ? "Other Solution" : "Solusi Lain"}</h2>
          </div>
          <div className="solutioneducation-container13">
            {this.loopOtherSolution()}
          </div>
        </section>
      </section>
      )
  }
}