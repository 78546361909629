import React, { Component } from 'react'
import { Shimmer } from 'react-shimmer';
import Maps from '../../../components/Maps';

export default class Content extends Component {
    render(){
        return (
      	<section>
      	  <section className="supportpagearea-section2">
            <div className="supportpagearea-container1">
              <span className="supportpagearea-text">Areas</span>
              <h1 className="supportpagearea-text1 fw-bold">Nexa’s Coverage</h1>
              <span className="supportpagearea-text2">
                { this.props.storeData !== null ? this.props.storeData : <Shimmer width={"20rem"} height={"4rem"} />}
              </span>
              
                <Maps />
              
            </div>
          </section>
      	</section>
        )
    }
}