import axios from 'axios';
import React from 'react';
import ReactGoogleAnalytic from "react-ga";
import { browserName, browserVersion } from "react-device-detect";

const api_base_url = "https://admin.nexagroup.id/";
//const api_base_url = "http://192.168.20.27/web-admin-nexagroup/";
const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const headers_token = {
  "Content-Type": "application/json",
  "Auth-Key": "nexa",
};

const ReactGA = (category = "", action = "") => {
  ReactGoogleAnalytic.initialize("UA-239075827-1");
  ReactGoogleAnalytic.event({
    category: category,
    action: action,
  });
};

const generateString = (length) => {
  let result = " ";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

const formatTanggal = (paramDate, id) => {
  let date = new Date(paramDate);
  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let tanggal = date.getDate();
  // let hari = date.getDay();

  let bulanIndonesia = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  let bulanEnglish = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (parseInt(id) === 2) {
    bulan = bulanIndonesia[bulan];
  } else {
    bulan = bulanEnglish[bulan];
  }
  return `${tanggal} ${bulan} ${tahun}`;
};
const formatTitle = (getData) => {
  let newText = "";
  if (typeof getData !== "undefined" || getData !== "") {
    let makeArr = getData.split(" ");
    let setLength = Math.floor(makeArr.length / 2);

    newText = makeArr.map((row, idx) =>
      idx === setLength ? (
        <React.Fragment key={row}>
          <br />
          {row.concat(" ")}
        </React.Fragment>
      ) : (
        <React.Fragment key={idx}>{row.concat(" ")}</React.Fragment>
      )
    );
    return newText;
  } else {
    return "Fetch loading...";
  }
};

const CreateToken = async () => {
  headers_token["Unique-Char"] = "JAVX";

  let config = {
    method: "post",
    url: api_base_url + "api/token_auth/create_token",
    headers: headers_token,
  };

  await axios(config)
    .then((response) => {
      let res_data = response.data;
      if (
        sessionStorage.getItem("token") !== "" ||
        sessionStorage.getItem("token") !== null
      )
        sessionStorage.removeItem("token");
      if (
        sessionStorage.getItem("unique") !== "" ||
        sessionStorage.getItem("unique") !== null
      )
        sessionStorage.removeItem("unique");
      if (res_data.metadata.status !== 200) {
      } else {
        //decode token
        sessionStorage.setItem("unique", btoa("JAVX"));
        sessionStorage.setItem("token", btoa(res_data.response.token));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

const DefaultLanguage = (idLang) => {
  sessionStorage.setItem("lang", idLang);
};

const GetLangStatus = async () => {
  const id = await sessionStorage.getItem("lang");
  if (id === "1") {
    return {
      id: id,
      name: "Indonesia",
    };
  } else {
    return {
      id: id,
      name: "English",
    };
  }
};

const setImgDownload = () => {
  let bahasa = sessionStorage.getItem("lang");

  let isCompatible = detectDevice();

  if (parseInt(bahasa) === 1) {
    return isCompatible
      ? "https://minio.nexa.net.id/nexa/iklan_english_sm.webp"
      : "https://minio.nexa.net.id/nexa/iklan_english_sm.png";
  } else {
    return isCompatible
      ? "https://minio.nexa.net.id/nexa/iklan_indo_sm.webp"
      : "https://minio.nexa.net.id/nexa/iklan_indo_sm.png";
  }
};

const setImgDownloadMobil = () => {
  let bahasa = sessionStorage.getItem("lang");

  if (parseInt(bahasa) === 1) {
    return "https://minio.nexa.net.id/nexa/iklan_engris_mobil.png";
  } else {
    return "https://minio.nexa.net.id/nexa/iklan_indo_mobil.png";
  }
};

const getLinkDownloadIos = async () => {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    os: "ios",
  });

  let config = {
    method: "post",
    url: api_base_url + "api/version/version",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
};

const getLinkDownloadAndroid = async () => {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    os: "android",
  });

  let config = {
    method: "post",
    url: api_base_url + "api/version/version",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
};

async function AboutUs() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/company/about_us",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function CareerPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/company/career",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function ListCareer() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));
  let data = JSON.stringify({
    status: 1,
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/career/list_career",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    // console.log(result)
    return result;
  } catch (err) {
    return err;
  }
}

async function DetailCareer(id) {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));
  let data = JSON.stringify({
    status: 1,
    id_bahasa: sessionStorage.getItem("lang"),
    id: id,
  });

  let config = {
    method: "post",
    url: api_base_url + "api/career/list_career",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    // console.log(result)
    return result;
  } catch (err) {
    return err;
  }
}

async function ClientPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/company/clients",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function ContactPage() {
  headers_token["Token"] = await atob(sessionStorage.getItem("token"));
  // console.log('headers_token', headers_token['Token'])

  let data = JSON.stringify({
    id_bahasa:
      sessionStorage.getItem("lang") === null
        ? sessionStorage.setItem("lang", 2)
        : sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/company/contact",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function PrivacyPolicyPage() {
  headers_token["Token"] = await atob(sessionStorage.getItem("token"));
  // console.log('headers_token', headers_token['Token'])

  let data = JSON.stringify({
    id_bahasa:
      sessionStorage.getItem("lang") === null
        ? sessionStorage.setItem("lang", 2)
        : sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/company/privacy_policy",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function CorporatePage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/solution/corporate",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function EducationPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/solution/education",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function GovermentPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/solution/goverment",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function HospitallyPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/solution/hospitally",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function ManufacturePage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/solution/manufacture",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

//##

async function SubscribePost(paramsData) {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify(paramsData);

  let config = {
    method: "post",
    url: api_base_url + "api/support_subcribe/subcribe",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function NexatelPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexatel",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function InternetServicesPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexatel_internet",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function ItsPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexatel_itsupport",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function InfrastructurePage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexatel_network_infra",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function NexaappsPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexa_apps",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function NexadataPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexa_data",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function NexadigitalPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexa_digital",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function NexasolutionPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/service/nexa_solution",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function SupportPage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/support/area",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function getArticle() {
  // let token = atob(sessionStorage.getItem('token'))
  // // console.log(token)

  // if(token != atob(sessionStorage.getItem('token'))){
  // 	headers_token['Token'] = atob(sessionStorage.getItem('token'))
  // }else{
  // 	headers_token['Token'] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1bmlxdWVfY2hhciI6IlBVS0kiLCJleHAiOiIyMDIyLTEwLTA2IDA5OjM3OjMzIn0.kyCTP8ANflIuht0-FYBK9Wd7uTK64M-YLvnG01xRDiE"
  // }

  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let lang =
    sessionStorage.getItem("lang") !== null
      ? sessionStorage.getItem("lang")
      : "2";

  let data = JSON.stringify({
    id_bahasa: lang,
    status: "1",
  });

  let config = {
    method: "post",
    url: api_base_url + "api/news/list_news",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

async function SubscribePage() {
  headers_token["Token"] = atob(sessionStorage.getItem("token"));

  let data = JSON.stringify({
    id_bahasa: sessionStorage.getItem("lang"),
  });

  let config = {
    method: "post",
    url: api_base_url + "api/support/subscribe",
    headers: headers_token,
    data: data,
  };

  try {
    const res = await axios(config);
    const result = res.data;
    return result;
  } catch (err) {
    return err;
  }
}

const homeHelper = async () => {
  let bahasa = sessionStorage.getItem("lang");

  if (parseInt(bahasa) === 1) {
    return {
      bannerDesc: "We are not only a provider but also friend in all issues",
      supportTitle: "Support",
      supportDesc: "We always happy to help",
      kontakTitle: "Contact Us",
      kontakDesc:
        "Call our number because we are here, and always ready to help you.",
      subscribeTitle: "Subscribe Now",
      subscribeDesc:
        "Find out more on how we can help you about your subscription plan.",
      downloadTitle: "Download Now",
      whyNexaTitle: "Why Nexa?",
      whyNexaDesc1: "Providing IT solutions for network",
      whyNexaDesc2: "problems that occurs to customers.",
      whyNexaCard1:
        "Always provide utmost service for our customer satisfaction.",
      whyNexaCard2: "“Connected Life” experience to your business",
      whyNexaCard3: "Helps to get the most out of your technology",
      titlePortofolio: "Our Valued Customers",
    };
  } else {
    return {
      bannerDesc:
        "Kami tidak hanya penyedia tetapi juga teman dalam semua masalah",
      supportTitle: "Bantuan",
      supportDesc: "Kami selalu senang membantu",
      kontakTitle: "Hubungi Kami",
      kontakDesc:
        "Hubungi nomor kami karena kami ada, dan selalu siap membantu Anda.",
      subscribeTitle: "Berlangganan Sekarang",
      subscribeDesc:
        "Cari tahu informasi lebih lanjut tentang bagaimana kami dapat membantu Anda tentang rencana berlangganan.",
      downloadTitle: "Download Sekarang",
      whyNexaTitle: "Why Nexa?",
      whyNexaDesc1: "Memberikan solusi IT untuk masalah jaringan",
      whyNexaDesc2: "yang terjadi pada pelanggan.",
      whyNexaCard1: "Selalu memberikan layanan terbaik untuk kepuasan Anda",
      whyNexaCard2: "Sistem yang terintegrasi untuk bisnis Anda",
      whyNexaCard3: "Membantu mendapatkan hasil maksimal dari teknologi Anda",
      titlePortofolio: "Our Valued Customers",
    };
  }
};

const detectDevice = () => {
  let isCompatible = 1;

  if (browserName === "Safari" || "Mobile Safari") {
    if (parseInt(browserVersion) < 14) {
      isCompatible = 0;
    }
  }
  // }else{

  // }
  // webpS().then(support => {
  // 	if(support){
  // 		isCompatible = 1
  // 	}else{
  // 		isCompatible = 0
  // 	}
  // })

  return isCompatible;
};

// async function checkWebP(callback) {
//     var webP = new Image();
//     webP.onload = webP.onerror = function () {
//         callback(webP.height == 2);
//     };
//     webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
// };

// async function supportsWebp() {
// 	// if (createImageBitmap) return false;
	
// 	const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
// 	const blob = await fetch(webpData).then(r => r.blob());
// 	return createImageBitmap(blob).then(() => true, () => false);
//   }

// const detectDevice = async () => {

// 	let dataT = await supportsWebp()
	
// 	return dataT

// }



const stripHtml = (str) => {
	
		if ((str===null) || (str===''))
			return false;
		else
			str = str.toString();
			  
		// Regular expression to identify HTML tags in 
		// the input string. Replacing the identified 
		// HTML tag with a null string.
		return str.replace( /(<([^>]+)>)/ig, '').toString();
	
}

const LineBreak = (text) => {
	const newText = text.split('<br/>').map(str => <React.Fragment key={str}>{str}<br/></React.Fragment>)
	return newText;
}

export {
	detectDevice,
	formatTanggal,
	formatTitle,
	DefaultLanguage, 
	GetLangStatus, 
	LineBreak, 
	CreateToken, 
	AboutUs, 
	CareerPage,
	DetailCareer,
	ListCareer,
	ClientPage ,
	ContactPage,
  PrivacyPolicyPage,

	CorporatePage,
	EducationPage,
	GovermentPage,
	HospitallyPage,
	ManufacturePage,
	SubscribePost,

	NexatelPage,
	InternetServicesPage,
	ItsPage,
	InfrastructurePage,

	NexaappsPage,
	NexadataPage,
	NexadigitalPage,
	NexasolutionPage,

	SupportPage,
	SubscribePage,
	getArticle,
	ReactGA,

	setImgDownload,
	getLinkDownloadAndroid,
	getLinkDownloadIos,
	setImgDownloadMobil,
	homeHelper,
	stripHtml
	
}

