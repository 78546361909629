import React, {Component} from 'react';
import {deviceDetect} from 'react-device-detect'
	
export default class Management extends Component {
	loopingData = () => {
		let El = []
		let res_data = [
			{
				'name': 'Priyo Suyono',
				'job': 'President Director',
				'profile': 'https://minio.nexa.net.id/nexa/pak_priyo.png',
				'style':{"width":"90%", height:"auto"}
			},
			{
				'name': 'Adhi Darminto',
				'job': 'Director',
				'profile': 'https://minio.nexa.net.id/nexa/pak_adhi.png',
				'style':{"width":"90%", height:"auto"}
			},
			// {
			// 	'name': 'Sianne Ho',
			// 	'job': 'Finance and Accounting Manager',
			// 	'profile': './assets/images/about/women.png'
			// },
			// // {
			// // 	'name': 'Atalia Citra',
			// // 	'job': 'Marketing and Communication Coordinator',
			// // 	'profile': './assets/images/about/women.png'
			// // },
			// {
			// 	'name': 'Onnie Kurnia',
			// 	'job': 'Customer Experience Assistant Manager',
			// 	'profile': './assets/images/about/women.png'
			// },
			// {
			// 	'name': 'Bayu Pringgandani',
			// 	'job': 'Sales Coordinator',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Jatmiko Ari Wibowo',
			// 	'job': 'Sales (Gov-Edu) Team Leader',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Apit Priono',
			// 	'job': 'Technical, Infrastructure, and Helpdesk Manager',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Wijanarko Nugroho',
			// 	'job': 'NOC IP Core Assistant Manager',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Sidik Pramono',
			// 	'job': 'Technical (Salatiga Branch) Assistant Manager',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Indra Permana',
			// 	'job': 'Helpdesk Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Khamim Budi',
			// 	'job': 'Base Station and Base Station Support Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Rahmat Nur Setiyanto',
			// 	'job': 'Delivery Control Service Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Argyan Chandra',
			// 	'job': 'Access Support Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Dika Afif',
			// 	'job': 'Operation Support Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Edy Kristanto',
			// 	'job': 'Application and Content Provider Manager',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Indra Maulana',
			// 	'job': 'Application and Features Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Dwi Aji Prabowo',
			// 	'job': 'Application Core and Quality Supervisor',
			// 	'profile': './assets/images/about/people.png'
			// },
			// {
			// 	'name': 'Anne Margaretha',
			// 	'job': 'Human Resource, General Affair, and Legal Manager',
			// 	'profile': './assets/images/about/women.png'
			// },
			{
				'name': 'Budiyanto',
				'job': 'Technical Director',
				'profile': 'https://minio.nexa.net.id/nexa/pak_budi.png',
				'style' : {"width":"100%", height:"auto"}
			},
		]
		res_data.forEach((row, index) => {
			El.push(
				<div key={index} className="col-md-4">
					<div className="card card-management-web mb-4">
						<img loading='lazy' alt={row.name} src="./assets/images/about/bg.jpeg" className="bg-management" />
						<div className="w-100 card-hover"
							onMouseEnter={e => {
								document.getElementById('__imgHover_'+index).classList.add('img-hover')
								document.getElementById('__cardHover_'+index).classList.add('bg-hover')
								document.querySelector('#__cardHover_'+index + ' .fw-bold.mb-0.fs-16').classList.remove('bannerandmenu-text1')
							}}
							onMouseLeave={e => {
								document.getElementById('__imgHover_'+index).classList.remove('img-hover')
								document.getElementById('__cardHover_'+index).classList.remove('bg-hover')
								document.querySelector('#__cardHover_'+index + ' .fw-bold.mb-0.fs-16').classList.add('bannerandmenu-text1')
							}}
							style={{"position":"absolute", "bottom":"0", "height":"100%","borderRadius":"10px"}}>
							<img loading='lazy' alt={row.name} src={row.profile} id={"__imgHover_"+index} className="img-profile" style={row.style} />
							<div className="card-body bg-white w-100 card-body-hover" id={"__cardHover_"+index} style={{"position":"absolute","bottom":"0","zIndex":"1","height":"110px"}}>
							<span className="bannerandmenu-text1 fw-bold mb-0 fs-16">{row.name}</span>
								<div className="card-footer bg-unset p-0 pt-2 mt-2 pb-2" style={{"lineHeight":"1.3"}}>
								{row.job}
								</div>
							</div>
						</div>
						
					</div>
				</div>
			)
		})
		return El;
	}
    render(){
        return (
			
        	<>
			<style jsx="true">
        	{`
        		.card.card-management-web {
        			height: 30rem	;
        		}
        	`}
        	</style>
        	<section className="home-section6 mt-5 mb-5 mx-auto" style={{"height": "auto"}}>
	          	<div className="home-container31 p-0 pt-5 pb-5 row">
		          	<div className="nexatel-container18">
		          		<span className="nexatel-text40">Company</span>
		          		<h1 className="nexatel-text41 fw-bold">Management</h1>
		          		<div className="row p-0 mt-5 me-5 ms-5">
			          		{this.loopingData()}
		          		</div>
		          	</div>
	          	</div>
	        </section>
        	</>
        )
    }
}