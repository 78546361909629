import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {detectDevice} from "../controller/index"

let isCompatible = detectDevice();

let base_url_portofolio = './assets/images/portofolio/';
let res_data = [
  {
    'title': 'Logistic',
    'images': [
    {
      'title': 'Dakota',
      'url': base_url_portofolio + 'Logistic/dakota.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'DHL',
      'url': base_url_portofolio + 'Logistic/dhl.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Expeditors',
      'url': base_url_portofolio + 'Logistic/expeditors.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'JNE',
      'url': base_url_portofolio + 'Logistic/jne.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'J&T',
      'url': base_url_portofolio + 'Logistic/jnt.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'FMCG',
    'images': [
    {
      'title': 'Aice',
      'url': base_url_portofolio + 'FMCG/aice.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Cimory',
      'url': base_url_portofolio + 'FMCG/cimory.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Indofood',
      'url': base_url_portofolio + 'FMCG/indofood.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Orang Tua',
      'url': base_url_portofolio + 'FMCG/orang_tua.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'So Good',
      'url': base_url_portofolio + 'FMCG/so_good.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Manufacture',
    'images': [
    {
      'title': 'Hino',
      'url': base_url_portofolio + 'Manufacture/hino.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sami',
      'url': base_url_portofolio + 'Manufacture/sami.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sri Boga',
      'url': base_url_portofolio + 'Manufacture/sriboga.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Government',
    'images': [
    {
      'title': 'Akpol',
      'url': base_url_portofolio + 'Goverment/akpol.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Angkasa Pura',
      'url': base_url_portofolio + 'Goverment/angkasa_pura.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'BKKBN',
      'url': base_url_portofolio + 'Goverment/bkkbn.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'BPJS Kesehatan',
      'url': base_url_portofolio + 'Goverment/bpjs_kesehatan.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'BPJS Ketenagakerjaan',
      'url': base_url_portofolio + 'Goverment/bpjs_ketenagakerjaan.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'BPOM',
      'url': base_url_portofolio + 'Goverment/BPOM.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Kelautan',
      'url': base_url_portofolio + 'Goverment/kelautan.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Kementrian Agama',
      'url': base_url_portofolio + 'Goverment/kementrian_agama.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Perhutani',
      'url': base_url_portofolio + 'Goverment/perhutani.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'PUPR',
      'url': base_url_portofolio + 'Goverment/pupr.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Healthcare',
    'images': [
    {
      'title': 'Hermina',
      'url': base_url_portofolio + 'Healthcare/hermnina.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Jec',
      'url': base_url_portofolio + 'Healthcare/jec.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'KMN',
      'url': base_url_portofolio + 'Healthcare/kmn.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Hospitality',
    'images': [
    {
      'title': 'Accorhotel',
      'url': base_url_portofolio + 'Hospitality/accorhotel.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Archipelago',
      'url': base_url_portofolio + 'Hospitality/archipelago.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Artotel',
      'url': base_url_portofolio + 'Hospitality/artotel.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'DHM',
      'url': base_url_portofolio + 'Hospitality/dhm.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Horison',
      'url': base_url_portofolio + 'Hospitality/horison.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'IHG',
      'url': base_url_portofolio + 'Hospitality/ihg.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Louis Kienne',
      'url': base_url_portofolio + 'Hospitality/lois_kienne.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Mesastila',
      'url': base_url_portofolio + 'Hospitality/mesastila.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Santika',
      'url': base_url_portofolio + 'Hospitality/santika.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Swiss Belhotel',
      'url': base_url_portofolio + 'Hospitality/swiss_belhotel.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Tentrem',
      'url': base_url_portofolio + 'Hospitality/tentrem.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Corporate',
    'images': [
    {
      'title': 'Carfix',
      'url': base_url_portofolio + 'Corporate/carfix.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Digimap',
      'url': base_url_portofolio + 'Corporate/digimap.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Gramedia',
      'url': base_url_portofolio + 'Corporate/gramedia.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Nasmoco',
      'url': base_url_portofolio + 'Corporate/nasmoco.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Optik Tunggal',
      'url': base_url_portofolio + 'Corporate/optik_tunggal.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Timezone',
      'url': base_url_portofolio + 'Corporate/timezone.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'XXI',
      'url': base_url_portofolio + 'Corporate/xxi.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Finance',
    'images': [
    {
      'title': 'BRI',
      'url': base_url_portofolio + 'Finance/bri.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'LIPPO',
      'url': base_url_portofolio + 'Finance/lipo.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'DBS',
      'url': base_url_portofolio + 'Finance/dbs.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'FIF',
      'url': base_url_portofolio + 'Finance/fif.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Nasari',
      'url': base_url_portofolio + 'Finance/nasari.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Restu Group',
      'url': base_url_portofolio + 'Finance/restu_group.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Cosmetics and Household',
    'images': [
    {
      'title': 'AJBS',
      'url': base_url_portofolio + 'Cosmetics/ajbs.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Farmer Market',
      'url': base_url_portofolio + 'Cosmetics/farmer_market.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Herborist',
      'url': base_url_portofolio + 'Cosmetics/herboist.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Matahari',
      'url': base_url_portofolio + 'Cosmetics/matahari.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Miniso',
      'url': base_url_portofolio + 'Cosmetics/miniso.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Natasha',
      'url': base_url_portofolio + 'Cosmetics/natasha.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Toms',
      'url': base_url_portofolio + 'Cosmetics/toms.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Vitalis',
      'url': base_url_portofolio + 'Cosmetics/vitalis.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'You',
      'url': base_url_portofolio + 'Cosmetics/you.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Food and Beverages',
    'images': [
    {
      'title': 'Excelso',
      'url': base_url_portofolio + 'Food/exelxo.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Janji Jiwa',
      'url': base_url_portofolio + 'Food/janji_jiwa.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Starbucks',
      'url': base_url_portofolio + 'Food/starback.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Tanamera',
      'url': base_url_portofolio + 'Food/tanamera.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Building Construction',
    'images': [
    {
      'title': 'Adhi Karya',
      'url': base_url_portofolio + 'Building/adhi_karya.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Varia Beton',
      'url': base_url_portofolio + 'Building/varia_beton.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Electronics',
    'images': [
    {
      'title': 'Arisa',
      'url': base_url_portofolio + 'Electronics/arisa.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Infrastructure, Utility, and Transportation',
    'images': [
    {
      'title': 'Siba Surya',
      'url': base_url_portofolio + 'Infrastructure/siba_surya.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Tower Bersama',
      'url': base_url_portofolio + 'Infrastructure/tower_bersama.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Industrial Park',
    'images': [
    {
      'title': 'KIC',
      'url': base_url_portofolio + 'Industrial/kic.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'KIK',
      'url': base_url_portofolio + 'Industrial/kik.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'KIW',
      'url': base_url_portofolio + 'Industrial/kiw.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Textile and Garment',
    'images': [
    {
      'title': 'Apacinti',
      'url': base_url_portofolio + 'Textile/apacinti.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Busana Apparel',
      'url': base_url_portofolio + 'Textile/busana_apparel.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Mas',
      'url': base_url_portofolio + 'Textile/mas.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sai Group',
      'url': base_url_portofolio + 'Textile/sai_group.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Property and Real Estate',
    'images': [
    {
      'title': 'Ciputra',
      'url': base_url_portofolio + 'Property/citra.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Paramount',
      'url': base_url_portofolio + 'Property/paramount.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Pollux',
      'url': base_url_portofolio + 'Property/pollux.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sinarmas',
      'url': base_url_portofolio + 'Property/sinarmas.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Pharmaceuticals',
    'images': [
    {
      'title': 'Erela',
      'url': base_url_portofolio + 'Pharmaceuticals/erla.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Erlimpex',
      'url': base_url_portofolio + 'Pharmaceuticals/erlimpex.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sampharindo',
      'url': base_url_portofolio + 'Pharmaceuticals/samparindo.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sido Muncul',
      'url': base_url_portofolio + 'Pharmaceuticals/sido_muncul.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  },
  {
    'title': 'Media News',
    'images': [
    {
      'title': 'Elshinta',
      'url': base_url_portofolio + 'Media/elshinta.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Jateng Pos',
      'url': base_url_portofolio + 'Media/jatengpos.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'RMOL',
      'url': base_url_portofolio + 'Media/rmol.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'RRI',
      'url': base_url_portofolio + 'Media/rri.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Sonora',
      'url': base_url_portofolio + 'Media/sonora.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Suara Merdeka',
      'url': base_url_portofolio + 'Media/suara_merdeka.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Trax',
      'url': base_url_portofolio + 'Media/trax.png',
      'style': {
        "height":"120px"
      }
    },
    {
      'title': 'Tribun Jateng',
      'url': base_url_portofolio + 'Media/tribun_jateng.png',
      'style': {
        "height":"120px"
      }
    }
    ]
  }
]

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function ScrollableTabsButtonAuto(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <>
    {/* <Box sx={{ maxWidth: { xs: 320, sm: 1000 }, bgcolor: 'none' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
          {
            res_data.map((row, idx) => {
              return (
              <Tab label={row.title} {...a11yProps(idx)} key={idx} className="client-button button custom-tabs" style={{"textTransform":"capitalize"}} />
              )
            })
          }
      </Tabs>
      
        {
          res_data.map((row, idx) => {
            return (
            <TabPanel key={idx} value={value} index={idx}>
            <div className="client-container06">
                <div className="client-container07 row">
                {
                  row.images.map((res, index) => {
                    return (
                          <div key={index} className="row client-image mb-5 me-0 ms-0 justify-content-center">
                            <img alt="" src={res.url} className="client-image" style={res.style}/>
                            <span className="client-image text-center">{res.title}</span>
                          </div>
                    )
                  })
                }
              </div>
            </div>
            </TabPanel>
            )
          })
        }
    </Box> */}

    <div className='text-center m-auto' style={{padding:"2%"}}>
        <img alt='Nexa Clients' loading='lazy' src={isCompatible ? "https://minio.nexa.net.id/nexa/portofolio.webp" : "https://minio.nexa.net.id/nexa/portofolio.png"} className="w-100"></img>
    </div>
      
    </>
  );
}


