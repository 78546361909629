import React, {Component} from 'react';
import '../../assets/companyabout.css';
import $ from 'jquery'

export default class Interested extends Component {
  constructor(props){
    super(props)

    if (typeof this.props.storeData !== 'undefined') {
      this.state = {
        title: (this.props.storeData !== null) ?  this.props.storeData.judul : null,
        desc: (this.props.storeData !== null) ?  this.props.storeData.desc : null,
      }
    } else {
      this.state = {
        title: this.getTitle(),
        desc: this.getDesc()
      }
    }
  }

  componentDidMount(){
    $("#__mFlag").on('change', function(){
      this.state = {
        title: this.getTitle(),
        desc: this.getDesc()
      }
    })
  }

  getTitle = () => {

    if(parseInt(sessionStorage.getItem('lang')) === 1){
      return 'Interested to Try'
    }else{
      return "Tertarik untuk mencoba"
    }
    
  }
  getDesc = () => {
    if(parseInt(sessionStorage.getItem('lang')) === 1){
      return <span>For new customers, you can find out <br/> more by contacting us.</span>
    }else{
      return <span>Untuk customer baru, Anda bisa mengetahui selengkapnya dengan menghubungi kami.</span>
    }
    
  }
  render(){
      return (
        <div className="companyabout-container11" style={{backgroundImage: `url("https://minio.nexa.net.id/nexa/nexa-footer-background-1500w.png")` }}>
          <div className="companyabout-container12">
            <h1 className="companyabout-text61 fw-bold text-center">
              { (this.state.title !== null) ? this.state.title : this.getTitle() }
            </h1>
            <span className="companyabout-text64">
            { (this.state.desc !== null) ? this.state.desc : this.getDesc() }
            </span>
            <a target='_blank' href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"><button className="companyabout-register2 button">Contact Us</button></a>
          </div>
        </div>
      )
  }
}
