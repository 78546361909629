import React from 'react';
import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import Content 		from './Content';
import Footer 		from '../../components/Footer';
import Interested2 from '../../components/Interested2';
import '../../../assets/nexasolution.css';
import { NexadigitalPage, LineBreak, CreateToken, detectDevice, AboutUs } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice();

function Nexadigital() {
	const [result, setResult] = React.useState(null)
	const [interested, setInterested] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data) => {

			AboutUs().then((data) => {
				setInterested(data.response[0])
			})

			NexadigitalPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text fw-bold text-pink"><span>Nexa Digital</span></h1>;
	let eTitle				= "Nexa Digital"
	let sBanner_desc = <>{ result !== null ? LineBreak(result.tentang_1) : <Shimmer width={"20rem"} height={"4rem"} />}</>
	let eStyleBanner = {"width": "100%"}
	let eStyleBannerText = {"width": "65%"}

	// let sAfterBanner_title = <>Long story short, chill and let us do the rest.</>;
	// let sAfterBanner_desc = <>Social Media Management, Media Planning,
	// Photography, Videography, Whatsapp, Email & SMS
	// Broadcast Engine, Brand Identity, Graphic Designs,
	// Copywriting, OOH Advertising,<br/><br/>
	// and for more to be discovered.</>

	let sAfterBanner_title = <div>{ result !== null ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />}</div>;
	let sAfterBanner_desc = result !== null ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} />

	let desc = result !== null ? result.tentang_3 : ""
	
	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_digital.webp" : "https://minio.nexa.net.id/nexa/banner_digital.jpeg"

	

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			{/* <Banner title={eBanner} desc={sBanner_desc} style={eStyleBanner} styleText={eStyleBannerText} url_image={mainBannerPage} /> */}
			<Banner title={eBanner} style={eStyleBanner} styleText={eStyleBannerText} url_image={mainBannerPage} />
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			{/* <Content /> */}
			{ result === null && <Content storeData={null} />}
			{ result !== null && <Content storeData={result.product_services}/>}
			{ (interested == null) && <Interested2 storeData={null} /> }
			{ (interested !== null) && <Interested2 storeData={interested} /> }
		</div>
		);
}

export default Nexadigital;
