import Footer from '../../components/Footer';
import Content from './Content';
// import Banner from '../../components/Banner';

import  React from 'react'
import { ContactPage, CreateToken } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

function Contact() {
  const [result, setResult] = React.useState(null)
  const langs = useSelector(state => state.langs);
  const [statusLang] = React.useState(langs[0].idLang)

  let langCondition = (statusLang !== langs[0].idLang)

  React.useEffect(() => {
    CreateToken().then((data) => {
      ContactPage().then((data) => {
        setResult(data.response[0]) 
      }).catch((err) => {
        setResult(err)
      })
    })
  }, [langCondition]);

  // console.log('Contact', result)

  let eTitle              = result ? result.contact_us.split('|')[0] : "Kontak Kami"
  let sAfterBanner_title  = result ? result.contact_us.split('|')[1] : "Ini Deskripsi"


  return (
  	<>
    <Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				{/* <meta property="og:image" content={mainBannerPage}></meta> */}
			</Helmet>
  	<Content storeData={result} />
  	</>
  )
}

export default Contact;