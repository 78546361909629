import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Shimmer } from "react-shimmer";
import { stripHtml } from "../../../controller";

export default class Content extends Component {
  formatTanggal = (paramDate, id) => {
    let date = new Date(paramDate);
    let tahun = date.getFullYear();
    let bulan = date.getMonth();
    let tanggal = date.getDate();
    // let hari = date.getDay();

    let bulanIndonesia = [
      "Januari",
      "Februari",
      "Maret",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Agustus",
      "September",
      "Oktober",
      "November",
      "Desember",
    ];

    let bulanEnglish = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (parseInt(id) === 2) {
      bulan = bulanIndonesia[bulan];
    } else {
      bulan = bulanEnglish[bulan];
    }
    return `${tanggal} ${bulan} ${tahun}`;
  };

  formatDesc = (text) => {
    console.log("aaa", text);
  };
  render() {
    const createCard = () => {
      let cards = [],
        res_data = [],
        sBackground = [
          "https://images.unsplash.com/photo-1573164713712-03790a178651?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE5fHxzZXJ2ZXJ8ZW58MHx8fHwxNjUyODQ1OTIz&ixlib=rb-1.2.1&w=400",
          "https://images.unsplash.com/photo-1553481187-be93c21490a9?crop=entropy&cs=tinysrgb&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fGdhbWV8ZW58MHx8fHwxNjUyOTQ1MzUx&ixlib=rb-1.2.1&q=80&raw_url=true&w=400",
          "https://images.unsplash.com/photo-1564457461758-8ff96e439e83?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHNlcnZlcnxlbnwwfHx8fDE2NTI4NDU5MjM&ixlib=rb-1.2.1&w=400",
        ];
      for (let i = 1; i <= 6; i++) {
        res_data.push({
          id: i,
          kategori: "ENTERTAINMENT " + i,
          url_image:
            sBackground[Math.floor(Math.random() * sBackground.length)],
          title: "AI is Applicable in Helping your Business Level of Sales",
          desc: "In a recent report titled ‘AI Guided Selling, Gartner found that the COVID-19 pandemic has caught many sales off guard.",
          date: "1" + i + " October 202" + i,
        });
      }
      console.log("aaa", this.props.storeData);

      if (this.props.storeData !== null) {
        res_data = this.props.storeData;
      } else {
        res_data = [];
      }

      res_data.forEach((response, index) => {
        cards.push(
          <NavLink
            to={"/news_detail?id=" + response.kode}
            key={response.title}
            className="news-container04 p-0 mb-5"
            style={{ marginRight: "6.2vw" }}
          >
            <article className="news-bg-card">
              <div className="newscard-image" style={{ height: "235px" }}>
                <img
                  loading="lazy"
                  alt={`Nexa News - ${response.title}`}
                  src={response.image}
                  className="h-100"
                  style={{ objectFit: "cover" }}
                />
                <div className="newscard-container1">
                  <span className="newscard-text">{response.label}</span>
                </div>
              </div>
              <div className="newscard-content">
                <h2 className="newscard-text1">
                  <span>{response.title}</span>
                </h2>
                <span className="newscard-conten">
                  <span>
                    {stripHtml(response.deskripsi)
                      .substr(0, 200)
                      .split(" ")
                      .slice(0, -1)
                      .join(" ")}
                    ...
                  </span>
                </span>
              </div>
              <div style={{ "margin-bottom": "10px", "margin-left": "2rem" }}>
                <span className="newscard-text2">
                  <span>
                    {this.formatTanggal(response.tanggal, response.id_bahasa)}
                  </span>
                </span>
              </div>
            </article>
          </NavLink>
        );
      });

      return cards;
    };

    return (
      <section>
        <article className="news-section2">
          <div
            className="news-maxwith"
            style={{
              backgroundImage: `url("${
                this.props.storeData !== null
                  ? this.props.storeData[0].image
                  : "https://via.placeholder.com/480x480"
              }")`,
            }}
          ></div>
          <div className="news-maxwith1">
            <NavLink
              to={
                this.props.storeData !== null
                  ? `/news_detail?id=${this.props.storeData[0].kode}`
                  : "/news"
              }
            >
              <h2 className="pb-3">
                {this.props.storeData !== null ? (
                  this.props.storeData[0].title
                ) : (
                  <Shimmer width={"20rem"} height={"4rem"} />
                )}
              </h2>
              <span className="news-text01">
                {this.props.storeData !== null ? (
                  stripHtml(this.props.storeData[0].deskripsi)
                    .substr(0, 500)
                    .split(" ")
                    .slice(0, -1)
                    .join(" ") + " ...."
                ) : (
                  <Shimmer width={"20rem"} height={"4rem"} />
                )}
              </span>
              <div className="news-container01 pt-3">
                <span className="news-text07">
                  {this.props.storeData !== null ? (
                    this.props.storeData[0].label
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </span>
                <div className="news-container02"></div>
                <span>
                  {this.props.text !== undefined
                    ? this.props.text.textPost
                    : ""}{" "}
                  {this.props.storeData !== null ? (
                    this.formatTanggal(
                      this.props.storeData[0].tanggal,
                      this.props.storeData[0].id_bahasa
                    )
                  ) : (
                    <Shimmer width={"20rem"} height={"4rem"} />
                  )}
                </span>
              </div>
            </NavLink>
          </div>
        </article>

        <section className="news-section3">
          <div className="news-container03 row">{createCard()}</div>
          {/* <button className="news-register button">Load More</button> */}
        </section>
      </section>
    );
  }
}
