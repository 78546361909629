import React, {Component, Fragment} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { formatTanggal, stripHtml } from '../../../controller/index'
import { NavLink } from 'react-router-dom';

export default class Content extends Component {
    render(){
    	const createCard = () => {
    		let cards = [], res_data =[],
    			sBackground = [
    				'https://images.unsplash.com/photo-1573164713712-03790a178651?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE5fHxzZXJ2ZXJ8ZW58MHx8fHwxNjUyODQ1OTIz&ixlib=rb-1.2.1&w=400',
    				'https://images.unsplash.com/photo-1553481187-be93c21490a9?crop=entropy&cs=tinysrgb&fm=jpg&ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDF8fGdhbWV8ZW58MHx8fHwxNjUyOTQ1MzUx&ixlib=rb-1.2.1&q=80&raw_url=true&w=400',
    				'https://images.unsplash.com/photo-1564457461758-8ff96e439e83?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDh8fHNlcnZlcnxlbnwwfHx8fDE2NTI4NDU5MjM&ixlib=rb-1.2.1&w=400'
    			]
    		for (let i = 1; i <= 6; i ++){
    			res_data.push({
    				'id': i,
    				'id_bahasa': 1,
    				'label': 'ENTERTAINMENT '+ i,
    				'image': sBackground[Math.floor(Math.random() * sBackground.length)],
    				'title': 'AI is Applicable in Helping your Business Level of Sales',
    				'deskripsi': 'In a recent report titled ‘AI Guided Selling, Gartner found that the COVID-19 pandemic has caught many sales off guard.',
    				'tanggal': '2022-12-12'
    			})
	    	}

	    	if (this.props.storeData !== null) {
	    		res_data = this.props.storeData
	    	} else {
	    		res_data = []
	    	}

	    	res_data.forEach((response, index) => {
	    		cards.push(
					<article>
		    		<Col xs={12} md={6} key={index} className="mb-3">
			          <NavLink to={"/news_detail?id="+response.kode} key={response.title}>
			          <Card className="card-column">
			          	<Card.Img loading='lazy' alt={`Nexa News - ${response.title}`} src={response.image} variant="top" className="w-100" style={{objectFit:"cover"}}/>
			          	<div className="news-container06 position-absolute" style={{"top":"10.5rem", "left":"20px"}}>
			          		<span className="news-text09">{response.label}</span>
			          	</div>
			          	<Card.Body className="news-container07">
				          	<h2 className="news-text10">
				              {response.title}
				            </h2>
				            <span className="news-text13 max-line-text-article">
				            {stripHtml(response.deskripsi.substr(0, 128))}
				            </span>
				            <span className="news-text18 ">{formatTanggal(response.tanggal, response.id_bahasa)}</span>
			          	</Card.Body>
			          </Card>
			          </NavLink>
			        </Col>
					</article>
	    		)
	    	})

	    	 
	    	return cards

    	}

        return (
        <Fragment>
        <style jsx="true">
        {`
        	.card-img-top {
        		height: 210px;
        		width: auto !important;
        	}
        	.popular-article {
        		top: -2rem;
        		border-radius:10px;
        		box-shadow: 5px 5px 16px 0px rgb(0 0 0 / 16%);
        	}
        	.article-container {
        		flex: 0 0 auto;
			    width: 100%;
			    display: flex;
			    max-width: 1366px;
        	}
        	.card-column {
        		box-shadow: 5px 5px 16px 0px rgb(0 0 0 / 12%);
        		border-radius: 10px;
        	}
        `}
        </style>
	        {/* <Container className="pe-3 ps-3">
	        	<Card className="popular-article border-0">
	        		<Card.Img variant="top" src={ this.props.storeData !== null ? this.props.storeData[0].image : 'https://via.placeholder.com/480x480' } />
	        		<Card.Title className="pe-4 ps-4 pt-4 pb-0" style={{marginBottom:0}}>
	        			<h2 style={{fontSize:"1.2rem"}}>{ this.props.storeData !== null ? this.props.storeData[0].title : <Shimmer  width={"inherit"} height={"5rem"}}</h2>
	        		</Card.Title>
	        		<Card.Body className="news-text01 pe-4 ps-4 pt-4 pb-0" style={{fontSize: "1rem"}}>
	        		{ this.props.storeData !== null ? this.props.storeData[0].deskripsi.substr(0, 150) : <Shimmer  width={"inherit"} height={"5rem"} }
	        		</Card.Body>
	        		<Card.Footer className="news-container01 bg-none border-0 p-4">
	        			<div className="news-text07">{ this.props.storeData !== null ? this.props.storeData[0].label : <Shimmer  width={"inherit"} height={"5rem"}}</div>
	        			<div className="news-container02"></div>
	        			<div>Posted { this.props.storeData !== null ? formatTanggal(this.props.storeData[0].tanggal, this.props.storeData[0].id_bahasa) : <Shimmer  width={"inherit"} height={"5rem"}}</div>
	        		</Card.Footer>
	        	</Card>
	        </Container> */}
	        <section>
	          		<Row className="pe-3 ps-3 ">
	          			{createCard()}
	          		</Row>
	          		{/* <div className="pe-3 ps-3 d-flex justify-content-center mb-5">
	          			<button className="news-register button">Load More</button>
	          		</div> */}
	        </section>
        </Fragment>
        )
    }
}