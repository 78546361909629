import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Content from './Content';
import '../../../assets/supportpagearea.css';

import  React from 'react'
import { SupportPage, LineBreak, CreateToken } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet';

function Areas() {

  //Google Analitik
  ReactGA.event({category:"user", action:"Support Area"})
	

  const [result, setResult] = React.useState(null)
  const langs = useSelector(state => state.langs);
  const [statusLang] = React.useState(langs[0].idLang)

  let langCondition = (statusLang !== langs[0].idLang)

  React.useEffect(() => {
    CreateToken().then((data) => {
      SupportPage().then((data) => {
        setResult(data.response[0]) 
      }).catch((err) => {
        setResult(err)
      })
    })
  }, [langCondition]);

  let eTitle = "Nexa's Coverage"
  let desc = `Kini Nexa hadir di beberapa kota besar, untuk memenuhi kebutuhan pelanggan tercinta.
  Jika Anda memerlukan informasi lebih lanjut, silakan menghubungi nomor telepon di bawah ini,
  sesuai dengan lokasi tempat tinggal Anda.`
  let mainBannerPage = ""

  return (
    <div>
      
      <Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

      { result === null && <Content storeData={null} />}
      { result !== null && <Content storeData={LineBreak(result.tentang_1)} />}
      
    </div>
  );
}

export default Areas;
