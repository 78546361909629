import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="text-center py-5">
        <h1>Page Not Found 404</h1>
        <button onClick={goBack}>go back</button>
      </div>
      <Footer />
    </>
  );
}

export default NotFound;
