import { combineReducers } from 'redux';
const CHANGE_LANG = 'CHANGE_LANG';

export function onChangeLang(lang) {
  return {
    type: CHANGE_LANG,
    lang,
  }
}

const defaultLang = [
  {
  	id: 1,
    name: (parseInt(sessionStorage.getItem('lang')) === 1) ? 'English' : 'Indonesia',
    idLang: parseInt(sessionStorage.getItem('lang')),
  }
];

const defaultNexalevel_id = [
  {
      desc : 'Kami tidak hanya penyedia <br/>tetapi juga teman <br/>dalam semua masalah'
  }
]
const defaultNexalevel_eng = [
  {
      desc : 'We are not only a provider<br/> but also friend in all issues'
  }
]

const defaultArticleId = [{
  title: 'Berita|& Artikel',
  desc : 'Semakin banyak Anda membaca,<br/>semakin Anda tahu.'
}]
const defaultArticleEng = [{
  title: 'News|& Article',
  desc : 'The more you read,<br/>the more you know.'
}]

const defaultServicesEng = [{
  services: {
    tel: { desc : 'Internet for your business <br/>& every needs.' },
    app: { desc : 'Align with your goals! Website, Web App, Mobile App, and Development Agency.' },
    data: { desc : 'Provider that can deliver DCaaS and data center services can keep your future ready.' },
    digital: { desc : 'Social Media Management, Media Planning, Whatsapp, Email & SMS Broadcast Engine, Brand Identity, Graphic Designs.' },
    solution: { desc : 'Managed service with the latest developments & cost-effective solutions.' },
    conn: { desc : 'Connections More | Than Anything' },
  },
  portofolio: { desc : 'We are very fortunate and proud to form excellent partnerships with our customers. <br/> Nexa has been trusted to serve and reliable to help smooth working system our client.'},
  news: { title: 'News & Article|Latest Issue' }
}]
const defaultServicesId = [{
  services: {
    tel: { desc : 'Internet untuk bisnis & seluruh kebutuhan Anda.' },
    app: { desc : 'Selaraskan tujuan Anda bersama kami! Situs Web, Aplikasi Web, Aplikasi Seluler, dan Agensi Pengembangan.' },
    data: { desc : 'Membuat masa depan perusahaan Anda siap dengan layanan DCaaS dan data center.' },
    digital: { desc : 'Manajemen Media Sosial, Perencanaan Media, Whatsapp, Mesin Siaran Email & SMS, Identitas Merek, Desain Grafis.' },
    solution: { desc : 'Layanan terkelola dengan perkembangan terbaru & solusi hemat biaya.' },
    conn: { desc : 'Koneksi yang melampaui | segalanya.' },
  },
  portofolio: { desc: 'Kami sangat beruntung dan bangga membentuk kemitraan yang baik dengan pelanggan. <br/> Nexa telah dipercaya untuk melayani dan hadir untuk selalu membantu kelancaran sistem kerja mereka.'},
  news: { title: 'Berita & Artikel|Kabar terkini' }
}]

function langs(state=defaultLang, action) {
  switch (action.type) {
    case CHANGE_LANG:
    // const lang = state.find(b => action.lang === b.idLang);
    return [
	    {
	    	id: 1,
	    	name: (parseInt(action.lang) === 1) ? 'English' : 'Indonesia',
	    	idLang: parseInt(action.lang)
	    }
    ];
    default:
      return state;
  }
}

function homePage(state=((parseInt(sessionStorage.getItem('lang')) === 1) ? defaultNexalevel_eng : defaultNexalevel_id), action) {
  switch (action.lang) {
    case 2:
    return defaultNexalevel_id
    case 1:
    return defaultNexalevel_eng
    default:
      return state;
  }
}

function articlePage(state=((parseInt(sessionStorage.getItem('lang')) === 1) ? defaultArticleEng : defaultArticleId), action) {
  switch (action.lang) {
    case 2:
    return defaultArticleId
    case 1:
    return defaultArticleEng
    default:
      return state;
  }
}

function mainServices(state=((parseInt(sessionStorage.getItem('lang')) === 1) ? defaultServicesEng : defaultServicesId), action) {
  switch (action.lang) {
    case 2:
    return defaultServicesId
    case 1:
    return defaultServicesEng
    default:
      return state;
  }
}

const langApp = combineReducers({
  langs, homePage, articlePage, mainServices
});

export default langApp;