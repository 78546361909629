import React, { Component } from "react";
import Portofolio from "../../components/Portofolio";
import OurPartners from "../../components/OurPartners";
import YoutubeEmbed from "../../components/YoutubeEmbed";
import Container from "react-bootstrap/Container";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import { NavLink } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import {
  LineBreak,
  formatTanggal,
  stripHtml,
  detectDevice,
} from "../../../controller/index";
import { mobileScreen, iPadPro } from "../../../components/MediaQueries";

import '../../../assets/splide-sea-green.min.css';
import { fontSize } from "@mui/system";
import $ from "jquery";
import { Shimmer } from "react-shimmer";

let iNumber = 1;
let mediaQuery = window.matchMedia("(max-width: 414px)").matches;

export default class Content extends Component {
  constructor(props) {
    super(props);

    this.state = {
      total_data: document.querySelectorAll("#__newsSlider .splide__slide")
        .length,
    };
  }

  componentDidMount() {
    this.totalDataArticle = setInterval(() => this.setTotalArticle(), 1000);

    $(document).on("click", "li", function () {
      $("li").removeClass("active");
      $("ul").toggleClass("expanded");
      $(this).addClass("active");
      var tab_id = $(this).attr("data-tab");
      $(".tab-content").removeClass("current");
      $(this).addClass("current");
      $("#" + tab_id).addClass("current");
    });
  }

  componentWillUnmount() {
    clearInterval(this.totalDataArticle);
  }

  setTotalArticle() {
    this.setState({
      total_data: document.querySelectorAll("#__newsSlider .splide__slide")
        .length,
    });
  }

  handleSlidePrev = () => {
    let totalData = document.querySelectorAll(
      "#__newsSlider .splide__slide"
    ).length;
    document.querySelector(".numberSlide").innerHTML =
      (iNumber <= 1 ? 1 : (iNumber -= 1)) + " / " + totalData;
    document.querySelector(".news-prev").click();
  };

  handleSlideNext = async () => {
    let totalData = document.querySelectorAll(
      "#__newsSlider .splide__slide"
    ).length;
    document.querySelector(".numberSlide").innerHTML =
      (iNumber >= totalData ? totalData : (iNumber += 1)) + " / " + totalData;
    document.querySelector(".news-next").click();
  };

  handleArticlePrev = (e) => {
    let indexEl = parseInt(e.currentTarget.getAttribute("data"));
    document
      .querySelectorAll(
        "#__newsWhyNexa .splide__arrows .splide__arrow.splide__arrow--prev"
      )
      [indexEl].click();
  };

  handleArticleNext = (e) => {
    let indexEl = parseInt(e.currentTarget.getAttribute("data"));
    document
      .querySelectorAll(
        "#__newsWhyNexa .splide__arrows .splide__arrow.splide__arrow--next"
      )
      [indexEl].click();
  };

  directHelpdesk = () => {
    window.open("http://wa.gmedia.id/hd", "_blank");
  };

  handleListYoutube = () => {
    let cards = [];
    let res_data = [
      {
        url_video: "https://www.youtube.com/embed/pgt1plVRnmY",
        title: "Testimoni 1",
      },
      {
        url_video: "https://www.youtube.com/embed/JW1tPKh8deo",
        title: "Testimoni 2",
      },
      {
        url_video: "https://www.youtube.com/embed/49-VDRqCfaE",
        title: "Testimoni 3",
      },
    ];

    res_data.forEach((res, index) => {
      cards.push(
        <YoutubeEmbed
          embedId={res.url_video.replace("https://www.youtube.com/embed/", "")}
          key={index}
          heightNumber="500"
          classContainer="col-sm-6 pe-3 ps-3 pb-3"
        />
      );
    });

    return (
      <section>
        <div className="mt-5 row">{cards}</div>
      </section>
    );
  };

  createSliderNews = () => {
    let aCards = [];
    let res_data = [
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_0.png",
      },
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_1.png",
      },
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_2.png",
      },
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_2.png",
      },
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_2.png",
      },
      {
        title: "AI is Applicable in Helping your Business Level of Sales",
        deskripsi:
          "In a recent report titled ‘AI Guided Selling’, Gartner found that the COVID-19 pandemic has caught many sales off guard.​",
        tanggal: "12 October 2021",
        label: "ENTERTAIMENT",
        image: "./assets/images/news/news_2.png",
      },
    ];

    if (this.props.news !== null) {
      res_data = this.props.news;
    } else {
      res_data.forEach((row) => {
        row.image = <Shimmer width={"20rem"} height={"4rem"} />;
        row.title = "";
        row.deskripsi = row.label = "";
      });
    }

    res_data.forEach((row, index) => {
      aCards.push(
        <SplideSlide
          key={index}
          className="p-2"
          count={res_data.length}
          page={index + 1}
        >
          <NavLink to={"/news_detail?id=" + row.kode}>
            <div className="card shadow-md border-0">
              <div
                className="newscard-image w-100"
                style={
                  mobileScreen ? { maxHeight: "200px" } : { height: "auto" }
                }
              >
                <img
                  loading="lazy"
                  alt={`Nexa News - ${row.title}`}
                  src={row.image}
                  className="h-100"
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="newscard-container1"
                  style={{ bottom: "-10px" }}
                >
                  <span className="newscard-text">{row.label}</span>
                </div>
              </div>
              <div className="card-body newscard-content w-100">
                <h2 className="newscard-text1">
                  <span>{row.title}</span>
                </h2>

                <span className="newscard-conten">
                  <span>
                    {stripHtml(
                      row.deskripsi
                        .substr(0, 200)
                        .split(" ")
                        .slice(0, -1)
                        .join(" ")
                    )}
                    ...
                  </span>
                </span>

                <span className="newscard-text2">
                  <span>{formatTanggal(row.tanggal, row.id_bahasa)}</span>
                </span>
              </div>
            </div>
          </NavLink>
        </SplideSlide>
      );
    });

    return (
      <Splide
        className="p-0"
        id="__newsSlider"
        options={{
          perPage: 1,
          perMove: 1,
          gap: "4vw",
        }}
        aria-label="My Favorite Images"
      >
        {aCards}

        <div className="splide__arrows splide__arrows--ltr d-none">
          <button
            className="splide__arrow splide__arrow--prev news-prev"
            type="button"
            aria-label="Previous slide"
            aria-controls="splide02-track"
          ></button>

          <button
            className="splide__arrow splide__arrow--next news-next"
            type="button"
            aria-label="Go to first slide"
            aria-controls="splide02-track"
          ></button>
        </div>
      </Splide>
    );
  };

  createSliderArticle = () => {
    let cards = [],
      res_data = [
        {
          title: "Always provide utmost service for our customer satisfaction.",
          url_image: this.props.isCompatible
            ? "https://minio.nexa.net.id/nexa/why_3.webp"
            : "https://minio.nexa.net.id/nexa/why_3.jpeg",
        },
        {
          title: "“Connected Life” experience to your business",
          url_image: this.props.isCompatible
            ? "https://minio.nexa.net.id/nexa/why_2.webp"
            : "https://minio.nexa.net.id/nexa/why_2.jpeg",
        },
        {
          title: "Helps to get the most out of your technology",
          url_image: this.props.isCompatible
            ? "https://minio.nexa.net.id/nexa/why_1.webp"
            : "https://minio.nexa.net.id/nexa/why_1.jpeg",
        },
      ];

    res_data.forEach((res, index) => {
      cards.push(
        <SplideSlide
          key={index}
          className="p-2 app-component-container"
          style={{ width: "320px !important" }}
        >
          <div className="app-component-container1">
            <img
              loading="lazy"
              alt={`Nexa Cards - ${index}`}
              src={res.url_image}
              className="app-component-image"
            />
            <div className="app-component-text foreground-gradient ps-4 w-100">
              <span
                className="pb-3"
                style={{ bottom: "0", position: "absolute" }}
              >
                {res.title}
              </span>
            </div>
          </div>
        </SplideSlide>
      );
    });

    cards.push(
      <SplideSlide
        key="empty"
        className="p-2 app-component-container"
        style={{ width: "320px !important" }}
      >
        <div className="app-component-container1"></div>
      </SplideSlide>
    );

    return cards;
  };

  createSliderArticle2 = () => {
    let cards = [],
      res_data = [
        {
          title: this.props.support ? (
            this.props.support.whyNexaCard1
          ) : (
            <Shimmer />
          ),
          url_image: this.props.isCompatible
            ? "https://minio.nexa.net.id/nexa/why_3.webp"
            : "https://minio.nexa.net.id/nexa/why_3.jpeg",
        },
        {
          title: this.props.support ? (
            this.props.support.whyNexaCard2
          ) : (
            <Shimmer />
          ),
          url_image: this.props.isCompatible
            ? "https://minio.nexa.net.id/nexa/why_2.webp"
            : "https://minio.nexa.net.id/nexa/why_2.jpeg",
        },
        {
          title: this.props.support ? (
            this.props.support.whyNexaCard3
          ) : (
            <Shimmer />
          ),
          url_image: this.props.isCompatible
            ? "https://minio.nexa.net.id/nexa/why_1.webp"
            : "https://minio.nexa.net.id/nexa/why_1.jpeg",
        },
      ];

    res_data.forEach((res, index) => {
      cards.push(
        <div className="app-component-container1 mt-2 mb-4">
          <img
            loading="lazy"
            alt={`Nexa News - ${index}`}
            src={res.url_image}
            className="app-component-image"
            style={{ width: "108%" }}
          />
          <div className="app-component-text foreground-gradient ps-4 w-100">
            <span
              className="pb-3 pe-2"
              style={{ bottom: "0", position: "absolute" }}
            >
              {res.title}
            </span>
          </div>
        </div>
      );
    });

    // cards.push(<SplideSlide key="empty" className="p-2 app-component-container" style={{"width":"320px !important"}}>
    //               <div className="app-component-container1">
    //               </div>
    //     </SplideSlide>)

    return cards;
  };

  renderArticle = () => {
    return (
      <Splide
        className="p-0 home-container19 w-100"
        id="__newsWhyNexa"
        options={{
          rewind: true,
          perPage: 2,
          perMove: 1,
          gap: iPadPro ? "20vw" : "20vw",
        }}
        style={{}}
        aria-label="My Favorite Images"
      >
        {this.createSliderArticle2()}
      </Splide>
    );
  };

  renderSupport = () => {
    let data = {
      supportTitle: "Support",
      supportDesc: "We always happy to help",
      kontakTitle: "Contact Us",
      kontakDesc:
        "Call our number because we are here, and always ready to help you.",
      subscribeTitle: "Subscribe Now",
      subscribeDesc:
        "Find out more on how we can help you about your subscription plan.",
      downloadTitle: "Download Sekarang",
    };

    if (this.props.support !== null) {
      data = this.props.support;
    }

    return (
      <section>
        <Row className="mx-auto mb-3" style={{ maxWidth: "768px" }}>
          <Col md={4}>
            <Card className="border-0">
              <Card.Body
                className="pt-0"
                style={!mediaQuery ? { minHeight: "230px" } : null}
              >
                <div
                  className={
                    mediaQuery
                      ? "home-container41 mb-0 pb-2 w-auto justify-content-center"
                      : "home-container41 mb-0 pb-2 w-auto"
                  }
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="home-icon40 me-2"
                    style={{ width: "1.8rem" }}
                  >
                    <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
                  </svg>
                  <h2
                    className={
                      mediaQuery ? "home-text53 fs-2" : "home-text53 fs-5"
                    }
                    style={
                      mediaQuery ? { fontSize: "1.5rem" } : { fontSize: "2rem" }
                    }
                  >
                    {data.supportTitle}
                  </h2>
                </div>

                <a href="http://wa.gmedia.id/hd" target="__blank">
                  <div className="home-container40 w-100">
                    <h2
                      className={mediaQuery ? "home-text51" : "home-text51"}
                      style={{ fontSize: "24px" }}
                    >
                      +62 811-2971-000
                    </h2>
                  </div>
                </a>
                <a href="http://wa.me/6282115555646" target="__blank">
                  <div className="home-container40 w-100">
                    <h2
                      className={mediaQuery ? "home-text51" : "home-text51"}
                      style={{ fontSize: "24px" }}
                    >
                      +62 8211-5555-646
                    </h2>
                  </div>
                </a>
                <span className="home-text54" style={{ fontSize: "16px" }}>
                  {data.supportDesc}
                </span>
              </Card.Body>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="border-0">
              <Card.Body style={!mediaQuery ? { minHeight: "150px" } : null}>
                <div className="home-container41 mb-0 pb-2 w-auto justify-content-center">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="home-icon40"
                    style={{ width: "2em", marginRight: "8px" }}
                  >
                    <path d="M512 42q160 0 272 113t112 271v428q0 52-38 90t-90 38h-256v-86h298v-42h-170v-342h170v-86q0-124-87-211t-211-87-211 87-87 211v86h170v342h-128q-52 0-90-38t-38-90v-300q0-158 112-271t272-113z"></path>
                  </svg>
                  <h2
                    className={mediaQuery ? "home-text53" : "home-text53"}
                    style={
                      mediaQuery ? { fontSize: "1.3rem" } : { fontSize: "2rem" }
                    }
                  >
                    {data.kontakTitle}
                  </h2>
                </div>
                <span className="home-text54">
                  {/* <span>Call our number because we are here </span>
							<span>and always ready to help you.</span> */}
                  <span>{data.kontakDesc}</span>
                </span>
              </Card.Body>
              <Card.Footer className="mt-3 bg-none border-0">
                {/* <NavLink to="/contact">
									<button className="home-register4 button w-50" style={{fontSize:"1rem"}}>Contact Now</button>
								</NavLink> */}
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"
                >
                  <button
                    className="home-register4 button w-50"
                    style={{ fontSize: "1rem" }}
                  >
                    Contact Now
                  </button>
                </a>
              </Card.Footer>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="border-0">
              <Card.Body style={!mediaQuery ? { minHeight: "150px" } : null}>
                <div className="home-container42">
                  <svg
                    viewBox="0 0 1024 1024"
                    className="home-icon42"
                    style={{ width: "1.5em", marginRight: "8px" }}
                  >
                    <path d="M755.188 64c-107.63 0-200.258 87.554-243.164 179-42.938-91.444-135.578-179-243.216-179-148.382 0-268.808 120.44-268.808 268.832 0 301.846 304.5 380.994 512.022 679.418 196.154-296.576 511.978-387.206 511.978-679.418 0-148.392-120.43-268.832-268.812-268.832z"></path>
                  </svg>
                  <h2
                    className={mediaQuery ? "home-text57" : "home-text57 fs-22"}
                    style={
                      mediaQuery ? { fontSize: "1.3rem" } : { fontSize: "2rem" }
                    }
                  >
                    {data.subscribeTitle}
                  </h2>
                </div>
                <span className="home-text58">
                  <span>{data.subscribeDesc}</span>
                </span>
              </Card.Body>
              <Card.Footer className="mt-3 bg-none border-0">
                <NavLink to="/subscribe">
                  <button
                    className="home-register5 button w-50"
                    style={{ fontSize: "1rem" }}
                  >
                    Read More
                  </button>
                </NavLink>
              </Card.Footer>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="border-0">
              <Card.Body>
                <div className="home-container42">
                  <h2 className="home-text57" style={{ fontSize: "1.5rem" }}>
                    {data.downloadTitle}
                  </h2>
                </div>
                <div className="container-mobile">
                  <img
                    loading="lazy"
                    alt="download_now"
                    src={this.props.img_download}
                    className="img-responsive w-100"
                  />
                  <div
                    className="container_download row"
                    style={{ top: "1em" }}
                  >
                    <div>
                      <button
                        onClick={() =>
                          window.open(this.props.link_android, "_blank")
                        }
                        style={{ backgroundColor: "transparent" }}
                      >
                        <img
                          loading="lazy"
                          alt="Download on PlayStore"
                          style={{ width: "7rem" }}
                          src="https://minio.nexa.net.id/nexa/android.png"
                        ></img>
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          window.open(this.props.link_ios, "_blank")
                        }
                        style={{ backgroundColor: "transparent" }}
                      >
                        <img
                          loading="lazy"
                          alt="Download on AppStore"
                          style={{ width: "7rem" }}
                          src="https://minio.nexa.net.id/nexa/aiso.png"
                        ></img>
                      </button>
                    </div>
                  </div>
                </div>
                <br></br>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    );
  };

  render() {
    return (
      <>
        <section>
          <style jsx="true">
            {`
              .shadow-md {
                box-shadow: 5px 5px 16px 0px rgb(0 0 0 / 12%);
              }
              .splide__pagination.splide__pagination--ltr {
                display: none;
              }
              .img-nexa2 {
                min-width: 117px;
                max-width: 150px;
                height: auto;
                align-self: center;
              }

              .img-nexa {
                min-width: 117px;
                max-width: 150px;
                height: auto;
                margin-left: 10px;
                margin-bottom: 5px;
              }

              .home-text19 {
                font-size: 2em;
              }
              .splide__track.splide__track--slide {
                height: auto !important;
              }
              .home-container13 {
                width: unset;
                grid-gap: 10px;
              }
              .home-container12 {
                padding-left: 0;
              }
              .home-container39 {
                padding-left: var(--dl-space-space-threeunits);
                padding-right: var(--dl-space-space-threeunits);
              }
              .w-unset {
                width: unset;
              }

              /*.splide__slide img {
			 	width: 335px;
			 	height: 100%;
			 	object-fit: cover;
			}*/

              .custom-container div {
                width: 350px;
              }

              /* Style the button and place it in the middle of the container/image */
              .container-mobile .btn1 {
                position: absolute;

                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                font-size: 1em;
                padding: 0px 0px;
                border: none;
                cursor: pointer;
                border-radius: 1rem;
              }

              .container-mobile .btn2 {
                position: absolute;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                font-size: 1em;
                padding: 0px 0px;
                border: none;
                cursor: pointer;
                border-radius: 1rem;
              }

              .img-btn {
                width: 8rem;
              }

              .container_mobile {
                margin-top: -6rem;
                margin-left: 0.4rem;
              }

              .container_download {
                margin-top: -7rem;
                margin-left: 0.4rem;
              }

              .card-box-shadow {
                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
                  0px 8px 16px rgba(0, 0, 0, 0.08);
                border-radius: 12px;
              }
            `}
          </style>
          <div className="button-wa-fixed-mobile">
            <a
              target="_blank"
              href="https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0"
            >
              <svg
                viewBox="0 0 1024 1024"
                className="home-icon-wa-fixed-mobile"
              >
                <path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
              </svg>
            </a>
          </div>

          <div
            className="p-4 pt-4 pb-4"
            style={{ textAlign: "center", fontSize: "20px", fontWeight: 700 }}
          >
            <span className="newscard-text1">Explore Our Service</span>
          </div>

          <Col
            xs={12}
            className="p-2 mb-4 card-box-shadow"
            style={{ width: "85%", margin: "auto" }}
          >
            <div
              className="card support box-shadow border-0"
              style={{ minHeight: "180px", borderRadius: "10%" }}
            >
              {/* <img loading='lazy' alt="" src={item.url_image} className="solutioneducation-image align-self-center card-img-top mt-3"/> */}
              <img
                loading="lazy"
                alt="NexaTel"
                src={
                  this.props.isCompatible
                    ? "https://minio.nexa.net.id/nexa/nexatel-mobile.webp"
                    : "https://minio.nexa.net.id/nexa/nexatel-mobile.png"
                }
                className="card-img-top img-nexa"
              />
              <span className="solutioneducation-text22 card-body pt-0 pl-0">
                <div
                  className="card-body mb-2 lh-1-4 ms-md me-md ms-sm me-sm"
                  style={{ maxWidth: "300px", margin: "auto", paddingTop: 0 }}
                >
                  {/* <div className="card-text" style={{fontSize:"14px"}}> */}
                  <div
                    className="card-text"
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      paddingLeft: 0,
                    }}
                  >
                    {LineBreak(this.props.storeData[0].services.tel.desc)}
                  </div>

                  {/* <NavLink to="/nexatel" className="card-footer position-absolute  home-button button border-0" style={{width:"60%", paddingLeft:"4rem"}}> */}
                  <NavLink
                    to="/nexatel"
                    className="card-footer position-absolute  home-button button border-0"
                    style={{ width: "60%" }}
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="home-icon"
                      style={{ width: "15px", height: "18px" }}
                    >
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    <span className="learn fs-14">Learn More</span>
                  </NavLink>
                </div>
              </span>
            </div>
          </Col>

          <Col
            xs={12}
            className="p-2 mb-4 card-box-shadow"
            style={{ width: "85%", margin: "auto" }}
          >
            <div
              className="card support box-shadow border-0"
              style={{ minHeight: "180px", borderRadius: "10%" }}
            >
              {/* <img loading='lazy' alt="" src={item.url_image} className="solutioneducation-image align-self-center card-img-top mt-3"/> */}
              <img
                loading="lazy"
                alt="NexaApp"
                src={
                  this.props.isCompatible
                    ? "https://minio.nexa.net.id/nexa/nexaapp-mobile.webp"
                    : "https://minio.nexa.net.id/nexa/nexaapp-mobile.png"
                }
                className="card-img-top img-nexa"
              />
              <span className="solutioneducation-text22 card-body pt-0 pl-0">
                <div
                  className="card-body mb-2 lh-1-4 ms-md me-md ms-sm me-sm"
                  style={{ maxWidth: "300px", margin: "auto", paddingTop: 0 }}
                >
                  {/* <div className="card-text" style={{fontSize:"14px"}}> */}
                  <div
                    className="card-text"
                    style={{ fontSize: "14px", textAlign: "left" }}
                  >
                    {LineBreak(this.props.storeData[0].services.app.desc)}
                  </div>

                  {/* <NavLink to="/nexatel" className="card-footer position-absolute  home-button button border-0" style={{width:"60%", paddingLeft:"4rem"}}> */}
                  <NavLink
                    to="/nexaapps"
                    className="card-footer position-absolute  home-button button border-0"
                    style={{ width: "60%" }}
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="home-icon"
                      style={{ width: "15px", height: "18px" }}
                    >
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    <span className="learn fs-14">Learn More</span>
                  </NavLink>
                </div>
              </span>
            </div>
          </Col>

          <Col
            xs={12}
            className="p-2 mb-4 card-box-shadow"
            style={{ width: "85%", margin: "auto" }}
          >
            <div
              className="card support box-shadow border-0"
              style={{ minHeight: "180px", borderRadius: "10%" }}
            >
              {/* <img loading='lazy' alt="" src={item.url_image} className="solutioneducation-image align-self-center card-img-top mt-3"/> */}
              <img
                loading="lazy"
                alt="NexaData"
                src={
                  this.props.isCompatible
                    ? "https://minio.nexa.net.id/nexa/nexadata-mobile.webp"
                    : "https://minio.nexa.net.id/nexa/nexadata-mobile.png"
                }
                className="card-img-top img-nexa"
              />
              <span className="solutioneducation-text22 card-body pt-0 pl-0">
                <div
                  className="card-body mb-2 lh-1-4 ms-md me-md ms-sm me-sm"
                  style={{ maxWidth: "300px", margin: "auto", paddingTop: 0 }}
                >
                  {/* <div className="card-text" style={{fontSize:"14px"}}> */}
                  <div
                    className="card-text"
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      paddingLeft: 0,
                    }}
                  >
                    {LineBreak(this.props.storeData[0].services.data.desc)}
                  </div>

                  {/* <NavLink to="/nexatel" className="card-footer position-absolute  home-button button border-0" style={{width:"60%", paddingLeft:"4rem"}}> */}
                  <NavLink
                    to="/nexadata"
                    className="card-footer position-absolute  home-button button border-0"
                    style={{ width: "60%" }}
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="home-icon"
                      style={{ width: "15px", height: "18px" }}
                    >
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    <span className="learn fs-14">Learn More</span>
                  </NavLink>
                </div>
              </span>
            </div>
          </Col>

          <Col
            xs={12}
            className="p-2 mb-4 card-box-shadow"
            style={{ width: "85%", margin: "auto" }}
          >
            <div
              className="card support box-shadow border-0"
              style={{ minHeight: "180px", borderRadius: "10%" }}
            >
              {/* <img loading='lazy' alt="" src={item.url_image} className="solutioneducation-image align-self-center card-img-top mt-3"/> */}
              <img
                loading="lazy"
                alt="NexaDigital"
                src={
                  this.props.isCompatible
                    ? "https://minio.nexa.net.id/nexa/nexadigital-mobile.webp"
                    : "https://minio.nexa.net.id/nexa/nexadigital-mobile.png"
                }
                className="card-img-top img-nexa"
              />
              <span className="solutioneducation-text22 card-body pt-0 pl-0">
                <div
                  className="card-body mb-2 lh-1-4 ms-md me-md ms-sm me-sm"
                  style={{ maxWidth: "300px", margin: "auto", paddingTop: 0 }}
                >
                  {/* <div className="card-text" style={{fontSize:"14px"}}> */}
                  <div
                    className="card-text"
                    style={{
                      fontSize: "14px",
                      textAlign: "left",
                      paddingLeft: 0,
                    }}
                  >
                    {LineBreak(this.props.storeData[0].services.digital.desc)}
                  </div>

                  {/* <NavLink to="/nexatel" className="card-footer position-absolute  home-button button border-0" style={{width:"60%", paddingLeft:"4rem"}}> */}
                  <NavLink
                    to="/nexadigital"
                    className="card-footer position-absolute  home-button button border-0"
                    style={{ width: "60%" }}
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="home-icon"
                      style={{ width: "15px", height: "18px" }}
                    >
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    <span className="learn fs-14">Learn More</span>
                  </NavLink>
                </div>
              </span>
            </div>
          </Col>

          <Col
            xs={12}
            className="p-2 mb-4 card-box-shadow"
            style={{ width: "85%", margin: "auto" }}
          >
            <div
              className="card support box-shadow border-0"
              style={{ minHeight: "180px", borderRadius: "10%" }}
            >
              {/* <img loading='lazy' alt="" src={item.url_image} className="solutioneducation-image align-self-center card-img-top mt-3"/> */}
              <img
                loading="lazy"
                alt="NexaSolution"
                src={
                  this.props.isCompatible
                    ? "https://minio.nexa.net.id/nexa/nexasolution-mobile.webp"
                    : "https://minio.nexa.net.id/nexa/nexasolution-mobile.png"
                }
                className="card-img-top img-nexa"
              />
              <span className="solutioneducation-text22 card-body pt-0 pl-0">
                <div
                  className="card-body mb-2 lh-1-4 ms-md me-md ms-sm me-sm"
                  style={{ maxWidth: "300px", margin: "auto", paddingTop: 0 }}
                >
                  {/* <div className="card-text" style={{fontSize:"14px"}}> */}
                  <div
                    className="card-text"
                    style={{ fontSize: "14px", textAlign: "left" }}
                  >
                    {LineBreak(this.props.storeData[0].services.solution.desc)}
                  </div>

                  {/* <NavLink to="/nexatel" className="card-footer position-absolute  home-button button border-0" style={{width:"60%", paddingLeft:"4rem"}}> */}
                  <NavLink
                    to="/solution"
                    className="card-footer position-absolute  home-button button border-0"
                    style={{ width: "60%" }}
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="home-icon"
                      style={{ width: "15px", height: "18px" }}
                    >
                      <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                    </svg>
                    <span className="learn fs-14">Learn More</span>
                  </NavLink>
                </div>
              </span>
            </div>
          </Col>
        </section>
        {/* /** 
			WHY NEXA ?????
			*/}
        <section
          className="home-container24"
          style={{ marginBottom: "0", marginTop: "4rem" }}
        >
          <div className="" style={{ textAlign: "center", margin: "auto" }}>
            <span className="home-text27">
              <span></span>
              <span>Business</span>
            </span>
            <h2 className="home-text30" style={{ fontWeight: 700 }}>
              Why Nexa?
            </h2>
            <span className="home-text31">
              <span>
                {this.props.support ? (
                  this.props.support.whyNexaDesc1
                ) : (
                  <Shimmer />
                )}
              </span>
              <br />
              <span>
                {this.props.support ? (
                  this.props.support.whyNexaDesc2
                ) : (
                  <Shimmer />
                )}
              </span>
            </span>
          </div>
          <div className="home-container25">
            <div className="row ml-0 mr-0">{this.createSliderArticle2()}</div>
          </div>
          <div
            className=""
            style={{
              textAlign: "center",
              margin: "auto",
              paddingBottom: "2rem",
            }}
          >
            <NavLink to="/contact">
              <button className="home-register1 button">Try Now</button>
            </NavLink>
          </div>
          {/* <div className="home-container29">
            <svg viewBox="0 0 1024 1024" className="home-icon28" data="1" onClick={this.handleArticlePrev}>
              <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path></svg>
              <svg viewBox="0 0 1024 1024" className="home-icon30" data="1" onClick={this.handleArticleNext}>
              <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div> */}
        </section>

        <section>
          <div className="mb-0 pt-4 mt-4" style={{ paddingLeft: "0.5rem" }}>
            <span className="home-text18">News &amp; Article</span>
            <h2 className="home-text19 fw-bold">Latest Issue</h2>
          </div>

          {this.createSliderNews()}

          <div className="home-container12 mb-0">
            <div className="home-container13" style={{ marginLeft: 0 }}>
              <svg
                viewBox="0 0 1024 1024"
                className="home-icon20"
                onClick={this.handleSlidePrev}
              >
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
              </svg>
              <svg
                viewBox="0 0 1024 1024"
                className="home-icon22"
                onClick={this.handleSlideNext}
              >
                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
              </svg>
            </div>
            <div className="row align-self-center justify-content-end w-100 mainNumber">
              <span
                className="col-1 numberSlide text-center"
                style={{ width: "100px" }}
              >
                1 / {this.state.total_data}
              </span>
            </div>
          </div>
        </section>

        <section className="home-section4 nexaIpad">
          <div className="home-container16">
            <span className="home-text20">
              <span></span>
              <span>Business</span>
            </span>
            <h2 className="home-text23">Why Nexa?</h2>
            <span className="home-text24">
              <span>Providing IT solutions for network</span>
              <br />
              <span>problems that occurs to customers.</span>
            </span>
            <NavLink to="/contact">
              <button className="home-register button">Try Now</button>
            </NavLink>
          </div>
          <div className="home-container17">
            <div className="home-container18">{this.renderArticle()}</div>
            {/* <div className="home-container22">
		            <svg viewBox="0 0 1024 1024" className="home-icon24" data="0" onClick={this.handleArticlePrev}>
		                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM682.667 469.333h-238.336l97.835-97.835c16.683-16.683 16.683-43.691 0-60.331s-43.691-16.683-60.331 0l-170.667 170.667c-4.096 4.096-7.168 8.789-9.259 13.824s-3.243 10.539-3.243 16.341c0 5.547 1.067 11.136 3.243 16.341 2.091 5.035 5.163 9.728 9.259 13.824l170.667 170.667c16.683 16.683 43.691 16.683 60.331 0s16.683-43.691 0-60.331l-97.835-97.835h238.336c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667z"></path>
		            </svg>
		            <svg viewBox="0 0 1024 1024" className="home-icon26" data="0" onClick={this.handleArticleNext}>
		                <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h238.336l-97.835 97.835c-16.683 16.683-16.683 43.691 0 60.331s43.691 16.683 60.331 0l170.667-170.667c3.925-3.925 7.083-8.619 9.259-13.824 4.309-10.453 4.309-22.229 0-32.683-2.091-5.035-5.163-9.728-9.259-13.824l-170.667-170.667c-16.683-16.683-43.691-16.683-60.331 0s-16.683 43.691 0 60.331l97.835 97.835h-238.336c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
		            </svg>
	            </div> */}
          </div>
        </section>

        <section className="mt-5">
          <YoutubeEmbed
            embedId="-dhjSMCLdv4"
            className="w-100"
            heightNumber="360"
          />
        </section>
        {/* {this.handleListYoutube()} */}

        <Portofolio
          desc={LineBreak(this.props.storeData[0].portofolio.desc)}
          title={this.props.support ? this.props.support.titlePortofolio : ""}
        />
        <OurPartners />

        {this.renderSupport()}
      </>
    );
  }
}
