import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Shimmer } from 'react-shimmer';
import { detectDevice } from '../../../controller';

let isCompatible = detectDevice()

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ScrollableTabsButtonAuto({storeData}) {
  console.log('ScrollableTabsButtonAuto', storeData)
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <section className="row nexadata-container05">
    <Box className="nexadata-container05">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className=" m-0 textCapitalize d-flex justify-content-center"
        aria-label="scrollable auto tabs example" id="__nexaBlue" style={{ "minHeight": "auto"}}>
          <Tab label="Colocation" {...a11yProps(0)} className="nexadata-button1 button" />
          <Tab label="Hosting Solution" {...a11yProps(1)} className="nexadata-button1 button" />
      </Tabs>
      
    </Box>
    	<TabPanel value={value} index={0}>
        { 
          <div className="nexadata-container07">
            <div className="nexadata-container08">
              <span className="nexadata-text15 text-primary">
                <span>{ storeData !== null ? storeData.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}</span>
              </span>
              <span className="nexadata-text17">
              { storeData !== null ? storeData.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} />}
              </span>
            </div>
            <div className="nexadata-container09" style={{"backgroundImage": `url("${isCompatible ? "https://minio.nexa.net.id/nexa/collect.webp" : "https://minio.nexa.net.id/nexa/collect.png"}")`}}></div>
          </div>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
      	{ 
          <div className="nexadata-container07">
            <div className="nexadata-container08">
              <span className="nexadata-text15 text-primary">
                <span>{ storeData !== null ? storeData.split('|')[2] : <Shimmer width={"20rem"} height={"4rem"} />}</span>
              </span>
              <span className="nexadata-text17">
                { storeData !== null ? storeData.split('|')[3] : <Shimmer width={"20rem"} height={"4rem"} />}
              </span>
            </div>
            <div className="nexadata-container09" style={{"backgroundImage": `url("${isCompatible ? "https://minio.nexa.net.id/nexa/hosting.webp" : "https://minio.nexa.net.id/nexa/hosting.png"}")`}}></div>
          </div>
        }
      </TabPanel>
  </section>
  );
}

export default class Content extends Component {

    render(){
        return (
        <section>
        <section className="nexadata-container03">
          <div className="nexadata-container04">
            <span className="nexadata-text13 text-primary">Nexa Data</span>
            <h2 className="nexadata-text14 fw-bold">Products &amp; Services</h2>
          </div>
          <div className="nexadata-container05">
            
            <ScrollableTabsButtonAuto storeData={this.props.storeData} />
            
          </div>
          
        </section>

        <section className="nexadata-section6">
          <div className="nexadata-container10">
            <span className="nexadata-text22 text-primary">Nexa Data</span>
            <h2 className="nexadata-text23 fw-bold">Service Features</h2>
          </div>
          <div className="nexadata-container11">
            <div className="nexadata-container12">
              <div className="nexadata-container13">
                <img loading="lazy" alt="Icon SLA" src="https://minio.nexa.net.id/nexa/icon_sla_99_blue.png" className="nexadata-image1"/>
                <span className="nexadata-text24">
                  <span>SLA 99,8%</span>
                  <br/>
                  <span></span>
                </span>
              </div>
              <div className="nexadata-container14">
                <img loading="lazy" alt="Icon NOC " src="https://minio.nexa.net.id/nexa/icon_noc_24-7.png" className="nexadata-image1"/>
                <span className="nexadata-text28">
                  <span>NOC 24/7</span>
                  <br/>
                  <span></span>
                </span>
              </div>
              <div className="nexadata-container15">
                <img loading="lazy" alt="Icon Dedicated Internet" src="https://minio.nexa.net.id/nexa/icon_dedicated.png" className="nexadata-image2"/>
                <span className="nexadata-text32">
                  <span>Dedicated</span>
                  <br/>
                  <span>Internet</span>
                </span>
              </div>
              <div className="nexadata-container16">
                <img loading="lazy" alt="Icon Quick Response" src="https://minio.nexa.net.id/nexa/icon_quick_response.png" className="nexadata-image3"/>
                <span className="nexadata-text35">
                  <span>Quick</span>
                  <br/>
                  <span>Response Time</span>
                </span>
              </div>
            </div>
            <div className="nexadata-container17">
              <div className="nexadata-container18">
                <img loading="lazy" alt="Icon Secure Network" src="https://minio.nexa.net.id/nexa/icon_secure_network.png" className="nexadata-image4"/>
                <span className="nexadata-text38">
                  <span>Secure</span>
                  <br/>
                  <span>Network</span>
                  <br/>
                  <span></span>
                  <span className="nexadata-text43"></span>
                </span>
              </div>
              <div className="nexadata-container19">
                <img loading="lazy" alt="Icon High Reliability" src="https://minio.nexa.net.id/nexa/icon_high_reliability.png" className="nexadata-image5"/>
                <span className="nexadata-text44">
                  <span>High</span>
                  <br/>
                  <span>Reliability</span>
                  <br/>
                  <span></span>
                </span>
              </div>
              <div className="nexadata-container20">
                <img loading="lazy" alt="Icon Scalability" src="https://minio.nexa.net.id/nexa/icon_fast_flexible.png" className="nexadata-image6"/>
                <span className="nexadata-text49">
                  <span>Fast &amp; Flexible</span>
                  <br/>
                  <span>Scalability</span>
                  <br/>
                  <span>as needed</span>
                </span>
              </div>
            </div>
          </div>
        </section>
        </section>
        )
    }
}