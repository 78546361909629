import Banner 		from '../../components/Banner';
import Content 		from './Content';
import '../../../assets/news.css';

import  React from 'react'
import { getArticle, LineBreak, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice()

function Article() {
	const langArticle 			= useSelector(state => state.articlePage);
	const langs 				= useSelector(state => state.langs);

	const [result, setResult] 	= React.useState(null)
	const [statusLang] 			= React.useState(langs[0].idLang)

	let langCondition 			= (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data) => {
			getArticle().then((data) => {
				setResult(data.response.news_articles)
			}).catch((err) => {
				console.log('setTel', err)
			})
		})
	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text fw-bold"><span>{ langArticle[0].title.split('|')[0] }</span>
				              <br/>
				              <span className="solutioncorporate-text002">{ langArticle[0].title.split('|')[1] }</span>
				            </h1>;
	let sBanner_desc  		= LineBreak(langArticle[0].desc)
	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_news.webp" : "https://minio.nexa.net.id/nexa/banner_news.png";
	let eTitle 				= result !== null ? `${langArticle[0].title.split('|')[0]} ${langArticle[0].title.split('|')[1]}` : ""
	let desc				= result !== null ? langArticle[0].desc : ""

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			{/* <Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={{"width":"100%"}} /> */}
			<Banner title={eBanner} url_image={mainBannerPage} style={{"width":"100%"}} />
			
			{ result === null && <Content storeData={null} /> }
			{ result !== null && <Content storeData={result} /> }
			
		</div>
		);
}

export default Article;