import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {detectDevice} from "../../controller/index"

// let base_url_partner = './assets/images/partner/';
let base_url_partner = "https://minio.nexa.net.id/"
let mediaQuery = window.matchMedia("(max-width: 414px)").matches
let isCompatible = detectDevice();

export default class OurPartners extends Component {
	isIpadPro() {
		// let res_data =  [
		// {
		// 	'url_image': base_url_partner + 'nexa/dell.webp',
		// 	'title': 'Dell',
		// 	'style': {
		// 		'height': '130px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/huawei.webp',
		// 	'title': 'Huawei',
		// 	'style': {
		// 		'height': '130px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/hp.webp',
		// 	'title': 'Hp',
		// 	'style': {
		// 		'height': '130px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/juniper.webp',
		// 	'title': 'Juniper',
		// 	'style': {
		// 		'height': '65px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/VMWARE.webp',
		// 	'title': 'VMWARE',
		// 	'style': {
		// 		'height': '55px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/lg.webp',
		// 	'title': 'LG',
		// 	'style': {
		// 		'height': '120px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/panduit.webp',
		// 	'title': 'Panduit',
		// 	'style': {
		// 		'height': '140px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/cisco.webp',
		// 	'title': 'Cisco',
		// 	'style': {
		// 		'height': '120px',
		// 	}
		// },
		// {
		// 	'url_image': base_url_partner + 'nexa/corning.webp',
		// 	'title': 'Corning',
		// 	'style': {
		// 		'height': '75px',
		// 	}
		// }
		// ];

		let res_data =  [
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/dell.webp' : 'nexa/dell.png'),
				'title': 'Dell',
				'style': {
					'height': '8rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/huawei.webp' : 'nexa/huawei.png'),
				'title': 'Huawei',
				'style': {
					'height': '8rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/hp.webp' : 'nexa/hp.png'),
				'title': 'Hp',
				'style': {
					'height': '8rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/lg.webp' : 'nexa/lg.png'),
				'title': 'LG',
				'style': {
					'height': '7rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/juniper.webp' : 'nexa/juniper.png'),
				'title': 'Juniper',
				'style': {
					'height': '4rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/VMWARE.webp' : 'nexa/VMWARE.png'),
				'title': 'VMWARE',
				'style': {
					'height': '3.3rem',
					'width': 'auto'
				}
			},
			
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/panduit.webp' : 'nexa/panduit.png'),
				'title': 'Panduit',
				'style': {
					'height': '8rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/cisco.webp' : 'nexa/cisco.png'),
				'title': 'Cisco',
				'style': {
					'height': '7rem',
					'width': 'auto'
				}
			},
			{
				'url_image': base_url_partner + (isCompatible ? 'nexa/corning.webp' : 'nexa/corning.png'),
				'title': 'Corning',
				'style': {
					'height': '4.2rem',
					'width': 'auto'
				}
			}
			];

		let storeElement = []

		res_data.map((row, index) => (
			storeElement.push(
				<Col xs={6} md={4} sm={4} key={index} className="align-self-center " style={{margin:"auto"}}>
					<img alt="" src={row.url_image} className="w-auto p-3" style={row.style} />
				</Col>
			)
		))

		return (<Row style={{"maxWidth":"600px"}}>{storeElement}</Row>);

	}
	isMobile(){
		let res_data =  [
		{
			'url_image': base_url_partner + (isCompatible  ? 'nexa/ZTE.webp' : 'nexa/ZTE.png'),
			'title': 'Dell',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'maxWidth': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/panduit.webp' : 'nexa/panduit.png'),
			'title': 'Huawei',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/lg.webp' : 'nexa/lg.png'),
			'title': 'Hp',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/hp.webp' : 'nexa/hp.png'),
			'title': 'LG',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/huawei.webp' : 'nexa/huawei.png'),
			'title': 'Juniper',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/dell.webp' : 'nexa/dell.png'),
			'title': 'VMWARE',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/vmware.webp' : 'nexa/vmware.png' ),
			'title': 'Panduit',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/juniper.webp' : 'nexa/juniper.png'),
			'title': 'Cisco',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/cisco.webp' : 'nexa/cisco.png' ),
			'title': 'Corning',
			'style': {
				'height': '7rem',
				'width': '7rem',
				'maxHeight': '125px',
				'max-width': '200px'
			}
		}
		];

		let storeElement = []

		res_data.map((row, index) => (
			storeElement.push(
				<Col xs={4} md={4} sm={4} key={index} className="align-self-center d-flex justify-content-center" style={{margin:"auto"}}>
					<img alt="" src={row.url_image} className="w-auto p-3" style={row.style} />
				</Col>
			)
		))

		return (<Row className={(mediaQuery) ? "m-3" : ""} style={{"maxWidth":"568px"}}>{storeElement}</Row>);
	}
    render(){
        return (
        <>
        	<section className={(mediaQuery) ? "nexatel-our-partner d-block" : "nexatel-our-partner"} style={this.props.styleSection}>
	          <div className="nexatel-container18">
	            <span className="nexatel-text40"></span>
	            <h1 className="nexatel-text41" style={{fontSize:"1.8rem"}}>Our Partners</h1>
	          </div>
	          <div className="nexatel-container19" style={{margin:"auto", paddingLeft:"1rem", paddingRight:"1rem"}}>
	          
	          {/*
	          	<img alt="" src="./assets/images/banner/partner.webp" className="w-75" style={{"marginLeft":"4rem","marginRight":"4rem"}} />
	      		*/}

	      		{(mediaQuery) ? this.isMobile() : this.isIpadPro()}
	          </div>
	        </section>
        </>
        )
    }
}