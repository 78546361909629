import React, {Component} from 'react';

export default class AfterBanner extends Component {
    render(){

        return (
        	<section className="companyabout-section2" style={this.props.styleSection}>
	          <div className={"companyabout-maxwith " + ((this.props.button) ? '' : '')} style={{"height": "auto"}}>
	            <div className="companyabout-container01 pr-5">
	            	<span className="companyabout-text fs-28">
	            		<div>{this.props.title}</div>
	            	</span>
	            </div>
	            <div className="companyabout-container02 ps-0 pe-0">
	            	<span className="companyabout-text04 w-100 pt-0 fs-24 fw-300">
	            		{this.props.desc}
	            		{(this.props.button) ? <React.Fragment><br/><a href="https://e-katalog.lkpp.go.id/info/penyedia/87" className="companyabout-register button mt-5 text-white" target="_blank" rel="noopener noreferrer">Find More</a></React.Fragment> : ''}
	            	</span>
	            </div>
	          </div>
	        </section>
        )
    }
}