import React, {Component} from 'react';
import Management from '../../components/Management';
import YoutubeEmbed from '../../components/YoutubeEmbed';
import Container from 'react-bootstrap/Container';
import { detectDevice } from '../../../controller/index';

let isCompatible = detectDevice()

export default class Content extends Component {
	render(){
		return (
			<>
			<section>
				<div className="row">
		            <div className="col-md-6 col-sm-12 d-flex justify-content-center">
		            	<div className="card border-0" style={{"width":"300px"}}>
		            		<h1 className="card-text text-center companyabout-text25 fw-bold p-3 pb-0 mb-0">
								{parseInt(sessionStorage.getItem('lang')) === 1 ? "Our Vision" : "Visi Kami"}
							</h1>
		            		<div className="card-body companyabout-text26 p-0 pt-4 pb-4">
		            		{this.props.storeData.visi}
		            		</div>
		            	</div>
		            </div>
		            <div className="col-md-6 col-sm-12 d-flex justify-content-center" >
		            	<div className="card border-0" style={{"width":"300px"}}>
		            		<h1 className="card-text text-center companyabout-text25 fw-bold p-3 pb-0 mb-0">
							{parseInt(sessionStorage.getItem('lang')) === 1 ? "Our Mission" : "Misi Kami"}
							</h1>
		            		<div className="card-body companyabout-text26 p-0 pt-4 pb-4">
		            		{this.props.storeData.misi}
		            		</div>
		            	</div>
		            </div>
		        </div>
			</section>

			<section>
				<YoutubeEmbed embedId="-dhjSMCLdv4" className="mt-5" heightNumber="360" />
			</section>

			<section className="companyabout-section6">
	          <div className="companyabout-container10">
	            <span className="companyabout-text59">Company</span>
	            <h1 className="companyabout-text60 fw-bold">Nexa in Numbers</h1>
	            <img alt="" src={isCompatible ? "https://minio.nexa.net.id/nexa/nexa_number_mobile.webp" : "https://minio.nexa.net.id/nexa/nexa_number_mobile.png"} className="companyabout-image"/>
	          </div>
	        </section>
			<Management />
			</>
		)
	}
}