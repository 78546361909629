import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId, classContainer, className='', widthNumber=0, heightNumber=0 }) => (
  <div className={"video-responsive "+classContainer}>
    <iframe
      loading="lazy"
      width={(widthNumber === 0) ? "100%" : widthNumber}
      height={(heightNumber === 0) ? "480" : heightNumber }
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      className={className} style={{"borderRadius":"15px"}}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};

export default YoutubeEmbed;
