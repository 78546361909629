import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import Content 		from './Content';
import Portofolio2 	from '../../components/Portofolio2';
import Interested2 	from '../../components/Interested2';
import Footer 		from '../../components/Footer';
import '../../../assets/client.css';

import  React from 'react'
import { ClientPage, LineBreak, AboutUs, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice()

function Client() {
	const [result, setResult] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	const [Interested, setInterested] = React.useState(null)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data) => {
			ClientPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
	
			AboutUs().then((data) => {
				setInterested(data.response[0])
			}).catch(err => {
				console.log(err)
			})
		})

	}, [langCondition]);

	let eBanner_title 		= <h1 className="banner-text fw-bold"><span>{parseInt(sessionStorage.getItem('lang')) === 1 ? "Clients" : "Klien"}</span></h1>;
	let eTitle				= parseInt(sessionStorage.getItem('lang')) === 1 ? "Clients" : "Klien"
	let sBanner_desc  		= result !== null ? LineBreak(result.tentang_1) :<Shimmer width={"20rem"} height={"4rem"} /> ;
	let sAfterBanner_title 	= result !== null ? result.tentang_2.replaceAll('<br/>', '') : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_desc	= result !== null ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} />
	
	let Portofolio_sub 		= "Business",
		Portofolio_title	= parseInt(sessionStorage.getItem('lang')) == 1 ? "Our Valued Customers" : "Our Valued Customers",
		Portofolio_desc		= result !== null ? LineBreak(result.clients) : <Shimmer width={"20rem"} height={"4rem"} />

	let mainBannerPage		= isCompatible ? "https://minio.nexa.net.id/nexa/client.webp" : "https://minio.nexa.net.id/nexa/client.jpeg"
	return (
		<div>
			{/* <Banner title={eBanner_title} desc={sBanner_desc} url_image={mainBannerPage} style={{"width":"100%"}} /> */}
			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			<Banner title={eBanner_title} url_image={mainBannerPage} style={{"width":"100%"}} />
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			<Content />
			<Portofolio2 sub_title={Portofolio_sub} title={Portofolio_title} desc={Portofolio_desc}/>
			<Interested2 storeData={Interested}/>
		</div>
  );
}

export default Client;
