import Header 		from '../../../components/Header';
import Content 		from './Content';
import Footer 		from '../../../components/Footer';
import NotFound 	from '../../../error_page/NotFound';

import  React from 'react'
import { getArticle, CreateToken } from '../../../controller/index'
import { useSelector } from 'react-redux'
import {useSearchParams} from 'react-router-dom'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet';

function Loading(){
	return(
		<>
		
		<div className="container text-center">
			<h3>Loading...</h3>
		</div>
		
		</>
	)
}	
function Detail() {

	//Google Analitik
	ReactGA.event({category:"user", action:"News Detail"})
	

	const [searchParams] 			= useSearchParams();
	const langs 					= useSelector(state => state.langs);
	const [result, setResult] 		= React.useState('null')
	const [article, setArticle] 	= React.useState(null)
	const [notFound, setNotFound]	= React.useState(<Loading />)
	const [statusLang] 				= React.useState(langs[0].idLang)
	const [textPageNews, setTextNews] = React.useState(null)

	let langCondition 				= (statusLang !== langs[0].idLang)

	let PageNews = (id) => {
		let lang = sessionStorage.getItem('lang')

		if(parseInt(lang) === 2){
			return {"posted":"Diposting pada",
					"titleNews":"Berita &amp; Artikel",
					"otherNews":"Berita Lain",
					
					}
		}else{
			return {"posted":"Posted on",
					"titleNews":"News &amp; Article",
					"otherNews":"Other News"}
		}
		
	}

	React.useEffect(() => {

		CreateToken().then((data) => {
			getArticle().then((data) => {
				let res_data = data
				if (res_data.metadata.status !== 200) {
					console.log('Error ', res_data.message)
				} else {
					res_data.response.news_articles.forEach((row, idx) => {
						if (row.kode === searchParams.get("id")){
							setResult(res_data.response.news_articles[idx])
							console.log(res_data.response.news_articles[idx])
						}
						else{
							setNotFound(<NotFound />)
						}
							
					})
					let dataNews = res_data.response.news_articles
				
					const indexOfObject = dataNews.findIndex(object => {
						return object.kode === searchParams.get("id")
					});
					
					dataNews.splice(indexOfObject, 1)
	
					setArticle(dataNews)
				}
			}).catch((err) => {
				console.log('detail err', err)
			})
	
		})

		setTextNews(PageNews())

		window.scrollTo(0, 0);
	}, [langCondition, searchParams]);

	// console.log('data', langCondition, langs, result)
	let eTitle = "Nexa News"
	let desc = "Ini deskripsi"
	let mainBannerPage = ""

	return (
		<div>
		<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>
		{ result === 'null' ? 
			(<>{notFound}</>) : 
			(<>
				
				<Content storeData={result} news={article} text={textPageNews}/>
				
				</>
				)
		}
		</div>
		)
}

export default Detail;
