module.exports = [
  {
    'name': 'Purwokerto',
    'latLng': {
      'lat': -7.426052005247885,
      'lng': 109.23073359765625,
    },
    'radius': 3500
  },
  {
    'name': 'Temanggung',
    'latLng': {
      'lat': -7.319820974619333,
      'lng': 110.18654414453125,
    },
    'radius': 3000
  },
  {
    'name': 'Salam',
    'latLng': {
      'lat': -7.619412398353927,
      'lng': 110.30701562085079,
    },
    'radius': 4000
  },
  {
    'name': 'Purworejo',
    'latLng': {
      'lat': -7.725538532915631,
      'lng': 110.00526973046875,
    },
    'radius': 4000
  },
  {
    'name': 'Wates',
    'latLng': {
      'lat': -7.886765440707644,
      'lng': 110.13641902246094,
    },
    'radius': 6000
  },
  {
    'name': 'Semarang',
    'latLng': {
      'lat': -7.007254130599748,
      'lng': 110.4231615930737,
    },
    'radius': 3500
  },
  {
    'name': 'Salatiga',
    'latLng': {
      'lat': -7.322545164857948,
      'lng': 110.50514766015625,
    },
    'radius': 5000
  },
  {
    'name': 'Boyolali',
    'latLng': {
      'lat': -7.513187600279645,
      'lng': 110.59575676238833,
    },
    'radius': 5000
  },
  {
    'name': 'Kudus',
    'latLng': {
      'lat': -6.842836872855777,
      'lng': 110.84023066796875,
    },
    'radius': 6000
  },
  {
    'name': 'Magelang',
    'latLng': {
      'lat': -7.508588417421982,
      'lng': 110.22097948624844,
    },
    'radius': 6000
  },
  {
    'name': 'Sragen',
    'latLng': {
      'lat': -7.421285775546951,
      'lng': 111.01669856347657,
    },
    'radius': 5000
  },
  {
    'name': 'Solo',
    'latLng': {
      'lat': -7.554039881212564,
      'lng': 110.80864497460938,
    },
    'radius': 7000
  },
  {
    'name': 'Karanganyar',
    'latLng': {
      'lat': -7.613936114812962,
      'lng': 111.0022790078125,
    },
    'radius': 4500
  },
  {
    'name': 'Klaten',
    'latLng': {
      'lat': -7.737785790814054,
      'lng': 110.64865657128907,
    },
    'radius': 7000
  },
  {
    'name': 'Wonogiri',
    'latLng': {
      'lat': -7.804459051586011,
      'lng': 110.941854203125,
    },
    'radius': 7000
  },
  {
    'name': 'Prambanan',
    'latLng': {
      'lat': -7.752073808753956,
      'lng': 110.51750727929688,
    },
    'radius': 3500
  },
  {
    'name': 'Yogyakarta',
    'latLng': {
      'lat': -7.856157530234498,
      'lng': 110.32387324609375,
    },
    'radius': 20000
  },
  {
    'name': 'Surabaya',
    'latLng': {
      'lat': -7.273507199872015,
      'lng': 112.75185176171875,
    },
    'radius': 10000
  },
  {
    'name': 'Sidoarjo',
    'latLng': {
      'lat': -7.409710431326389,
      'lng': 112.71339961328125,
    },
    'radius': 5000
  },
  {
    'name': 'Gresik',
    'latLng': {
      'lat': -7.180865344198352,
      'lng': 112.6282555703125,
    },
    'radius': 5000
  },
  {
    'name': 'Malang',
    'latLng': {
      'lat': -7.943214083572485,
      'lng': 112.62550898828125,
    },
    'radius': 8000
  },
  {
    'name': 'Batu',
    'latLng': {
      'lat': -7.875202718036851,
      'lng': 112.52663203515625,
    },
    'radius': 5000
  },
  {
    'name': 'Ubud',
    'latLng': {
      'lat': -8.514056097973716,
      'lng': 115.22019699609375,
    },
    'radius': 10000
  },
  {
    'name': 'Denpasar',
    'latLng': {
      'lat': -8.679341625120971,
      'lng': 115.21359629211926,
    },
    'radius': 7000
  },
  {
    'name': 'Kuta Selatan',
    'latLng': {
      'lat': -8.805947175829933,
      'lng': 115.16938522851562,
    },
    'radius': 5000
  },
  {
    'name': 'Gianyar',
    'latLng': {
      'lat': -8.558980881639265,
      'lng': 115.34597170831279,
    },
    'radius': 3000
  },
  {
    'name': 'Nusa Penida',
    'latLng': {
      'lat': -8.680502115728954,
      'lng': 115.45858157159404,
    },
    'radius': 3000
  },
  {
    'name': 'Jl. Gili Manuk',
    'latLng': {
      'lat': -8.145422245804351,
      'lng': 114.57317869116517,
    },
    'radius': 3000
  },
  {
    'name': 'Bubunan',
    'latLng': {
      'lat': -8.200405971748024,
      'lng': 114.91818501941276,
    },
    'radius': 3000
  },
  {
    'name': 'Buleleng',
    'latLng': {
      'lat': -8.122241471992035,
      'lng': 115.08297994128776,
    },
    'radius': 3000
  },
  {
    'name': 'Boengkoelan',
    'latLng': {
      'lat': -8.08349341685935,
      'lng': 115.1674373387487,
    },
    'radius': 3000
  },
  {
    'name': 'Amlapura',
    'latLng': {
      'lat': -8.455931938157718,
      'lng': 115.59434697300769,
    },
    'radius': 3000
  }
]