import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { formatTanggal, stripHtml } from '../../../controller/index'
import { NavLink, useLocation } from 'react-router-dom';

import { Shimmer } from 'react-shimmer';


export default class Content extends Component {
	constructor(props){
		super(props)
		
		this.state = {
			textCollapse: 'show'
		}
	}
	
	cutText = (sText, id_bahasa) => {
		
	
		return (
			<div>
			{ this.state.textCollapse === 'hidden' ? stripHtml(sText.substr(0,200)) + '...' : <div dangerouslySetInnerHTML={{__html:sText}}/>}
			{	
				// this.state.textCollapse === 'hidden' ? <button onClick={ () => { this.setCollapseText('show') }}>Show More</button> : <button onClick={ () => { this.setCollapseText('hidden') }}>Hidden</button>
			}
			</div>
		)	
	}

	setCollapseText = (sCond) => {
		if (sCond === 'hidden') {
			let content = document.querySelector('.detailnews-image')
			content.scrollIntoView()
		} 
		this.setState({ textCollapse: sCond })
	}

	createMarkup(){
		return this.props.storeData !== null ? this.cutText(this.props.storeData.deskripsi, this.props.storeData.id_bahasa) : <Shimmer  width={"inherit"} height={"inherit"} />
	}

	

    render(){

    	return(
    	<section>
    	<style jsx="true">
    	{`
    		.card-column {
    			box-shadow: 5px 5px 16px 0px rgb(0 0 0 / 16%);
    		}
    	`}
    	</style>
	    	<article className="detailnews-section2 mt-0">
	          	<div className="detailnews-container01">
		            <span className="detailnews-text fw-bold mb-1" style={{fontSize:"16px"}}>News &amp; Article</span>
		            <h1 className="detailnews-text001" style={{fontSize:"1rem", textAlign:"center"}}>
		            { this.props.storeData !== null ? this.props.storeData.title : <Shimmer  width={"inherit"} height={"inherit"} /> }
		            </h1>
		            <span className="detailnews-text004" style={{fontSize: "13px"}}>
		              <span>Posted on { this.props.storeData !== null ? formatTanggal(this.props.storeData.tanggal, this.props.storeData.id_bahasa) : <Shimmer  width={"inherit"} height={"inherit"} /> }</span>
		            </span>
	          	</div>
		        
				<article>
					<Card className="border-0">
						<Card.Img loading='lazy' alt={`Nexa - ${this.props.storeData !== null ? this.props.storeData.title : ""}`} variant="top" className="detailnews-image" src={ this.props.storeData !== null ? this.props.storeData.image : 'https://via.placeholder.com/480x480' } />
						<Card.Body>
							<div className="detailnews-text006 card-text" style={{textAlign:'justify', fontSize:"14px"}}>
							
							{ this.props.storeData !== null ? this.cutText(this.props.storeData.deskripsi, this.props.storeData.id_bahasa) : <Shimmer  width={"inherit"} height={"inherit"} />	 }
							
							</div>
						</Card.Body>
					</Card>
				</article>
	        </article>

	        <hr/>

		    <div className="detailnews-section4">
	          <div className="detailnews-container04 mt-3">
	            <span className="detailnews-text040">News &amp; Article</span>
	            <h2 className="detailnews-text041" style={{fontSize:"2rem", marginBottom:"2rem"}}>Other News</h2>
	          </div>
	          <Row>
				
	        	
	        	{	
					
	        		this.props.news.map((response, index) => {
					

					return (
					<article>
						<Col xs={12} md={6} key={index} className="mb-3">
						<NavLink to={"/news_detail?id="+response.kode} key={response.title}>
						<Card className="card-column">
							<Card.Img alt={`Nexa News - ${response.title}`} loading='lazy' src={response.image} variant="top" style={{"height":"210px", "width":"auto", objectFit:"cover"}} />
							<div className="news-container06 position-absolute" style={{"top":"10.5rem", "left":"20px"}}>
								<span className="news-text09">{response.label}</span>
							</div>
							<Card.Body className="news-container07">
								<h2 className="news-text10">
								{ response.title }
								</h2>
								<span className="news-text13 ">
								{ stripHtml(response.deskripsi.substr(0, 200)) + " ...." }
								</span>
								<span className="news-text18">{formatTanggal(response.tanggal, response.id_bahasa)}</span>
							</Card.Body>
						</Card>
						</NavLink>
						</Col>
					</article>
					)
					
					})
	        	}
	          </Row>
	        </div>
    	</section>
    	)
    }
}