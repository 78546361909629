import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {detectDevice} from "../controller/index"

// let base_url_partner = './assets/images/partner/';
let base_url_partner = "https://minio.nexa.net.id/"
let mediaQuery = window.matchMedia("(max-width: 414px)").matches

let isCompatible = detectDevice();
export default class OurPartners extends Component {
	isIpadPro() {
		let res_data =  [
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/ZTE.webp' : 'nexa/ZTE.png'),
			'title': 'ZTE',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/panduit.webp' : 'nexa/panduit.png'),
			'title': 'Panduit',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/vmware.webp' : 'nexa/vmware.png'),
			'title': 'VMware',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/juniper.webp' : 'nexa/juniper.png'),
			'title': 'Juniper',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/hp.webp' : 'nexa/hp.png'),
			'title': 'HP',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/huawei.webp' : 'nexa/huawei.png'),
			'title': 'Huawei',
			'style': {
				'height': '100%',
				'width':'100%',		
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/dell.webp' : 'nexa/dell.png'),
			'title': 'Dell',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/lg.webp' : 'nexa/lg.png'),
			'title': 'LG',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/cisco.webp' : 'nexa/cisco.png'),
			'title': 'Cisco',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},
		{
			'url_image': base_url_partner +  (isCompatible ? 'nexa/google.webp' : 'nexa/google.png'),
			'title': 'Google Workspace',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},

		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/ubiquity.webp' : 'nexa/ubiquity.png'),
			'title': 'Ubiquity',
			'style': {
				'height': '100%',
				'width':'100%',
			},
			'class': ''
		},

		{
			'url_image': base_url_partner + (isCompatible ? 'nexa/sophos.webp' : 'nexa/sophos.png'),
			'title': 'Sophos',
			'style': {
				'height': '100%',
				'width':'100%'
			},
			'class': ''
		}
		];

		let storeElement = []
		
		
		res_data.map((row, index) => (
			storeElement.push(
				<Col xs={3} md={3} sm={3} key={index} className="align-self-center d-flex justify-content-center ">
					<img alt={`${row.title} Logo`} loading='lazy' src={row.url_image} className={row.class} style={row.style} />
				</Col>
			)
		))

		// return (<Row style={{"maxWidth":"1100px"}}>{storeElement}</Row>);
		return (<div className='row' style={{maxWidth:"1100px"}}>{storeElement}</div>)

	}
	isMobile(){
		let res_data =  [
		{
			'url_image': base_url_partner + 'dell.webp',
			'title': 'Dell',
			'style': {
				'height': '130px',
			}
		},
		{
			'url_image': base_url_partner + 'huawei.webp',
			'title': 'Huawei',
			'style': {
				'height': '130px',
			}
		},
		{
			'url_image': base_url_partner + 'hp.webp',
			'title': 'Hp',
			'style': {
				'height': '130px',
			}
		},
		{
			'url_image': base_url_partner + 'lg.webp',
			'title': 'LG',
			'style': {
				'height': '120px',
				'width': 'auto!important',
			}
		},
		{
			'url_image': base_url_partner + 'juniper.webp',
			'title': 'Juniper',
			'style': {
				'height': '80px',
			}
		},
		{
			'url_image': base_url_partner + 'VMWARE.webp',
			'title': 'VMWARE',
			'style': {
				'height': '55px',
				'width': '100% !important'
			}
		},
		
		{
			'url_image': base_url_partner + 'panduit.webp',
			'title': 'Panduit',
			'style': {
				'height': '155px',
			}
		},
		{
			'url_image': base_url_partner + 'cisco.webp',
			'title': 'Cisco',
			'style': {
				'height': '115px',
				'width': 'auto!important',
			}
		},
		{
			'url_image': base_url_partner + 'corning.webp',
			'title': 'Corning',
			'style': {
				'height': '70px',
			}
		}
		];

		let storeElement = []

		res_data.map((row, index) => (
			storeElement.push(
				<Col xs={4} key={index} className="align-self-center d-flex justify-content-center " style={{margin:"auto"}}>
					<img alt="" src={row.url_image} className="w-100 p-3" style={row.style} />
				</Col>
			)
		))

		return (<Row className={(mediaQuery) ? "m-3" : ""} style={{"maxWidth":"568px"}}>{storeElement}</Row>);
	}
    render(){
        return (
        <>
        	<section className={(mediaQuery) ? "nexatel-our-partner d-block" : "nexatel-our-partner"} style={this.props.styleSection}>
	          <div className="nexatel-container18">
	            <span className="nexatel-text40">    </span>
	            <h2 className="nexatel-text41">Our Partners</h2>
	          </div>
	          <div className="nexatel-container19" style={{margin:"auto"}}>
	          
	          {/*
	          	<img alt="" src="./assets/images/banner/partner.webp" className="w-75" style={{"marginLeft":"4rem","marginRight":"4rem"}} />
	      		*/}

	      		{(mediaQuery) ? this.isMobile() : this.isIpadPro()}
	          </div>
	        </section>
        </>
        )
    }
}