import React from 'react';
import Header 		from '../../../components/Header';
import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import Content 		from './Content';
import Footer 		from '../../../components/Footer';
import '../../../assets/career.css';
import ReactGA from 'react-ga'

function Support() {

	//Google Analitik
	ReactGA.event({category:"user", action:"Support Page"})

	let eBanner 			= <h1 className="banner-text"><span>Subscribe Now</span></h1>;
	let sBanner_desc  		= "Start your Subcription Plan only with Nexa";
	let sAfterBanner_title 	= "Easy and fast process of subscription only with Nexa";
	let sAfterBanner_desc 	= "For customers who want to subscribe to the internet with Nexa, we recommend consulting our sales team first. If it meets your needs, then please download the file below, complete your data by filling out the form provided, then click Submit.";
	
	return (
		<div>
			<Header />
			<Banner title={eBanner} desc={sBanner_desc} />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			<Content />
			<Footer />
		</div>
		);
}

export default Support;