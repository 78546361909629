import React, {Component} from 'react';
import MenuTabs from './ScrollableTabsButtonAuto';

export default class Portofolio extends Component {

    render(){
        return (
        	<section className="client-section6">
	          <div className="client-container04">
	            <span className="client-text16 pb-3">{this.props.sub_title}</span>
	            <h2 className="client-text17 fw-bold">{this.props.title}</h2>
	            <span className="client-text18 w-100" style={{paddingLeft:"2rem", paddingRight:"2rem", textAlign:"center"}}>
	            	{this.props.desc}
	            </span>
	          </div>
	          
	          <MenuTabs />
	        </section>
        )
    }
}