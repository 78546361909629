import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

export default class OtherSolutions extends Component {
  loopOtherSolution = () => {
    let aCards = []
    let fetchData = [
    {
      'title': 'fetch data...',
      'url_image': 'https://via.placehold er.com/100x100?text=Fetch',
      'url_action': '/sdfdf'
    },
    {
      'title': 'fetch data...',
      'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
      'url_action': '/sdfdf'
    },
    {
      'title': 'fetch data...',
      'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
      'url_action': '/sdfdf'
    },
    {
      'title': 'fetch data...',
      'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
      'url_action': '/sdfdf'
    },
    {
      'title': 'fetch data...',
      'url_image': 'https://via.placeholder.com/100x100?text=Fetch',
      'url_action': '/sdfdf'
    }
    ]

    let res_data = [
    {
      'title': 'Goverment',
      'url_image': 'https://minio.nexa.net.id/nexa/icon_gov.png',
      'url_action': '/goverment'
    },
    {
      'title': 'Education',
      'url_image': 'https://minio.nexa.net.id/nexa/icon_edu.png',
      'url_action': '/education'
    },
    {
      'title': 'Hospitally',
      'url_image': 'https://minio.nexa.net.id/nexa/icon_hos.png',
      'url_action': '/hospitality'
    },
    {
      'title': 'Manufacture',
      'url_image': 'https://minio.nexa.net.id/nexa/icon_manu.png',
      'url_action': '/manufacture'
    },
    {
      'title': 'Corporate',
      'url_image': 'https://minio.nexa.net.id/nexa/icon_corp.png',
      'url_action': '/corporate'
    }
    ]

    if (this.props.storeData === null || typeof this.props.storeData === 'undefined') {
      fetchData.forEach((row, index) => {
        aCards.push(
          
          <Col xs={6} md={4} key={index} className="mt-3">
            <NavLink to={row.url_action}>
              <Card className="card-other border-0">
                <Card.Img loading='lazy' alt={row.title} variant="top" src={row.url_image} className="card-img-top mx-auto mt-3" />
                <Card.Body>
                  <Card.Title className="fs-16 text-center">
                    {row.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </NavLink>
          </Col>
          )
      })

    } else {
      res_data.map((row, idx) => row.title = this.props.storeData.replaceAll(' ', '').split('|')[idx] )
      
      res_data.forEach((row, index) => {
        aCards.push(
          
          <Col xs={6} md={4} key={index} className="mt-3">
            <NavLink to={row.url_action}>
              <Card className="card-other border-0">
                <Card.Img loading='lazy' alt={`Nexa Icon ${row.title}`} variant="top" src={row.url_image} className="card-img-top mx-auto mt-3" />
                <Card.Body>
                  <Card.Title className="fs-16 text-center">
                    {row.title}
                  </Card.Title>
                </Card.Body>
              </Card>
            </NavLink>
          </Col>
          )
      })
    }

    return aCards;
  }
  render(){
      return (
      <section>
      <style jsx="true">
      {`
        .other-solutions {
          max-width: 500px;
        }
        .card-img-top {
          width: auto;
          height: 80px;
        }
        .card-other {
          box-shadow:5px 5px 10px 0px #d4d4d4;
        }
      `}
      </style>
      <div className="solutioneducation-container11" style={{"gridGap":"unset"}}>
        <div className="solutioneducation-container12">
          <span className="solutioneducation-text37">{ sessionStorage.getItem('lang') === "1" ? "Solution" : "Solusi"}</span>
          <h2 className="solutioneducation-text38" style={{fontSize:"2.5rem", textAlign:"center", marginTop:"1rem", fontWeight:"bold"}}>  
            {sessionStorage.getItem('lang') === "1" ? "Other Solution" : "Solusi Lain"}
          </h2>
        </div>
        <Row className="other-solutions me-3 ms-3">
          {this.loopOtherSolution()}
        </Row>
      </div>
      </section>
      )
  }
}