import React from 'react';
import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import Content 		from './Content';
import Footer 		from '../../components/Footer';
import Interested2 from '../../components/Interested2';
import '../../../assets/nexasolution.css';
import { useSelector } from 'react-redux'
import { NexasolutionPage, LineBreak, CreateToken, detectDevice, AboutUs } from '../../../controller/index'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible =  detectDevice()

function Solution() {
	// let eBanner 			= <h1 className="banner-text fw-bold text-primary"><span>Nexa Solutions</span></h1>;
	// let sBanner_desc  		= <>Managed service solutions help <br/>to eliminate the headaches of maintenance, support, and keeping up <br/>with the latest developments.</>;
	let eStyleBanner = {"width": "100%"}
	let eStyleBannerText = {"width": "65%"}

	// let sAfterBanner_title = <>Improve your business process efficiency <br/>with cost-effective solutions</>
	// let sAfterBanner_desc = <>Handle a myriad of operational tasks that are critical to business, including infrastructure management, hardware maintenance, network monitoring, and more.</>;
	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_nexasolution.webp" : "https://minio.nexa.net.id/nexa/banner_nexasolution.jpeg";

	const [result, setResult] = React.useState(null)
	const [interested, setInterested] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {
		CreateToken().then((data) => {

			AboutUs().then((data) => {
				setInterested(data.response[0])
			})

			NexasolutionPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text fw-bold text-primary"><span>Nexa Solutions</span></h1>;
	let eTitle				= "Nexa Solution"
	let sBanner_desc  		= <>{ result !== null ? LineBreak(result.tentang_1) : <Shimmer width={"20rem"} height={"4rem"} />}</>
	
	let sAfterBanner_title 	= result !== null ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_desc 	= result !== null ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} />;
	let desc 				= result !== null ? result.tentang_3 : ""

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			{/* <Banner title={eBanner} desc={sBanner_desc} style={eStyleBanner} styleText={eStyleBannerText} url_image={mainBannerPage} /> */}
			<Banner title={eBanner} style={eStyleBanner} styleText={eStyleBannerText} url_image={mainBannerPage} />
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			{/* <Content /> */}
			{ result === null && <Content storeData={null} />}
			{ result !== null && <Content storeData={{'result':result.services.split('|'), 'lang': langCondition}} />}
			{ (interested == null) && <Interested2 storeData={null} /> }
			{ (interested !== null) && <Interested2 storeData={interested} /> }
		</div>
		);
}

export default Solution;