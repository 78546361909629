import React, {Component} from 'react';
import Management from '../../../components/Management';
import {detectDevice} from "../../../controller/index"

let isCompatible = detectDevice()
export default class Content extends Component {
	// constructor(props){
	// 	super(props)
	// }

    render(){
        return (
        <React.Fragment>

		    <section className="companyabout-section3">
	          <div className="companyabout-container04" style={{alignItems: 'start', paddingTop: "5rem"}} >
	            <div className="companyabout-container05" style={{paddingLeft:"20px", paddingRight:"20px"}}>
	              <h2 className="companyabout-text25">{parseInt(sessionStorage.getItem('lang')) === 1 ? "Our Vision" : "Visi Kami"}</h2>
	              <span className="companyabout-text26" style={{"width":"20rem"}}>
	                
	                {this.props.storeData.visi}
	              </span>
	            </div>
	            <div className="companyabout-container06" style={{paddingLeft:"20px", paddingRight:"20px"}}>
	              <h2 className="companyabout-text34">{parseInt(sessionStorage.getItem('lang')) === 1 ? "Our Mission" : "Misi Kami"}</h2>
	              <span className="companyabout-text35" style={{"width":"20rem"}}>
	                
	                {this.props.storeData.misi}
	              </span>
	            </div>
	          </div>
	        </section>

		    <section className="companyabout-section5">
	          <div className="companyabout-container09">
	            <iframe loading='lazy' title="video" src="https://www.youtube.com/embed/-dhjSMCLdv4" className="companyabout-iframe"></iframe>
	          </div>
	        </section>

		    <section className="companyabout-section6">
	          <div className="companyabout-container10">
	            <span className="companyabout-text59">Company</span>
	            <h2 className="companyabout-text60 fw-bold">Nexa in Numbers</h2>
	            <img alt="" src={isCompatible ? "https://minio.nexa.net.id/nexa/nexa_number.webp" : "https://minio.nexa.net.id/nexa/nexa_number.png"} className="companyabout-image"/>
	          </div>
	        </section>

	        <Management />

        </React.Fragment>
        )
    }
}