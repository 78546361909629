import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Row, Col, Card } from 'react-bootstrap';
import { Shimmer } from 'react-shimmer';


// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}>
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           {children}
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

// function ScrollableTabsButtonAuto({storeData}) {
//   const [value, setValue] = React.useState(0);

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };

//   return (
//   <div className="row nexadata-container05">
//     <Box className="nexadata-container05">
//       <Tabs
//         value={value}
//         onChange={handleChange}
//         variant="scrollable"
//         scrollButtons="auto"
//         className=" m-0 textCapitalize d-flex justify-content-center"
//         aria-label="scrollable auto tabs example" id="__nexaDataTabs" style={{ "minHeight": "auto"}}>
//           <Tab label="B2B" {...a11yProps(0)} className="nexadata-button1 button" />
//           <Tab label="Consumer" {...a11yProps(1)} className="nexadata-button1 button" />
//       </Tabs>
      
//     </Box>
//     	<TabPanel value={value} index={0}>
//         { 
//           	<div className="internetservice-container07" style={{"alignItems":"start"}}>
	            
// 	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
// 	            	<div className="card-title internetservice-text020">Metro Ethernet (Metro-E)</div>
// 	            	<div className="card-body internetservice-text023 pe-0 ps-0">
// 	                  { storeData !== null ? storeData.b2.split('|')[0] : 'Fetching loading...' }
// 	            	</div>
// 	            </div>
// 	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
// 	            	<div className="card-title internetservice-text020">Metro Wireless (Metro-W)</div>
// 	            	<div className="card-body internetservice-text023 pe-0 ps-0">
// 	            	{ storeData !== null ? storeData.b2.split('|')[1] : 'Fetching loading...' }
// 	            	</div>
// 	            </div>
// 	          </div>
	          
//         }
//       </TabPanel>
//       <TabPanel value={value} index={1}>
//       	{ 
//         	<div className="internetservice-container07" style={{"alignItems":"start", "height":"auto"}}>
// 	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
// 	            	<div className="card-title internetservice-text020">Business</div>
// 	            	<div className="card-body internetservice-text023">
// 		            <ol type="a" className="ps-3 business">
// 		              	<li>
// 		              		<div className="row">
// 			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Basic</div>
// 			              		<div>
// 			              		{ storeData !== null ? storeData.consumer.split('|')[0] : 'Fetching loading...' }
// 			              		</div>
// 		              		</div>
// 		              	</li>
// 		              	<li>
// 		              		<div className="row">
// 			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Premium</div>
// 			              		<div>
// 			              		{ storeData !== null ? storeData.consumer.split('|')[1] : 'Fetching loading...' }
// 			              		</div>
// 		              		</div>
// 		              	</li>
// 		              	<li>
// 		              		<div className="row">
// 			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Ultimate</div>
// 			              		<div>
// 			              		{ storeData !== null ? storeData.consumer.split('|')[2] : 'Fetching loading...' }
// 			              		</div>
// 		              		</div>
// 		              	</li>
// 		            </ol>
// 	            	</div>
// 	            </div>

// 	            <div className="card border-0 p-4 rounded-15" style={{"width":"400px","height":"auto", "minHeight":"200px", "boxShadow":"5px 5px 10px 0px #d4d4d4"}}>
// 	            	<div className="card-title internetservice-text020">Home</div>
// 	            	<div className="card-body internetservice-text023">
// 		            <ol type="a" className="ps-3 business">
// 		              	<li>
// 		              		<div className="row">
// 			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Fiberstream</div>
// 			              		<div>
// 			              		{ storeData !== null ? storeData.consumer.split('|')[3] : 'Fetching loading...' }
// 			              		</div>
// 		              		</div>
// 		              	</li>
// 		              	<li>
// 		              		<div className="row">
// 			              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Zigy</div>
// 			              		<div>
// 			              		{ storeData !== null ? storeData.consumer.split('|')[4] : 'Fetching loading...' }
// 			              		</div>
// 		              		</div>
// 		              	</li>
// 	              	</ol>
// 	            	</div>
// 	            </div>

// 	        </div>
//         }
//       </TabPanel>
//     </div>
//   );
// }


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }} className="pe-0 ps-0">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ScrollableTabsButtonAuto({storeData}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
  <div className="row container" style={{width:"40rem"}}>
  <style jsx="true">
  {`
  	.nexadata-features {
    flex: 0 0 auto;
    display: flex;
    grid-gap: var(--dl-space-space-unit);
    align-items: center;
    flex-direction: column;
    justify-content: center;
	}
  	#__nexaBlue .MuiButtonBase-root.MuiTab-root.Mui-selected {
		color: #ffffff;
	    width: auto;
	    padding-left:2.9rem;
	    padding-right:2.9rem;
	    font-size: 16px;
	    text-align: center;
	    transition: 0.3s;
	    background-image: linear-gradient(270deg, rgb(243, 112, 33) 29.00%,rgb(253, 150, 18) 99.00%) !important;
	    border-radius: 10px;
	}
	#__nexaDataTabs .MuiButtonBase-root.MuiTab-root, #__nexaBlue .MuiButtonBase-root.MuiTab-root {
	    min-height: 36px;
	    width: auto;
	    padding: 0.5rem;
	    padding-left:3.1rem;
	    padding-right:3.1rem;
	    margin: 0px;
	    font-size: 16px;
	    font-weight: 340;
	    background-color: #fff;
	    color: var(--dl-color-gray-700);
	}

  `}
  </style>
    <Box className="">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        className=" m-0 textCapitalize d-flex justify-content-center mobileData"
        aria-label="scrollable auto tabs example" id="__nexaBlue" style={{ "minHeight": "auto"}}>
          <Tab label="B2B" {...a11yProps(0)} className="nexadata-button1 button" />
          <Tab label="Consumer" {...a11yProps(1)} className="nexadata-button1 button" />
      </Tabs>
      
    </Box>
    	<TabPanel className="" value={value} index={0}>
        { <Row>
        	<Col md={12} className="mb-4">
	          <Card className="border-0">
	          	<Card.Body>
		          	<Card.Title className="internetservice-text020">
		          	Metro Ethernet (Metro-E)
		          	</Card.Title>
		          	<Card.Text className="nexadata-text17">
	              	{ storeData !== null ? storeData.b2.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}
		          	</Card.Text>
	          	</Card.Body>
	          </Card>
          	</Col>
          	{/* <Col md={12} className="mb-4">
	          <Card className="border-0">
	          	<Card.Body>
		          	<Card.Title className="internetservice-text020">
		          	Metro Wireless (Metro-W)
		          	</Card.Title>
		          	<Card.Text className="nexadata-text17">
	              	{ storeData !== null ? storeData.b2.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} /> }
		          	</Card.Text>
	          	</Card.Body>
	          </Card>
          	</Col> */}
          </Row>
        }
      </TabPanel>
      <TabPanel value={value} index={1}>
      	{ 
	        <Row>
	        	<Col md={12} className="mb-4">
		          <Card className="border-0">
		          	<Card.Body>
			          	<Card.Title className="internetservice-text020">
			          	Business
			          	</Card.Title>
			          	<div className="nexadata-text17 card-text">
				            <ol type="a" className="ps-3 business">
				              	<li>
				              		<div className="row">
					              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Prime</div>
					              		<div>
					              		{ storeData !== null ? <div dangerouslySetInnerHTML={{__html: storeData.consumer.split('|')[0]}}  />  : <Shimmer width={"20rem"} height={"4rem"} /> }
					              		</div>
				              		</div>
				              	</li>
				              	<li>
				              		<div className="row">
					              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Impressive</div>
					              		<div>
					              		{ storeData !== null ? <div dangerouslySetInnerHTML={{__html: storeData.consumer.split('|')[1]}}  /> : <Shimmer width={"20rem"} height={"4rem"} /> }
					              		</div>
				              		</div>
				              	</li>
				              	{/* <li>
				              		<div className="row">
					              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Ultimate</div>
					              		<div>
					              		{ storeData !== null ? storeData.consumer.split('|')[2] :<Shimmer width={"20rem"} height={"4rem"} /> }
					              		</div>
				              		</div>
				              	</li> */}
				            </ol>
			          	</div>
		          	</Card.Body>
		          </Card>
	          	</Col>
	          	<Col md={12} className="mb-4">
			          <Card className="border-0">
			          	<Card.Body>
				          	<Card.Title className="internetservice-text020">
				          	Home
				          	</Card.Title>
				          	<div className="nexadata-text17 card-text">
					            <ol type="a" className="ps-3 business">
				              	<li>
				              		<div className="row">
					              		<div className="internetservice-text020 pb-2 pt-2" style={{"border":"unset"}}>Nexa Home</div>
					              		<div>
					              		{ storeData !== null ? <div dangerouslySetInnerHTML={{__html: storeData.consumer.split('|')[2]}}  /> :<Shimmer width={"20rem"} height={"4rem"} /> }
					              		</div>
				              		</div>
				              	</li>
			              	</ol>
				          	</div>
			          	</Card.Body>
			          </Card>
	          	</Col>
	        </Row>
        }
      </TabPanel>
    </div>
  );
}

export default class Content extends Component {
	services_features = () => {
		return [
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_sla.png',
				'title': 'SLA 99,8%'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_redundancy_network.png',
				'title': 'Redundancy<br/>Network'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_lastmile.png',
				'title': 'Lastmile<br/>Backup'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_on_call_support.png',
				'title': '24/7 Out-of-hours<br/>Support'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_reliable_service.png',
				'title': 'Reliable<br/>Service'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_delivery_network.png',
				'title': 'Content Delivery<br/>Network'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_symetric_bandwidth.png',
				'title': ' Symetric<br/>Bandwidth'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_own_infrastructure.png',
				'title': 'Own<br/>Infrastructure'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_early_warning.png',
				'title': 'Early Warning<br/>System'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_bandwidth_management.png',
				'title': 'Bandwidth<br/>Management'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_lan_troubleshooting.png',
				'title': 'LAN<br/>Troubleshooting'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_regular_visit.png',
				'title': 'Regular Visit'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_bandwith_on_demand.png',
				'title': 'Bandwidth On<br/>Demand'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_static_ip_public.png',
				'title': 'Static IP Public'	
			},
			{
				'image': 'https://minio.nexa.net.id/nexa/icon_deep_analytic.png',
				'title': 'Deep Analytic'	
			},
		]
	}

	formatText = (text) => {
		const newText = text.split('<br/>').map(str => <React.Fragment key={str}>{str}<br/></React.Fragment>)
	return newText;
	}

    render(){
        return (
        <section>

				<div className='button-wa-fixed-mobile'>
					<a target='_blank' href='https://api.whatsapp.com/send/?phone=6282115555646&text&type=phone_number&app_absent=0'>
						<svg viewBox="0 0 1024 1024" className="home-icon-wa-fixed-mobile">
						<path d="M873 148.8c-95.8-96-223.2-148.8-359-148.8-279.6 0-507.2 227.6-507.2 507.4 0 89.4 23.4 176.8 67.8 253.6l-72 263 269-70.6c74.2 40.4 157.6 61.8 242.4 61.8h0.2c0 0 0 0 0 0 279.6 0 507.4-227.6 507.4-507.4 0-135.6-52.8-263-148.6-359zM514.2 929.6v0c-75.8 0-150-20.4-214.8-58.8l-15.4-9.2-159.6 41.8 42.6-155.6-10-16c-42.4-67-64.6-144.6-64.6-224.4 0-232.6 189.2-421.8 422-421.8 112.6 0 218.6 44 298.2 123.6 79.6 79.8 123.4 185.6 123.4 298.4-0.2 232.8-189.4 422-421.8 422zM745.4 613.6c-12.6-6.4-75-37-86.6-41.2s-20-6.4-28.6 6.4c-8.4 12.6-32.8 41.2-40.2 49.8-7.4 8.4-14.8 9.6-27.4 3.2s-53.6-19.8-102-63c-37.6-33.6-63.2-75.2-70.6-87.8s-0.8-19.6 5.6-25.8c5.8-5.6 12.6-14.8 19-22.2s8.4-12.6 12.6-21.2c4.2-8.4 2.2-15.8-1-22.2s-28.6-68.8-39-94.2c-10.2-24.8-20.8-21.4-28.6-21.8-7.4-0.4-15.8-0.4-24.2-0.4s-22.2 3.2-33.8 15.8c-11.6 12.6-44.4 43.4-44.4 105.8s45.4 122.6 51.8 131.2c6.4 8.4 89.4 136.6 216.6 191.4 30.2 13 53.8 20.8 72.2 26.8 30.4 9.6 58 8.2 79.8 5 24.4-3.6 75-30.6 85.6-60.2s10.6-55 7.4-60.2c-3-5.6-11.4-8.8-24.2-15.2z"></path>
						</svg>
					</a>
				</div> 

	        <section className="nexadata-container03">
		        <div className="nexadata-container04">
		        	<span className="internetservice-text018">Nexatel</span>
		        	<h2 className="nexadata-text14 fw-bold fs-32">Business Category</h2>
		        </div>
		        <div className="nexadata-container05">
		        	<ScrollableTabsButtonAuto storeData={this.props.storeData} />
		        </div>
	        </section>


		    <section className="nexadata-section6">
	          <div className="nexadata-container10">
	            <span className="internetservice-text033">Nexatel</span>
	            <h2 className="nexadata-text23 fw-bold fs-32">Service Features</h2>
	          </div>
	          <div className="container pe-4 ps-4">
	            <div className="nexadata-container12 row mx-auto" style={{"gridGap":"unset", "justifyContent":"unset"}}>
	              {
	              	this.services_features().map((row, idx) => (
		              <Col xs={4} md={4} key={idx} className="nexadata-features mt-4 text-center">
		                <img loading='lazy' alt={`Icon ${row.title}`} src={row.image} className="nexadata-image mx-auto"/>
		                <span className="nexadata-text24" style={{fontSize:"0.8rem"}}>
		                {this.formatText(row.title)}
		                </span>
		              </Col>
	              	))
	              }

	            </div>
	          </div>
	        </section>

        </section>
        )
    }
}