import React, {Component} from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Image, Shimmer } from 'react-shimmer'
import '../../assets/splide-sea-green.min.css';
import {detectDevice} from "../../controller/index"
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default class Banner extends Component {
	constructor(props){
		super(props)
		this.state = {
			header : {
				height: "56px"
			}
		}
	}
	componentDidMount() {
		this.didMountHeaderHeight = setInterval(
			() => this.setHeaderHeight(),
			1000
			);

	}

	componentWillUnmount() {
		clearInterval(this.didMountHeaderHeight);
	}
	setHeaderHeight = () => {
		this.setState({
			header : {
				height: document.getElementById('__mainHeader').clientHeight + "px"
			}
		})
	}
	mainBannerPrev = () => {
		document.querySelector('.banner-prev').click()
	}
	mainBannerNext = () => {
		document.querySelector('.banner-next').click()
	}
	handleSlidePrev = () => {
		document.querySelector('.news-prev').click()
	}

	handleSlideNext = () => {
		document.querySelector('.news-next').click()
	}

	directHelpdesk = () => {
		window.open('http://wa.gmedia.id/hd', '_blank');
	}

	createSlider = () => {
		let isCompatible = detectDevice()
		let img = (typeof this.props.url_image == 'undefined') ? (isCompatible ? 'https://minio.nexa.net.id/nexa/nexahome-mobile.webp' : 'https://minio.nexa.net.id/nexa/nexahome-mobile.png') : this.props.url_image
		if (this.props.slider !== true) {
    		return (
			<section className="bannerandmenu-banner h-auto" style={{"marginTop": this.state.header.height}}>
		          <div className="bannerandmenu-maxwith" style={this.props.style}>
		            {this.props.title}
		            <span className="bannerandmenu-text1 fs-12" style={this.props.styleText}>
		              <div>{this.props.desc}</div>
		            </span>
		          </div>
	          <div className="bannerandmenu-image">
	          	<LazyLoadImage alt={`Banner ${document.title}`} fallback={<Shimmer width={500} height={246} />} src={img} />
	          
			  </div>
	        </section>)
    		
    	}

    	// console.log('this.props.arrayData', this.props.arrayData)

		let cards = [];
		this.props.arrayData.forEach((row, index) => {
			cards.push(
			<SplideSlide key={index} className="p-0" data-splide-interval="3000">
		        <div className="bannerandmenu-banner" style={{"marginTop": this.state.header.height}}>
		          <div className="bannerandmenu-maxwith" style={this.props.style}>
		            {row.title}
		            <span className="bannerandmenu-text1 fs-12">
		              <p>{row.desc}</p>
		            </span>
		          </div>
		          <img alt="" src={row.url_image} className="bannerandmenu-image"/>
		        </div>
        	</SplideSlide>
			)
		})
		

		return(
			<Splide className="p-0" id="__bannerSlider" data-splide-interval="0"
			options={ {
				type: 'loop',
				rewind: true,
				perPage: 1,
				perMove: 1,
    			autoplay: true,
    			pauseOnHover : false,
    			resetProgress: false,
			} }

			aria-label="My Favorite Images">
			<div className="splide__progress">
				<div className="splide__progress__bar" />
			</div>
        	{cards}

        	<div className="splide__arrows splide__arrows--ltr d-none">
        		<button className="splide__arrow splide__arrow--prev banner-prev" type="button" aria-label="Previous slide" aria-controls="splide02-track">
        		Prev</button>

        		<button className="splide__arrow splide__arrow--next banner-next" type="button" aria-label="Go to first slide" aria-controls="splide02-track">
        		Next</button>
        	</div>
        </Splide>)
    }

    loadSingleBanner = () => {
    	return (
			<div className="bannerandmenu-banner">
	          <div className="bannerandmenu-maxwith" style={this.props.style}>
	            {this.props.title}
	            <span className="bannerandmenu-text1">
	              <p>{this.props.desc}</p>
	            </span>
	          </div>
	          <img alt="" src="https://minio.nexa.net.id/nexa/nexahome-mobile.webp" className="bannerandmenu-image"/>
	        </div>
    	)
    }

    render(){
        return (
        	<section>
        	<style>{`
        	#__bannerSlider .splide__pagination {
        		bottom: 0
        	}
        	`}</style>
	        {this.createSlider()}
	        </section>
        )
    }
}