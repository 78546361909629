import Banner 		from '../../components/Banner';
import AfterBanner 	from '../../components/AfterBanner';
import Content 		from './Content';
import Footer 		from '../../components/Footer';
import '../../../assets/network-infrastructure.css';

import  React from 'react'
import { InfrastructurePage, formatTitle, CreateToken, detectDevice, AboutUs } from '../../../controller/index'
import { useSelector } from 'react-redux'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';
import Interested2 from '../../components/Interested2';

let isCompatible = detectDevice()

function Infra() {
	const [result, setResult] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	const [interested, setInterested] = React.useState(null)


	let langCondition = (statusLang !== langs[0].idLang)
	
	React.useEffect(() => {
		CreateToken().then((data) => {
			
			AboutUs().then((data) => {
				setInterested(data.response[0])
			})

			InfrastructurePage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
	}, [langCondition]);

	let eBanner 			= <h1 className="banner-text fw-bold"><span>{ result !== null ? result.tentang_1.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}</span></h1>;
	let sBanner_desc  		= result !== null ? formatTitle(result.tentang_1.split('|')[1].replaceAll('<br/>', '')) : <Shimmer width={"20rem"} height={"4rem"} />;
	let sAfterBanner_title 	= result !== null ? (result.tentang_2).replaceAll('<br/>', '') : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_desc 	= result !== null ? (result.tentang_3).replaceAll('<br/>', '') : <Shimmer width={"20rem"} height={"4rem"} />

	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_internet_service.webp" : "https://minio.nexa.net.id/nexa/banner_internet_service.jpeg";
	let eTitle				= result !== null ? result.tentang_1.split('|')[0] : ""
	let desc				= result !== null ? (result.tentang_3).replaceAll('<br/>', '') : ""

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={`Nexa - ${eTitle}`}></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content="https://nexa.net.id/"></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
      		</Helmet>
			{/* <Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={{"width":"100%"}} /> */}
			<Banner title={eBanner} url_image={mainBannerPage} style={{"width":"100%"}} />
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} />
			{ result === null && <Content storeData={null} /> }
			{ result !== null && <Content storeData={result} /> }
			{ (interested == null) && <Interested2 storeData={null} /> }
			{ (interested !== null) && <Interested2 storeData={interested} /> }
			
		</div>
		);
}

export default Infra;
